import { action } from 'typesafe-actions';
import {
    DynamicObject,
    ResponseModalObject,
} from '../../utils/commonInterfaces';
import { PageData } from '../common/types';
import {
    GetCommonInvoiceListInvoicesRequestPayload,
    Invoice,
} from '../invoices/types';
import {
    GetPaymentPlanDataScheduleRequestPayload,
    PaymentPlanSchedule,
} from '../paymentPlans/types';
import {
    BulkSaveTaskDetailsUpdateRequestPayload,
    GetExtendedInvoicesRequestPayload,
    GetNotificationTasksRequestPayload,
    GetTaskHistoryChangedTasksRequestPayload,
    GetTaskHistoryCollectionTasksRequestPayload,
    GetTaskHistoryNotificationsRequestPayload,
    GetTaskHistoryDeliveryReportRequestPayload,
    GetTaskHistoryRequestPayload,
    GetTaskHistoryTasksFailedRequestPayload,
    GetTaskPropertiesRequestPayload,
    GetTasksRequestPayload,
    GetWorkflowStepOptionsRequestPayload,
    NotifyCustomersRequestPayload,
    Task,
    TaskAddCommentRequestPayload,
    TaskBatch,
    TaskHistoryNotification,
    TasksActionTypes,
    TaskDeliveryReport,
    GetTaskHistoryActionedTasksRequestPayload,
    TaskActionPaymentVerificationTaskRequestPayload,
    TaskActionPaymentRALinkTaskRequestPayload,
    TaskActionRequestRATaskRequestPayload,
    GetTaskViewerDetailForUserRequestPayload,
    ChangeTaskViewingRequestPayload,
    TaskWorkflowFilter,
    TaskActionPhoneCallTaskRequest,
    GetActionedPhoneCallDataRequest,
    TasksFetchedForOrganisationPayload,
    TaskActionRequestOrganisationRATaskRequestPayload,
    TaskActionOrganisationPaymentRALinkTaskRequestPayload,
    SetSelectedTaskHistoryRequestPayload,
    SetSelectedTaskHistoryPayload
} from './types';
import { User } from '../users/types';
import { GetRemittanceAdvicesRequestPayload } from '../../store/remittanceAdvices/types'

/**
 * Actions for fetching tasks.
 * @param payload
 */
export const getTasksRequestAction = (payload: GetTasksRequestPayload) =>
    action(TasksActionTypes.GET_TASKS_REQUEST, payload);
export const getTasksForOrganisationRequestAction = (payload: GetTasksRequestPayload) =>
    action(TasksActionTypes.GET_TASKS_FOR_ORGANISATION_REQUEST, payload);
export const getTasksSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(TasksActionTypes.GET_TASKS_SUCCESS, payload);
export const getTasksErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASKS_ERROR, payload);

/**
 * Action for updating tasks filters.
 * @param filters
 */
export const updateTasksFiltersAction = (
    filters: GetTasksRequestPayload['filters']
) => action(TasksActionTypes.UPDATE_TASKS_FILTERS, filters);

/**
 * Action for updating tasks sort values.
 * @param tableSortAndStatus
 */
export const updateTasksSortByAndStatusAction = (tableSortAndStatus: {
    sortBy: GetTasksRequestPayload['sortBy'];
    sortAscending: boolean;
    taskStatus: string;
}) =>
    action(
        TasksActionTypes.UPDATE_TASKS_SORT_BY_AND_STATUS,
        tableSortAndStatus
    );

/**
 * Action for updating tasks table filter value.
 * @param tableFilter
 */
export const updateTasksTableFilterAction = (tableFilter: string) =>
    action(TasksActionTypes.UPDATE_TASKS_TABLE_FILTER_STATUS, tableFilter);

/**
 * Action for resetting table filters and sort values.
 */
export const clearTasksStateAllTableFiltersAction = () =>
    action(TasksActionTypes.CLEAR_TASKS_STATE_ALL_TABLE_FILTERS);

/**
 * Action for clearing all tasks state data.
 */
export const clearTasksStateDataAction = () =>
    action(TasksActionTypes.CLEAR_TASKS_STATE_DATA);

/**
 * Action for getting the tasks ready state of selected items.
 * @param payload
 */
export const checkTasksReadyStateAction = (payload: GetTasksRequestPayload) =>
    action(TasksActionTypes.CHECK_TASKS_READY_STATE, payload);

/**
* Action for getting the tasks ready state of selected items.
* @param payload
*/
export const checkTasksReadyStateActionForOrganisation = (payload: GetTasksRequestPayload) =>
    action(TasksActionTypes.CHECK_TASKS_READY_STATE_FOR_ORGANISATION, payload);

/**
 * Actions for notifying customers (communication automation).
 * @param payload
 */
export const notifyCustomersRequestAction = (
    payload: NotifyCustomersRequestPayload
) => action(TasksActionTypes.NOTIFY_CUSTOMERS_REQUEST, payload);
export const notifyOrganisationCustomersRequestAction = (
    payload: NotifyCustomersRequestPayload
) => action(TasksActionTypes.NOTIFY_ORGANISATION_CUSTOMERS_REQUEST, payload);
export const notifyCustomersResponseAction = () =>
    action(TasksActionTypes.NOTIFY_CUSTOMERS_RESPONSE);

/**
 * Action for getting action filter options to be used for filter options.
 */
export const getTasksActionFilterOptionsRequestAction = (
    companyIds: string[] | undefined = undefined,
    isOrgView: boolean = false
) =>
    action(TasksActionTypes.GET_TASKS_ACTION_FILTER_OPTIONS_REQUEST, { companyIds, isOrgView });
export const getTasksActionFilterOptionsResponseAction = (
    response: DynamicObject
) =>
    action(TasksActionTypes.GET_TASKS_ACTION_FILTER_OPTIONS_RESPONSE, response);

// Invoices
/**
 * Actions for getting the ticket invoices.
 * @param payload
 */
export const getTaskTicketInvoicesRequestAction = (
    payload: GetCommonInvoiceListInvoicesRequestPayload
) => action(TasksActionTypes.GET_TASK_TICKET_INVOICES_REQUEST, payload);
export const getTaskTicketInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASK_TICKET_INVOICES_SUCCESS, payload);
export const getTaskTicketInvoicesErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_TICKET_INVOICES_ERROR, payload);

/**
 * Action for updating the ticket invoices filters.
 * @param filters
 */
export const updateTaskTicketInvoicesFiltersAction = (
    filters: GetCommonInvoiceListInvoicesRequestPayload['filters']
) => action(TasksActionTypes.UPDATE_TASK_TICKET_INVOICES_FILTERS, filters);

export const updateTaskTicketInvoicesTableFiltersAction = (invoiceState: string) =>
    action(TasksActionTypes.UPDATE_TASK_TICKET_INVOICES_FILTERS, invoiceState);

/**
 * Action for updating ticket invoices sort values.
 * @param tableSort
 */
export const updateTaskTicketInvoicesSortByAndStateAction = (tableSort: {
    sortBy: GetCommonInvoiceListInvoicesRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        TasksActionTypes.UPDATE_TASK_TICKET_INVOICES_SORT_BY_AND_STATE,
        tableSort
    );

/**
 * Payment schedule
 */
/**
 * Actions called for getting the payment schedule data for payment plan.
 * @param payload
 */
export const getTaskPaymentPlanScheduleRequestAction = (
    payload: GetPaymentPlanDataScheduleRequestPayload
) => action(TasksActionTypes.GET_TASK_PAYMENT_PLAN_SCHEDULE_REQUEST, payload);
export const getTaskPaymentPlanScheduleSuccessAction = (payload: {
    data: PaymentPlanSchedule[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASK_PAYMENT_PLAN_SCHEDULE_SUCCESS, payload);
export const getTaskPaymentPlanScheduleErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_PAYMENT_PLAN_SCHEDULE_ERROR, payload);

/**
 * Action for updating the filter values to be used in getting the Schedule data for payment plan.
 * @param filters
 */
export const updateTaskPaymentPlanScheduleFiltersAction = (
    filters: GetPaymentPlanDataScheduleRequestPayload['filters']
) =>
    action(TasksActionTypes.UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_FILTERS, filters);

/**
 * Action called for updating the Invoice state and sort values when getting the schedule for payment plan.
 * @param tableSort
 */
export const updateTaskPaymentPlanScheduleSortByAndStateAction = (tableSort: {
    sortBy: GetPaymentPlanDataScheduleRequestPayload['sortBy'];
    sortAscending: boolean;
    invoiceState: string;
}) =>
    action(
        TasksActionTypes.UPDATE_TASK_PAYMENT_PLAN_SCHEDULE_SORT_BY_AND_STATE,
        tableSort
    );

// Single Record
/**
 * Actions for setting the selected task id - to be used as identifier.
 * @param taskId
 * @param callback
 */
export const setSelectedTaskIdRequestAction = (
    taskId: string | null,
    callback: () => void,
    companyId: string | null = null,
) =>
    action(TasksActionTypes.SET_TASK_SELECTED_ID_REQUEST, {
        taskId,
        companyId,
        callback,
    });
export const setSelectedTaskIdSuccessAction = (
    taskId: string | null,
    companyId: string | null
) =>
    action(TasksActionTypes.SET_TASK_SELECTED_ID_SUCCESS, { taskId, companyId });

/**
 * Actions for getting the task details.
 * @param taskId
 * @param lockedDeliveryMethod
 */
export const getTaskDataRequestAction = (
    taskId: string,
    lockedDeliveryMethod?: boolean,
    isUsingCloudImportType?: boolean,
    usingMultipleWorkflow?: boolean,
    isPaymentPlanEnabled?: boolean,
    isCalendarView?: boolean
) =>
    action(TasksActionTypes.GET_TASK_DATA_REQUEST, {
        taskId,
        lockedDeliveryMethod,
        isUsingCloudImportType,
        usingMultipleWorkflow,
        isPaymentPlanEnabled,
        isCalendarView
    });
export const getTaskDataForOrganisationRequestAction = (
    taskId: string,
    companyId: string,
    region: string,
    lockedDeliveryMethod?: boolean,
    isUsingCloudImportType?: boolean,
    usingMultipleWorkflow?: boolean,
    isPaymentPlanEnabled?: boolean,
) =>
    action(TasksActionTypes.GET_TASK_DATA_FOR_ORGANISATION_REQUEST, {
        taskId,
        companyId,
        region,
        lockedDeliveryMethod,
        isUsingCloudImportType,
        usingMultipleWorkflow,
        isPaymentPlanEnabled,
    });
export const getTaskDataSuccessAction = (payload: { record: {} }) =>
    action(TasksActionTypes.GET_TASK_DATA_SUCCESS, payload);
export const getTaskDataErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_DATA_ERROR, payload);

/**
 * Action for clearing the activeData for tasks.
 */
export const clearTaskDataAction = () =>
    action(TasksActionTypes.CLEAR_TASK_DATA_SUCCESS);

/**
 * Task History section
 */
/**
 * Actions for getting the task history data.
 * @param payload
 */
export const getTaskHistoryRequestAction = (
    payload: GetTaskHistoryRequestPayload
) => action(TasksActionTypes.GET_TASK_HISTORY_REQUEST, payload);
export const getTaskHistorySuccessAction = (payload: {
    data: TaskBatch[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASK_HISTORY_SUCCESS, payload);
export const getTaskHistoryErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_ERROR, payload);
export const getOrganisationTasksHistoryRequestAction = (
    payload: GetTaskHistoryRequestPayload
) => action(TasksActionTypes.GET_ORGANISATION_TASKS_HISTORY_REQUEST, payload);

/**
 * Action for updating the task history filters.
 * @param filters
 */
export const updateTaskHistoryFiltersAction = (
    filters: GetTaskHistoryRequestPayload['filters']
) => action(TasksActionTypes.UPDATE_TASK_HISTORY_FILTERS, filters);

/**
 * Action for updating task history sort values.
 * @param tableSort
 */
export const updateTaskHistorySortByAction = (tableSort: {
    sortBy: GetTaskHistoryRequestPayload['sortBy'];
    sortAscending: boolean;
}) => action(TasksActionTypes.UPDATE_TASK_HISTORY_SORT_BY, tableSort);

/**
 * Action for updating task history table filter.
 * @param tableFilter
 */
export const updateTaskHistoryTableFilterAction = (tableFilter: string) =>
    action(
        TasksActionTypes.UPDATE_TASK_HISTORY_TABLE_FILTER_STATUS,
        tableFilter
    );

/**
 * Action for resetting table filters and sort values.
 */
export const clearTaskHistoryStateAllTableFiltersAction = () =>
    action(TasksActionTypes.CLEAR_TASK_HISTORY_STATE_ALL_TABLE_FILTERS);

/**
 * Action for clearing the task history state.
 */
export const clearTaskHistoryStateDataAction = () =>
    action(TasksActionTypes.CLEAR_TASK_HISTORY_STATE_DATA);

// Single Record
/**
 * Actions for setting the selected id for task history - to be used as an identifier.
 * @param taskId
 * @param callback
 */
export const setSelectedTaskHistoryIdRequestAction = (
    payload: SetSelectedTaskHistoryRequestPayload
) => action(TasksActionTypes.SET_TASK_HISTORY_SELECTED_ID_REQUEST, payload);

export const setSelectedTaskHistoryIdSuccessAction = (
    payload: SetSelectedTaskHistoryPayload
) =>
    action(TasksActionTypes.SET_TASK_HISTORY_SELECTED_ID_SUCCESS, payload);

/**
 * Actions for getting the task history data.
 * @param taskId
 */
export const getTaskHistoryDataRequestAction = (taskId: string) =>
    action(TasksActionTypes.GET_TASK_HISTORY_DATA_REQUEST, taskId);
export const getTaskHistoryDataSuccessAction = (payload: { record: {} }) =>
    action(TasksActionTypes.GET_TASK_HISTORY_DATA_SUCCESS, payload);
export const getTaskHistoryDataErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_DATA_ERROR, payload);

/**
 * Action for clearing the task history data.
 */
export const clearTaskHistoryDataAction = () =>
    action(TasksActionTypes.CLEAR_TASK_HISTORY_DATA_SUCCESS);

// Notification List Actions
/**
 * Actions for getting the task history notifications list.
 * @param payload
 */
export const getTaskHistoryNotificationsRequestAction = (
    payload: GetTaskHistoryNotificationsRequestPayload
) => action(TasksActionTypes.GET_TASK_HISTORY_NOTIFICATIONS_REQUEST, payload);
export const getTaskHistoryNotificationsSuccessAction = (payload: {
    data: TaskHistoryNotification[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASK_HISTORY_NOTIFICATIONS_SUCCESS, payload);
export const getTaskHistoryNotificationsErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_NOTIFICATIONS_ERROR, payload);

// Delivery Report List Actions
/**
 * Actions for getting the task history delivery report list.
 * @param payload
 */
export const getTaskHistoryDeliveryReportRequestAction = (
    payload: GetTaskHistoryDeliveryReportRequestPayload
) => action(TasksActionTypes.GET_TASK_HISTORY_DELIVERY_REPORT_REQUEST, payload);
export const getTaskHistoryDeliveryReportSuccessAction = (payload: {
    data: TaskDeliveryReport[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASK_HISTORY_DELIVERY_REPORT_SUCCESS, payload);
export const getTaskHistoryDeliveryReportErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_DELIVERY_REPORT_ERROR, payload);

/**
 * Action for updating the task history delivery report list sort values.
 * @param tableSort
 */
export const updateTaskHistoryDeliveryReportSortByAction = (tableSort: {
    sortBy: GetTaskHistoryDeliveryReportRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        TasksActionTypes.UPDATE_TASK_HISTORY_DELIVERY_REPORT_SORT_BY,
        tableSort
    );

/**
 * Action for updating the task history delivery report list filter values.
 * @param filters
 */
export const updateTaskHistoryDeliveryReportFiltersAction = (
    filters: GetTaskHistoryDeliveryReportRequestPayload['filters']
) =>
    action(TasksActionTypes.UPDATE_TASK_HISTORY_DELIVERY_REPORT_FILTERS, filters);

/**
 * Action for updating the task history notifications list filters.
 * @param filters
 */
export const updateTaskHistoryNotificationsFiltersAction = (
    filters: GetTaskHistoryNotificationsRequestPayload['filters']
) =>
    action(TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATIONS_FILTERS, filters);

/**
 * Action for updating the task history notifications list sort values.
 * @param tableSort
 */
export const updateTaskHistoryNotificationsSortByAction = (tableSort: {
    sortBy: GetTaskHistoryNotificationsRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATIONS_SORT_BY,
        tableSort
    );

/**
 * Actions for setting the task history notification id - to be used as an identifier.
 * @param taskId
 * @param callback
 */
export const setSelectedTaskHistoryNotificationIdRequestAction = (
    taskId: string | null,
    callback: () => void
) =>
    action(
        TasksActionTypes.SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_REQUEST,
        {
            taskId,
            callback,
        }
    );
export const setSelectedTaskHistoryNotificationIdSuccessAction = (
    taskId: string | null
) =>
    action(
        TasksActionTypes.SET_TASK_HISTORY_NOTIFICATIONS_SELECTED_ID_SUCCESS,
        taskId
    );

/**
 * Actions to get the task history notification details.
 * @param notificationId
 * @param isUsingCloudImportType
 */
export const getTaskHistoryNotificationDataRequestAction = (
    companyId: string | undefined,
    notificationId: string,
    isUsingCloudImportType: boolean
) =>
    action(TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_DATA_REQUEST, {
        companyId,
        notificationId,
        isUsingCloudImportType,
    });
export const getTaskHistoryNotificationDataSuccessAction = (payload: {
    record: {};
}) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS,
        payload
    );
export const getTaskHistoryNotificationDataErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_DATA_ERROR, payload);

/**
 * Actions to get the task history notification details.
 * @param companyIds
 * @param notificationId
 * @param isUsingCloudImportType
 */
export const getOrganisationTaskHistoryNotificationDataRequestAction = (
    companyId: string | undefined,
    companyIds: (string | undefined)[] = [],
    notificationId: string,
    conversionCurrency: number | undefined,
    isUsingCloudImportType: boolean
) =>
    action(TasksActionTypes.GET_ORGANISATION_TASK_HISTORY_NOTIFICATION_DATA_REQUEST, {
        companyId,
        companyIds,
        notificationId,
        conversionCurrency,
        isUsingCloudImportType,
    });
export const getOrganisationTaskHistoryNotificationDataSuccessAction = (payload: {
    record: {};
}) =>
    action(
        TasksActionTypes.GET_ORGANISATION_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS,
        payload
    );
export const getOrganisationTaskHistoryNotificationDataErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_ORGANISATION_TASK_HISTORY_NOTIFICATION_DATA_ERROR, payload);

/**
 * Action for clearing the task history notification details state.
 */
export const clearTaskHistoryNotificationDataAction = () =>
    action(TasksActionTypes.CLEAR_TASK_HISTORY_NOTIFICATION_DATA_SUCCESS);

// Invoices
/**
 * Actions for getting the task history notifications list Invoices record.
 * @param payload
 */
export const getOrganisationTaskHistoryNotificationInvoicesRequestAction = (
    payload: GetExtendedInvoicesRequestPayload
) =>
    action(
        TasksActionTypes.GET_ORGANISATION_TASK_HISTORY_NOTIFICATION_INVOICES_REQUEST,
        payload
    );
/**
 * Actions for getting the task history notifications list Invoices record.
 * @param payload
 */
export const getTaskHistoryNotificationInvoicesRequestAction = (
    payload: GetExtendedInvoicesRequestPayload
) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_INVOICES_REQUEST,
        payload
    );
export const getTaskHistoryNotificationInvoicesSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_INVOICES_SUCCESS,
        payload
    );
export const getTaskHistoryNotificationInvoicesErrorAction = (
    payload: string[]
) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_INVOICES_ERROR,
        payload
    );

/**
 * Action for updating the filter values under task history notifications list invoices tab.
 * @param filters
 */
export const updateTaskHistoryNotificationInvoicesFiltersAction = (
    filters: GetExtendedInvoicesRequestPayload['filters']
) =>
    action(
        TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_FILTERS,
        filters
    );

/**
 * Action for updating the sort values under task history notifications list invoices tab.
 * @param tableSort
 */
export const updateTaskHistoryNotificationInvoicesSortByAndStateAction =
    (tableSort: {
        sortBy: GetExtendedInvoicesRequestPayload['sortBy'];
        sortAscending: boolean;
        invoiceState: string;
    }) =>
        action(
            TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_INVOICES_SORT_BY_AND_STATE,
            tableSort
        );

// Tasks
/**
 * Actions for getting the task history notifications list tasks record.
 * @param payload
 */
export const getTaskHistoryNotificationTasksRequestAction = (
    payload: GetNotificationTasksRequestPayload
) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_TASKS_REQUEST,
        payload
    );
export const getTaskHistoryNotificationTasksSuccessAction = (payload: {
    data: Invoice[];
    pageData: PageData;
}) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_TASKS_SUCCESS,
        payload
    );
export const getTaskHistoryNotificationTasksErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_NOTIFICATION_TASKS_ERROR, payload);

/**
 * Action for updating the filter values under task history notifications list Tasks tab.
 * @param filters
 */
export const updateTaskHistoryNotificationTasksFiltersAction = (
    filters: GetNotificationTasksRequestPayload['filters']
) =>
    action(
        TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_FILTERS,
        filters
    );

/**
 * Action for updating the sort values under task history notifications list Tasks tab.
 * @param tableSort
 */
export const updateTaskHistoryNotificationTasksSortByAction = (tableSort: {
    sortBy: GetNotificationTasksRequestPayload['sortBy'];
    sortAscending: boolean;
}) =>
    action(
        TasksActionTypes.UPDATE_TASK_HISTORY_NOTIFICATION_TASKS_SORT_BY,
        tableSort
    );

/**
 * Action for getting the task history queued tasks count.
 * @param batchId
 * @param callback
 */
export const getTaskHistoryQueuedTasksCountRequestAction = (
    batchId: string,
    callback?: (response: DynamicObject) => void,
    companyId: string | undefined = undefined
) =>
    action(TasksActionTypes.GET_TASK_HISTORY_QUEUED_TASKS_COUNT_REQUEST, {
        batchId,
        callback,
        companyId
    });

/**
 * Action for getting the task history batch status record.
 * @param batchId
 * @param callback
 */
export const getTaskHistoryBatchStatusRequestAction = (
    batchId: string,
    callback?: (response: DynamicObject) => void,
    companyId: string | undefined = undefined
) =>
    action(TasksActionTypes.GET_TASK_HISTORY_BATCH_STATUS_REQUEST, {
        batchId,
        callback,
        companyId
    });

/**
 * Action for downloading the merged pdf record for documents sent (under notifications section for task history).
 * @param batchId
 * @param callback
 */
export const downloadMergedPdfRequestAction = (
    batchId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(TasksActionTypes.DOWNLOAD_MERGED_PDF_REQUEST, {
        batchId,
        callback,
    });

/**
 * Function for getting the button status for downloading merged pdf (either disabled/enabled).
 * @param batchId
 * @param callback
 */
export const downloadMergedPdfStatusRequestAction = (
    batchId: string,
    companyId?: string,
    callback?: (response: DynamicObject) => void
) =>
    action(TasksActionTypes.DOWNLOAD_MERGED_PDF_STATUS_REQUEST, {
        batchId,
        companyId,
        callback,
    });

// Changed Tasks Actions
/**
 * Actions for getting the task history changed tasks list.
 * @param payload
 */
export const getTaskHistoryChangedTasksRequestAction = (
    payload: GetTaskHistoryChangedTasksRequestPayload
) => action(TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_REQUEST, payload);
export const getTaskHistoryChangedTasksSuccessAction = (payload: {
    data: Task[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_SUCCESS, payload);
export const getTaskHistoryChangedTasksErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_ERROR, payload);

/**
 * Actions for getting the details for task history changed tasks.
 * @param taskId
 */
export const getTaskHistoryChangedTasksDataRequestAction = (
    batchId: string | null,
    companyId: string | undefined
) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_DATA_REQUEST,
        { batchId, companyId }
    );
export const getTaskHistoryChangedTasksDataSuccessAction = (payload: {
    record: {};
}) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_DATA_SUCCESS,
        payload
    );
export const getTaskHistoryChangedTasksDataErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_CHANGED_TASKS_DATA_ERROR, payload);

// Task history tasks failed
/**
 * Actions for getting the failed items upon tasks update.
 * @param payload
 */
export const getTaskHistoryTasksFailedRequestAction = (
    payload: GetTaskHistoryTasksFailedRequestPayload
) => action(TasksActionTypes.GET_TASK_HISTORY_TASKS_FAILED_REQUEST, payload);
export const getTaskHistoryTasksFailedSuccessAction = (payload: {
    data: Task[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASK_HISTORY_TASKS_FAILED_SUCCESS, payload);
export const getTaskHistoryTasksFailedErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_TASKS_FAILED_ERROR, payload);

// Edit Tasks Actions
/**
 * Actions for saving task details.
 * @param payload
 */
export const bulkSaveTaskDetailsUpdateRequestAction = (
    payload: BulkSaveTaskDetailsUpdateRequestPayload
) => action(TasksActionTypes.BULK_SAVE_TASK_DETAILS_REQUEST, payload);
export const bulkSaveTaskDetailsUpdateResponseAction = () =>
    action(TasksActionTypes.BULK_SAVE_TASK_DETAILS_RESPONSE);
export const organisationBulkSaveTaskDetailsUpdateRequestAction = (
    payload: BulkSaveTaskDetailsUpdateRequestPayload
) => action(TasksActionTypes.ORGANISATION_BULK_SAVE_TASK_DETAILS_REQUEST, payload);
export const organisationBulkSaveTaskDetailsUpdateResponseAction = () =>
    action(TasksActionTypes.ORGANISATION_BULK_SAVE_TASK_DETAILS_RESPONSE);

/**
 * Action for getting the edit properties for selected tasks.
 * @param payload
 */
export const getTaskEditPropertiesRequestAction = (
    payload: GetTaskPropertiesRequestPayload
) => action(TasksActionTypes.GET_TASK_EDIT_PROPERTIES_REQUEST, payload);

/**
 * Action for getting the workflow step options based on workflow selected.
 * @param payload
 */
export const getWorkflowStepOptionsRequestAction = (
    payload: GetWorkflowStepOptionsRequestPayload
) => action(TasksActionTypes.GET_WORKFLOW_STEP_OPTIONS_REQUEST, payload);

// Ticket items on Task History
/**
 * Action for getting the actioned ticket details in task history.
 * @param batchId
 */
export const getActionedTicketDetailsRequestAction = (batchId: string, companyId: string | undefined) =>
    action(TasksActionTypes.GET_ACTIONED_TICKET_DETAILS_REQUEST, { batchId, companyId });

// Payment plan items on Task History
/**
 * Action for getting the actioned ticket details in task history.
 * @param batchId
 */
export const getActionedPaymentPlanDetailsRequestAction = (
    batchId: string,
    companyId: string | undefined
) =>
    action(TasksActionTypes.GET_ACTIONED_PAYMENT_PLAN_DETAILS_REQUEST, {
        batchId,
        companyId
    });

// Payment allocation items on Task History
/**
 * Action for getting the actioned Payment allocation/verification/RA details in task history.
 * @param batchId
 */
export const getActionedPaymentDetailsRequestAction = (
    batchId: string, 
    companyId: string | undefined
) =>
    action(TasksActionTypes.GET_ACTIONED_PAYMENT_DETAILS_REQUEST, {
        batchId,
        companyId
    });

// Collections
/**
 * Actions for getting the collections items in task history page.
 * @param taskId
 */
export const getTaskHistoryCollectionsDataRequestAction = (
    batchId: string | null,
    companyId: string | undefined
) => action(TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_DATA_REQUEST, { batchId, companyId });
export const getTaskHistoryCollectionsDataSuccessAction = (payload: {
    record: {};
}) =>
    action(TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_DATA_SUCCESS, payload);
export const getTaskHistoryCollectionsDataErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_DATA_ERROR, payload);

/**
 * Actions for getting the tasks under task history collections.
 * @param payload
 */
export const getTaskHistoryCollectionTasksRequestAction = (
    payload: GetTaskHistoryCollectionTasksRequestPayload
) =>
    action(TasksActionTypes.GET_TASK_HISTORY_COLLECTION_TASKS_REQUEST, payload);
export const getTaskHistoryCollectionTasksSuccessAction = (payload: {
    data: Task[];
    pageData: PageData;
}) =>
    action(TasksActionTypes.GET_TASK_HISTORY_COLLECTION_TASKS_SUCCESS, payload);
export const getTaskHistoryCollectionTasksErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_HISTORY_COLLECTION_TASKS_ERROR, payload);

/**
 * Actions for setting the taskId for task history collections - to be used as an identifier.
 * @param taskId
 * @param callback
 */
export const setSelectedTaskHistoryCollectionsTaskIdRequestAction = (
    taskId: string | null,
    callback: () => void
) =>
    action(
        TasksActionTypes.SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_REQUEST,
        {
            taskId,
            callback,
        }
    );
export const setSelectedTaskHistoryCollectionsTaskIdSuccessAction = (
    taskId: string | null
) =>
    action(
        TasksActionTypes.SET_TASK_HISTORY_COLLECTIONS_TASK_SELECTED_ID_SUCCESS,
        taskId
    );

/**
 * Actions for getting the details for task history collections task.
 * @param notificationId
 */
export const getTaskHistoryCollectionsTaskDataRequestAction = (
    companyId: string | undefined,
    notificationId: string,
    isUsingCloudImportType: boolean
) =>
    action(TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_REQUEST, {
        companyId,
        taskId: notificationId,
        isUsingCloudImportType,
    });
export const getTaskHistoryCollectionsTaskDataSuccessAction = (payload: {
    record: {};
}) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS,
        payload
    );
export const getTaskHistoryCollectionsTaskDataErrorAction = (
    payload: string[]
) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_COLLECTIONS_TASK_DATA_ERROR,
        payload
    );

/**
 * Action for clearing the task history collection task details.
 */
export const clearTaskHistoryCollectionsTaskDataAction = () =>
    action(TasksActionTypes.CLEAR_TASK_HISTORY_COLLECTIONS_TASK_DATA_SUCCESS);

/**
 * Action called when clicking `Yes` upon confirmation of escalating to collections of the selected items.
 * @param payload
 */
export const escalateToCollectionsAction = (payload: {}) =>
    action(TasksActionTypes.ESCALATE_TO_COLLECTIONS, payload);
export const organisationEscalateToCollectionsAction = (payload: {}) =>
    action(TasksActionTypes.ORGANISATION_ESCALATE_TO_COLLECTIONS, payload);

/**
 * Function for getting the view for tasks page if compact or expanded
 */
export const getTableCompactViewRequestAction = (refetchUserPrefs?: boolean) =>
    action(
        TasksActionTypes.TASKS_GET_TABLE_COMPACT_VIEW_REQUEST,
        refetchUserPrefs
    );

export const getTableCompactViewSuccessAction = (isCompact: boolean) =>
    action(TasksActionTypes.TASKS_GET_TABLE_COMPACT_VIEW_SUCCESS, isCompact);
/**
 * Function for setting the view for tasks page if compact or expanded
 */
export const setTableCompactViewRequestAction = (
    isCompact: boolean,
    callback?: (res: ResponseModalObject) => void
) =>
    action(TasksActionTypes.TASKS_SET_TABLE_COMPACT_VIEW_REQUEST, {
        isCompact,
        callback,
    });

export const setTableCompactViewSuccessAction = (
    refetchCompactViewState?: boolean
) =>
    action(
        TasksActionTypes.TASKS_SET_TABLE_COMPACT_VIEW_SUCCESS,
        refetchCompactViewState
    );

export const setTaskExpandStatusAction = (taskId?: string) =>
    action(TasksActionTypes.SET_TASK_EXPAND_STATUS, taskId);

export const processTaskCompactViewReduxSettingAction = (
    userPrefs: DynamicObject[]
) =>
    action(
        TasksActionTypes.TASKS_PROCESS_COMPACT_VIEW_REDUX_SETTING,
        userPrefs
    );

/**
 * Action called for adding comment from task view.
 * @param payload
 */
export const taskAddCommentRequestAction = (
    payload: TaskAddCommentRequestPayload
) => action(TasksActionTypes.TASK_ADD_COMMENT_REQUEST, payload);

// Actioned Tasks
/**
 * Actions for getting the task history actioned tasks list.
 * 
 */
export const getTaskHistoryActionedTasksRequestAction = (
    payload: GetTaskHistoryActionedTasksRequestPayload
) => action(TasksActionTypes.GET_TASK_HISTORY_ACTIONED_TASKS_REQUEST, payload);

/**
 * Actions for getting the details for task history actioned tasks.
 * @param taskId
 */
export const getTaskHistoryActionedTasksDataRequestAction = (
    batchId: string | null,
    companyId: string | null
) =>
    action(
        TasksActionTypes.GET_TASK_HISTORY_ACTIONED_TASKS_DATA_REQUEST,
        {
            batchId,
            companyId
        }
    );

/**
 * Actions for manually allocating payment.
 */
export const actionManualAllocatePaymentRequest = (payload: {}) =>
    action(TasksActionTypes.MANUALLY_ALLOCATE_PAYMENT_REQUEST, payload);
export const actionOrganisationManualAllocatePaymentRequest = (payload: {}) =>
    action(TasksActionTypes.ORGANISATION_MANUALLY_ALLOCATE_PAYMENT_REQUEST, payload);

/**
 * Actions for manually allocating payment with overpayment.
 */
export const actionManualAllocatePaymentWithOverpaymentRequest = (payload: {}) =>
    action(TasksActionTypes.MANUALLY_ALLOCATE_PAYMENT_WITH_OVERPAYMENT_REQUEST, payload);

/**
* Actions for sending action payment verification task request.
* @param payload
/**
* Actions for getting the details for task history actioned tasks.
* @param taskId
*/
export const taskActionPaymentVerificationTaskRequest = (
    payload: TaskActionPaymentVerificationTaskRequestPayload
) =>
    action(TasksActionTypes.TASK_ACTION_PAYMENT_VERIFICATION_TASK_REQUEST, payload);

    
export const taskActionOrganisationPaymentVerificationTaskRequest = (
    payload: TaskActionPaymentVerificationTaskRequestPayload
) =>
    action(TasksActionTypes.TASK_ACTION_ORGANISATION_PAYMENT_VERIFICATION_TASK_REQUEST, payload);


export const taskActionPaymentRALinkTaskRequest = (
    payload: TaskActionPaymentRALinkTaskRequestPayload
) =>
    action(TasksActionTypes.TASK_ACTION_PAYMENT_RA_LINK_TASK_REQUEST, payload);

export const taskActionOrganisationPaymentRALinkTaskRequest = (
    payload: TaskActionOrganisationPaymentRALinkTaskRequestPayload
) =>
    action(TasksActionTypes.TASK_ACTION_ORGANISATION_PAYMENT_RA_LINK_TASK_REQUEST, payload);

export const taskActionRequestRATaskRequest = (
    payload: TaskActionRequestRATaskRequestPayload
) =>
    action(TasksActionTypes.TASK_ACTION_REQUEST_RA_TASK_REQUEST, payload);

export const taskActionOrganisationRequestRATaskRequest = (
    payload: TaskActionRequestOrganisationRATaskRequestPayload
) =>
    action(TasksActionTypes.TASK_ACTION_ORGANISATION_REQUEST_RA_TASK_REQUEST, payload);
    
/**
* Function for getting the Options for the `Action` options on resolving ticket panel.
* @param companyId
* @param workflowId
* @param stateName
* @param callback
*/
export const getTriggersForWorkflowRequestAction = (
    workflowId: string,
    stateName: string,
    companyId: string,
    callback?: (response: any) => void
) =>
    action(TasksActionTypes.GET_TRIGGERS_FOR_WORKFLOW, {
        workflowId,
        stateName,
        companyId,
        callback,
    });

/**
* Function for getting the Payment Remittance advices for a company.
* @param payload
*/
export const getRemittanceAdvicesRequestAction = (
    payload: GetRemittanceAdvicesRequestPayload,
) =>
    action(TasksActionTypes.GET_REMITTANCE_ADVICES_REQUEST, payload);

export const getRemittanceAdvicesSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
}) => action(TasksActionTypes.GET_TASKS_SUCCESS, payload);


/**
 * Action for getting the actioned payment behaviour insight details in task history.
 * @param batchId
 */
export const getActionedPaymentBehaviourInsightRequestAction = (
    batchId: string, 
    companyId: string | undefined
) => action(TasksActionTypes.GET_ACTIONED_PAYMENT_BEHAVIOUR_INSIGHT_DETAILS_REQUEST, { batchId, companyId });

/* Action called when submitting the form when actioning a payment behaviour inssight task.
 * @param payload
 */
export const taskPaymentBehaviorInsightsRequestAction = (payload: {}) =>
action(TasksActionTypes.TASK_PAYMENT_BEHAVIOR_INSIGHTS_REQUEST, payload);
export const taskOrganisationPaymentBehaviorInsightsRequestAction = (payload: {}) =>
action(TasksActionTypes.TASK_ORGANISATION_PAYMENT_BEHAVIOR_INSIGHTS_REQUEST, payload);

/** 
 * Actions for tasks viewings.
 * @param payload
 */
export const getTaskViewerDetailForUserRequestAction = (payload: GetTaskViewerDetailForUserRequestPayload) =>
    action(TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_REQUEST, payload);
export const getTaskViewerDetailForUserOrganisationRequestAction = (payload: GetTaskViewerDetailForUserRequestPayload) =>
    action(TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_ORGANISATION_REQUEST, payload);
export const getTaskViewerDetailForUserSuccessAction = (payload: {
    viewingUser?: User
}) => action(TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_SUCCESS, payload);
export const getTaskViewerDetailForUserErrorAction = (payload: string[]) =>
    action(TasksActionTypes.GET_TASK_VIEWER_DETAIL_FOR_USER_ERROR, payload);

export const addTaskViewingsRequestAction = (payload: ChangeTaskViewingRequestPayload) =>
    action(TasksActionTypes.ADD_TASK_VIEWINGS_REQUEST, payload);

export const deleteTaskViewingsRequestAction = (payload: ChangeTaskViewingRequestPayload) =>
    action(TasksActionTypes.DELETE_TASK_VIEWINGS_REQUEST, payload);

/**
* Function for getting the Task Customer Custom Fields for a company.
* @param payload
*/
export const getTaskCustomerCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(TasksActionTypes.GET_TASK_CUSTOMER_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });

    /**
* Function for getting the Task Invoice Custom Fields for a company.
* @param payload
*/
export const getTaskInvoiceCustomFieldsForCompanyRequestAction = (
    companyId: string,
    callback?: (response: DynamicObject) => void
) =>
    action(TasksActionTypes.GET_TASK_INVOICE_CUSTOM_FIELDS_FOR_COMPANY_REQUEST, {
        companyId,
        callback,
    });

export const getTaskWorkflowsRequestAction = (payload: TaskWorkflowFilter[]) =>
    action(TasksActionTypes.GET_TASK_WORKFLOWS_REQUEST, payload);
export const getTaskWorkflowsSuccessAction = (workflows: DynamicObject[]) =>
    action(TasksActionTypes.GET_TASK_WORKFLOWS_SUCCESS, workflows);
export const getTaskWorkflowsErrorAction = (messages: string[]) =>
    action(TasksActionTypes.GET_TASK_WORKFLOWS_ERROR, messages);
/**
 * Actions for phone call task
 */
export const taskActionPhoneCallTaskRequest = (
    payload: TaskActionPhoneCallTaskRequest,
    callback: (response: any) => void
) => action(TasksActionTypes.ACTION_PHONE_CALL_REQUEST, {
    ...payload,
    callback
});
export const taskActionOrganisationPhoneCallTaskRequest = (
    payload: TaskActionPhoneCallTaskRequest,
    callback: (response: any) => void
) => action(TasksActionTypes.ACTION_ORGANISATION_PHONE_CALL_REQUEST, {
    ...payload,
    callback
});

/**
 * Actions for getting the details for phone call task history details.
 */
export const getActionedPhoneCallDataRequestAction = (
    payload: GetActionedPhoneCallDataRequest
) =>
    action(TasksActionTypes.GET_ACTIONED_PHONE_CALL_DATA_REQUEST, payload);

/**
* Function for getting the Tasks for organisation.
* @param payload
*/
export const tasksFetchedForOrganisationAction = (
    payload: TasksFetchedForOrganisationPayload
) => action(TasksActionTypes.TASKS_FETCHED_FOR_ORGANISATION_RECEIVED, payload);