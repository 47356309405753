import { insertAt } from '../utils/commonFunctions';
import {
    dateSelectOptions,
    invoicesAmountOperatorOptions,
} from './invoicesSortAndFilters';
import moment from 'moment-timezone';
import { multipleCustomertateOptions } from './remittanceAdvicesSortAndFilter';

export const preConfiguredFilters = {
    ALL_CREDITS: 'All credits',
    ALL_REBATES: 'All rebates'
};

export const creditsTableFilterOptions = [
    // { label: 'All open invoices', value: 'Open' },
    { label: 'All credits', value: preConfiguredFilters.ALL_CREDITS },
];

export const rebatesTableFilterOptions = [
    // { label: 'All open invoices', value: 'Open' },
    { label: 'All rebates', value: preConfiguredFilters.ALL_REBATES },
];

export const creditsSortByOptions = [
    { label: 'Credit number', value: 'Credit number' },
    { label: 'Customer code', value: 'Customer code' },
    { label: 'Customer name', value: 'Company name' },
    { label: 'Credit created date', value: 'Credit created date' },
    { label: 'Credit available date', value: 'Credit available date' },
    { label: 'Amount available', value: 'Amount available' },
    { label: 'Original total', value: 'Original total' },
    { label: 'Type', value: 'Type' },
    { label: 'Created type', value: 'Created type' },
    { label: 'Recurring', value: 'Recurring' },
];

export const rebatesSortByOptions = [
    { label: 'Rebate number', value: 'Rebate number' },
    { label: 'Customer code', value: 'Customer code' },
    { label: 'Customer name', value: 'Company name' },
    { label: 'Credit created date', value: 'Rebate created date' },
    { label: 'Credit available date', value: 'Rebate available date' },
    { label: 'Amount available', value: 'Amount available' },
    { label: 'Original total', value: 'Original total' },
    { label: 'Created Type', value: 'Created Type' },
    { label: 'Recurring', value: 'Recurring' },
];

export const creditsAmountType = [
    { label: 'Available', value: 'Available' },
    { label: 'Original', value: 'Total' },
    { label: 'Percentage', value: 'Percentage' },
];

const {
    THIS_MONTH,
    NEXT_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    NEXT_7_DAYS,
    NEXT_30_DAYS,
    CREATE_DATE_ATB_CURRENT,
    CREATE_DATE_ATB_30_DAYS,
    CREATE_DATE_ATB_60_DAYS,
    CREATE_DATE_ATB_90_PLUS_DAYS,
    CREATE_DATE_ATB_1_MONTH,
    CREATE_DATE_ATB_2_MONTHS,
    CREATE_DATE_ATB_3_PLUS_MONTHS,
    AVAILABLE_DATE_ATB_NOT_AVAILABLE,
    AVAILABLE_DATE_ATB_CURRENT,
    AVAILABLE_DATE_ATB_30_DAYS,
    AVAILABLE_DATE_ATB_60_DAYS,
    AVAILABLE_DATE_ATB_90_PLUS_DAYS,
    AVAILABLE_DATE_ATB_1_MONTH,
    AVAILABLE_DATE_ATB_2_MONTHS,
    AVAILABLE_DATE_ATB_3_PLUS_MONTHS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
} = dateSelectOptions;

export const creditsCreatedSinceOptions = (isCalendarView: boolean) => [
    THIS_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    CREATE_DATE_ATB_CURRENT,
    isCalendarView ? CREATE_DATE_ATB_1_MONTH : CREATE_DATE_ATB_30_DAYS,
    isCalendarView ? CREATE_DATE_ATB_2_MONTHS : CREATE_DATE_ATB_60_DAYS,
    isCalendarView ? CREATE_DATE_ATB_3_PLUS_MONTHS : CREATE_DATE_ATB_90_PLUS_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const creditsAvailableSinceOptions = (isCalendarView: boolean) => [
    THIS_MONTH,
    NEXT_MONTH,
    LAST_MONTH,
    LAST_7_DAYS,
    LAST_30_DAYS,
    NEXT_7_DAYS,
    NEXT_30_DAYS,
    AVAILABLE_DATE_ATB_NOT_AVAILABLE,
    AVAILABLE_DATE_ATB_CURRENT,
    isCalendarView ? AVAILABLE_DATE_ATB_1_MONTH : AVAILABLE_DATE_ATB_30_DAYS,
    isCalendarView ? AVAILABLE_DATE_ATB_2_MONTHS : AVAILABLE_DATE_ATB_60_DAYS,
    isCalendarView ? AVAILABLE_DATE_ATB_3_PLUS_MONTHS :AVAILABLE_DATE_ATB_90_PLUS_DAYS,
    CUSTOM_DATE_RANGE,
    CUSTOM_DAYS_RANGE,
];

export const creditTypeStateOptions = [
    { label: 'Credit', value: 'Credit' },
    { label: 'Rebate', value: 'Rebate' },
];

export const creditCreatedTypeStateOptions = [
    { label: 'Automatic', value: 'Automatic' },
    { label: 'Manually', value: 'Manually' },
];

export const creditRecurringStateOptions = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
];

export const getCreditsFilterBarFilters = (
    customerValuesOption?: any, 
    customerLabel?: string, 
    isCalendarView?: boolean,
    isOrgView: boolean = false,
    companyIds: string [] = [],
    conversionCurrency: number = 1
) => {
    const filters = [
        {
            filterName: 'Credit number',
            filterStateName: 'CreditNumber',
            filterElement: 'input-autocomplete',
            filterQuery: isOrgView
                ? 'GET_CREDITS_FOR_ORGANISATION_AUTOCOMPLETE_FILTER'
                : 'GET_CREDITS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Credit number',
            filterResponse: isOrgView
                ? 'GetCreditsForOrganisation.Credits'
                :'GetCreditsForCompany.Credits',
            filterLabelField: 'Number',
            extraQueryVariables: isOrgView
                ? {
                    CompanyIds: companyIds,
                    ConversionCurrency: conversionCurrency
                }
                : undefined
        },
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: isOrgView
                ? 'GET_CUSTOMERS_FOR_ORGANISATION_AUTOCOMPLETE_FILTER'
                :'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: isOrgView
                ? 'GetCustomersForOrganisation.Customers'
                : 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
            extraQueryVariables: isOrgView
                ? {
                    CompanyIds: companyIds,
                    ConversionCurrency: conversionCurrency
                }
                : undefined
        },
        {
            filterName: 'Multiple Customer',
            filterStateName: 'MultipleCustomer',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select if multiple customer',
            filterOptions: multipleCustomertateOptions,
        },
        {
            filterName: 'Created since',
            filterStateName: 'CreatedDate',
            filterElement: 'select',
            filterType: 'text',
            filterMaxDate: moment(),
            filterOptions: creditsCreatedSinceOptions(!!isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to-last',
            },
        },
        {
            filterName: 'Available since',
            filterStateName: 'AvailableDate',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: creditsAvailableSinceOptions(!!isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to',
            },
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: creditsAmountType,
                },
                {
                    AmountOperator: invoicesAmountOperatorOptions,
                },
            ],
        },
        {
            filterName: 'Type',
            filterStateName: 'Type',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select type status',
            filterOptions: creditTypeStateOptions,
        },
        {
            filterName: 'Created Type',
            filterStateName: 'CreatedType',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select created type status',
            filterOptions: creditCreatedTypeStateOptions,
        },
        {
            filterName: 'Recurring',
            filterStateName: 'Recurring',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select recurring status',
            filterOptions: creditRecurringStateOptions,
        },
    ];

    if (customerValuesOption && customerLabel) {
        filters.splice(2, 0, {
            filterName: customerLabel,
            filterStateName: 'CustomerFirstLetter',
            filterElement: 'checkbox-group',
            filterType: 'array',
            filterOptions: customerValuesOption
        });
    }

    return filters;
};

export const creditsStateFilterOptions = [
    { label: 'Open credits', value: 'Open' },
    { label: 'All credits', value: 'All' },
];

export const rebatesStateFilterOptions = [
    { label: 'Open rebates', value: 'Open' },
    { label: 'All rebates', value: 'All' },
];

/**
 * For credit changes
 */

export const creditChangesFilters = {
    ALL_CHANGES: 'All changes',
};

export const creditChangesTableFilterOptions = [
    {
        label: 'All changes',
        value: creditChangesFilters.ALL_CHANGES,
    },
];

export const creditChangeTypes = {
    NewResource: 'NewResource',
    UpdatedResource: 'UpdatedResource',
    Allocation: 'Allocation',
    Deallocation: 'Deallocation',
    Overpayment: 'Overpayment'
};

export const getCreditsWidgetFilters = (
    isCalendarView: boolean,
    supportCashAllocation: any
) => {

    let creditsWidgetFilters = [
        {
            filterName: 'Customer',
            filterStateName: 'Customer',
            filterElement: 'input-autocomplete',
            filterQuery: 'GET_CUSTOMERS_FOR_COMPANY_AUTOCOMPLETE_FILTER',
            filterSort: 'Company name',
            filterResponse: 'GetCustomersForCompany.Customers',
            filterLabelField: 'DisplayName',
        },
        {
            filterName: 'Created since',
            filterStateName: 'CreatedDate',
            filterElement: 'select',
            filterType: 'text',
            filterMaxDate: moment(),
            filterOptions: creditsCreatedSinceOptions(isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to-last',
            },
            filterPlaceholder: 'Select Date Range',
        },
        {
            filterName: 'Available since',
            filterStateName: 'AvailableDate',
            filterElement: 'select',
            filterType: 'text',
            filterOptions: creditsAvailableSinceOptions(isCalendarView),
            filterOptionsCustomTypes: {
                'Custom date range': 'date-range',
                'Custom days range': 'all-days-range-from-to',
            },
            filterPlaceholder: 'Select Date Range',
        },
        {
            filterName: 'Amount',
            filterStateName: 'Amount',
            filterElement: 'select-multiple-and-input-amount',
            filterType: 'text',
            filterOptions: [
                {
                    AmountType: creditsAmountType,
                    filterOptionName: 'AmountType',
                    filterPlaceholder: 'Select Amount Type'
                },
                {
                    amountOperator: invoicesAmountOperatorOptions,
                    filterOptionName: 'AmountOperator',
                    filterPlaceholder: 'Select Operator',
                },
            ],
        },
        {
            filterName: 'Multiple Customer',
            filterStateName: 'MultipleCustomer',
            filterElement: 'radio-group',
            filterType: 'text',
            filterPlaceholder: 'Select if multiple customer',
            filterOptions: multipleCustomertateOptions,
        },
    ];

    if (supportCashAllocation) {
        creditsWidgetFilters = insertAt(
            creditsWidgetFilters,
            1,
            [
                {
                    filterName: 'Type',
                    filterStateName: 'ResourceType',
                    filterElement: 'radio-group',
                    filterType: 'text',
                    filterPlaceholder: 'Select Type',
                    filterOptions: creditTypeStateOptions,
                },
            ]
        );
    }

    return creditsWidgetFilters;
}