/**
 * File responsible for all the UI and actions for Import page - `/app/import`.
 */

import { Button, Card, Col, Icon, Modal, Row, Typography, Upload } from 'antd';
import { delay, get, includes } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationAfterActionDelayPollTime } from '../../config/config';
import { ApplicationState } from '../../store';
import { CompanyUserRole } from '../../store/companies/types';
import {
    downloadTemplateFileRequestAction,
    getCheckImportJobStatusRequestAction,
    uploadTemplateFileRequestAction,
} from '../../store/import/actions';
import { triggerRefetchNotificationsBooleanAction } from '../../store/notifications/actions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { downloadFileFromPresignedUrl, getTranslatedText } from '../../utils/commonFunctions';


const ModalWithSpinner = lazy(
    () => import('../../components/common/ModalWithSpinner')
);

const { Title } = Typography;

interface IProps {
    readonly downloadTemplate: string,
    readonly uploadFileType?: string,
    readonly templateText: string
}
const ImportExcelComponent: React.FC<IProps> = ({
    downloadTemplate,
    uploadFileType,
    templateText
}: IProps) => {
    const dispatch = useDispatch();
    
    const selectedUserCompany: CompanyUserRole = useSelector(
        (state: ApplicationState) => state.companies.selectedUserCompany
    );

    const [fileToUpload, setFileToUpload] = useState<any>();
    const [downloadingFile, setDownloadingFile] = useState<boolean>(false);
    const [uploadingFile, setUploadingFile] = useState<boolean>(false);

    /**
     * Listens for company switch.
     */
    useEffect(() => {
        setFileToUpload(null);
    }, [selectedUserCompany]);

    /**
     * Function called when clicking on the `Download Template File` button.
     */
    const downloadTemplateFile = () => {
        setDownloadingFile(true);
        dispatch(
            downloadTemplateFileRequestAction(downloadTemplate, (res: any) => {
                const presignedUrl = res.ImportTemplateUrl;
                downloadFileFromPresignedUrl(presignedUrl, () => {
                    setDownloadingFile(false);
                });
            })
        );
    };
    
    /**
     * Function called when clicking the `Import` button.
     */
    const uploadFile = () => {
        const fileType = get(fileToUpload, 'type', '').toLowerCase();
        if (
            fileType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') === -1 &&
            fileType.indexOf('application/vnd.ms-excel.sheet.macroenabled') === -1
        ) {
            return Modal.error({
                title: getTranslatedText("File not allowed"),
                content: (
                    <div>{getTranslatedText("Only .xlsx and .xlsm files are allowed").split(/(<b>[^<]+<\/b>)/g) // Split by <b>...</b> tags
                        .map((part, index) =>
                            part.startsWith("<b>") ? (
                                <b key={index}>{part.replace(/<\/?b>/g, "")}</b>
                            ) : (
                                part
                            )
                        )}</div>
                ),
                okText: getTranslatedText("OK"),
            });
        }
        else 
        {
            dispatch(
                getCheckImportJobStatusRequestAction((res: any) => {
                    if(res && res.toString().toLowerCase() === 'true') {
                        return Modal.warning({
                            title: getTranslatedText('Import notification!'),
                            content: (
                                <div>
                                    {getTranslatedText('The import job will start once the current import is finished!')}
                                </div>
                            ),
                        });
                    }
                    else
                    {
                        setUploadingFile(true);
                        dispatch(
                            uploadTemplateFileRequestAction(uploadFileType, (res: DynamicObject) => {
                                if (res.IsSuccess === false) {
                                    setUploadingFile(false);
                                    Modal.error({
                                        title: getTranslatedText("Error uploading file"),
                                        content: getTranslatedText(get(res, 'Messages.0')),
                                        okText: getTranslatedText("OK"),
                                    });
                                } else {
                                    uploadFileUsingPresignedUrl(res.ImportFileUrl);
                                }
                            })
                        );
                    }
                })
            );
        }
    }

    /**
     * Function called when uploading the file using presigned url - fetched from backend.
     * @param preSignedUrl - presigned url from backend
     */
    const uploadFileUsingPresignedUrl = async (preSignedUrl: string) => {
        fetch(preSignedUrl, {
            method: 'PUT',
            body: fileToUpload,
        })
            .then((_res: any) => {
                setUploadingFile(false);
                setFileToUpload(null);
                dispatchFetchNewNotifications();
                Modal.success({
                    title: getTranslatedText("Success"),
                    content:
                        getTranslatedText("File uploaded successfully! Import is running in our end. We'll notify you once the import is successful or if we encounter some errors"),
                    okText: getTranslatedText("OK")
                });
            })
            .catch((_error: any) => {
                dispatchFetchNewNotifications();
                setUploadingFile(false);
                Modal.error({
                    title: getTranslatedText("Error"),
                    content: getTranslatedText("Failed to upload the file!"),
                    okText: getTranslatedText("OK"),
                });
            });
    };

    /**
     * Function to get new notifications after uploading the file.
     */
    const dispatchFetchNewNotifications = () => {
        delay(
            () => dispatch(triggerRefetchNotificationsBooleanAction(true)),
            notificationAfterActionDelayPollTime
        );
    };

    /**
     * Upload properties required, based on Ant Design Upload.
     */
    const uploadProps = {
        accept: '.xlsx,.xlsm',
        name: fileToUpload ? '' : getTranslatedText("No file selected"),
        onRemove: () => {
            setFileToUpload(null);
        },
        beforeUpload: (file: any) => {
            setFileToUpload(file);
            return false;
        },
        fileList: fileToUpload ? [fileToUpload] : [],
    };

    return (
        <Col xxl={12} xl={18} lg={24}>
            <QueueAnim type={['right', 'left']} leaveReverse>
                <Row key="title-container">
                    <Col span={24}>
                        <Title level={3}>{getTranslatedText("Import using Excel")}</Title>
                    </Col>
                </Row>
                <br />
                <Row key="steps-container">
                    <Col span={24}>
                        <Card
                            className="mb-10"
                            type="inner"
                            title={
                                <b>
                                    {getTranslatedText(`Step 1. Download our ${ templateText } template file`)}
                                </b>
                            }
                            key="download-template-file"
                        >
                            <div>
                                {getTranslatedText(`Start by downloading our ${templateText} Excel (xlsx) template file`)}
                            </div>
                            <div>
                                {getTranslatedText(`The file has the correct column headings we need to import your ${templateText.includes("invoices") ? "invoice" : "payment plan"} data`)}
                            </div>
                            <div className="mt-10">
                                <Button
                                    type="primary"
                                    loading={downloadingFile}
                                    onClick={downloadTemplateFile}
                                >
                                    <Icon type="file-excel" />
                                    {getTranslatedText("Download Template File")}
                                </Button>
                            </div>
                        </Card>
                        <Card
                            className="mb-10"
                            type="inner"
                            title={
                                <b>
                                    {getTranslatedText(`Step 2. ${templateText.includes("manual communication") ? 'Enter' : 'Copy'} your ${templateText} into the template`)}
                                </b>
                            }
                            key="copy-invoices-to-template"
                        >
                            <div>
                                {templateText.includes("invoices") ? (
                                    getTranslatedText(`Export all of your ${templateText} from your accounting system as a comma separated list (CSV file) or an Excel file. Using Excel, or another xlsx spreadsheet editor, copy and paste your ${templateText.includes("invoices") ? "invoices" : "payment plans"} from the exported file into the ${templateText} template. Make sure the ${templateText.includes("invoices") ? "invoice" : "payment plan"} data you copy matches the column headings provided in the template`)
                                ) : templateText.includes("payment plans") ? (
                                    getTranslatedText(`Export all of your ${templateText} from your system as a comma separated list (CSV file) or an Excel file. Using Excel, or another xlsx spreadsheet editor, copy and paste your ${templateText.includes("invoices") ? "invoices" : "payment plans"} from the exported file into the ${templateText} template. Make sure the ${templateText.includes("invoices") ? "invoice" : "payment plan"} data you copy matches the column headings provided in the template`)   
                                ) : (
                                    getTranslatedText(`Enter all of the manual communications you need to send as an Excel file. Make sure the manual communications data you enter matches the column headings provided in the template.`)  
                                )}
                                
                            </div>
                            <br />
                            <div>
                                <b className="red">{getTranslatedText("IMPORTANT")}</b>
                                <div className="mt-10 red">
                                    {getTranslatedText(`Do not change the column headings provided in the ${templateText} template. These need to be unchanged for the import to work in the next step.`)}
                                </div>
                                {uploadFileType !== 'mc' && <div className="mt-10 red">
                                    {getTranslatedText("Dates are assumed to be in English (Australia) format. For example, 25/12/2016 or 25 Dec 2016").split(/(<span className="fw-600">[^<]+<\/span>)/g)
                                        .map((part, index) => {
                                            if (part.startsWith('<span className="fw-600">')) {
                                                return (
                                                    <span key={index} className="fw-600">
                                                        {part.replace(/<\/?span[^>]*>/g, '')}
                                                    </span>
                                                );
                                            }
                                            return part;
                                        })}
                                </div>}
                                {uploadFileType !== 'mc' && <div className="mt-10 red">
                                    {getTranslatedText("Columns marked with an asterisk are required fields and the import requires them to be completed for each line in the template")}
                                </div>}
                                {uploadFileType !== 'mc' && <div className="mt-10 red fw-600">
                                    {getTranslatedText(`All previously loaded ${templateText.includes("invoices") ? "invoices" : "payment plans"} that are missing from the ${templateText} template shall be considered as ${templateText.includes("invoices") ? "settled" : "closed"} by our system`)}
                                </div>}
                            </div>
                        </Card>
                        <Card
                            type="inner"
                            title={
                                <b>
                                    {getTranslatedText(`Step 3. Import the updated ${templateText} template file`)}
                                </b>
                            }
                            key="import-template-file"
                        >
                            <div className="fw-600">
                                {getTranslatedText("Select the file to import")}
                            </div>
                            <div
                                className="import-file-container"
                                style={{ display: 'flex' }}
                            >
                                <Upload {...uploadProps}>
                                    <Button type="primary">{getTranslatedText("Browse")}</Button>
                                </Upload>
                                {!fileToUpload && (
                                    <span className="ml-10">
                                        {getTranslatedText("No file selected")}
                                    </span>
                                )}
                            </div>
                            <div className="mt-10">
                                {getTranslatedText("The file you import must be a Microsoft Excel version 2007, or greater, spreadsheet file")}
                            </div>
                            <div>
                                {getTranslatedText("The name of your file should end with .xlsx or .xlsm")}
                            </div>
                        </Card>
                        <div className="mt-10 ta-right">
                            <Button
                                type="primary"
                                disabled={!fileToUpload}
                                loading={uploadingFile}
                                onClick={uploadFile}
                            >
                                {getTranslatedText("Import")}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </QueueAnim>
            {downloadingFile && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Preparing Download")}
                        modalVisible={downloadingFile}
                        displayMessage={getTranslatedText("Please wait while we prepare the template file")}
                    />
                </Suspense>
            )}
            {uploadingFile && (
                <Suspense fallback={null}>
                    <ModalWithSpinner
                        modalTitle={getTranslatedText("Uploading File")}
                        modalVisible={uploadingFile}
                        displayMessage={getTranslatedText("Please wait while we upload your file")}
                    />
                </Suspense>
            )}
        </Col>
    );
};

export default ImportExcelComponent;
