/**
 * Component for populating each item in support ticket lists page.
 */

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Row, Col, Tag } from 'antd';
import { getSelectedSupportTicketId } from '../../store/supportTickets/sagas';
import { DynamicObject } from '../../utils/commonInterfaces';
import {getIfIsLg, getTranslatedText} from '../../utils/commonFunctions';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import FontAwesome from '../../components/common/FontAwesome';
import AvatarNameInitialsComponent from '../common/AvatarNameInitialsComponent';

interface IProps {
    readonly item: any;
    readonly style: {};
    readonly onRowClick: (item: DynamicObject) => void;
    readonly formatDateLocal: (
        date: any,
        fromFormat?: string | null,
        toFormat?: string | null
    ) => string;
}
const SupportTicketItemComponent: React.FC<IProps> = ({
    item,
    style,
    onRowClick,
    formatDateLocal
}: IProps) => {
    const {
        id,
        status,
        createdTime
    } = item;
    const selectedId = useSelector(getSelectedSupportTicketId);

    const isLg = getIfIsLg();

    let itemColor = 'green';

    if (status == "Open") {
        itemColor = 'green';
    } else if(status == "On Hold") {
        itemColor = 'orange';
    } else if(status == "Escalated") {
        itemColor = 'red';
    }
    else {
        itemColor = 'gray';
    }
    const iconLgStyle = { minHeight: isLg ? 48 : 'auto' };

    /**
     * Common function that gets the row data and adds an identifier named `key`.
     */
    const getItemInfo = () => {
        const itemInfo: any = item;
        itemInfo.key = id;

        return itemInfo;
    };

    /**
    * Function called when the row is clicked.
    */
    const handleRowClick = () => {
        const itemInfo = getItemInfo();

        if (onRowClick) onRowClick(itemInfo);
    };
    /**
     * Function called for generating the ticket item icon if `generateTicketIcon` HOC function exists.
     */
    const generateItemIcon = () => {
        const fontSize = (getIfIsLg() ? 24 : undefined);
        return (
            <div
                className="fa-layers fa-fw fa-2x"
                style={{ fontSize }}
            >
                <FontAwesome
                    icon={['far', 'circle']}
                    className={itemColor}
                    size="2x"
                    transform="shrink-2 left-3"
                />
                <FontAwesome
                    icon={['fas', 'info']}
                    className={itemColor}
                    size="2x"
                    transform="shrink-9"
                />
            </div>
        )
    };

    const renderSupportTicketItem = () => {
        return (
            <Row
                className="fx-center-xl center-flex-i pr-8 h-100"
                gutter={{ xxl: 10, xl: 10, lg: 20 }}
            >
                <Col
                    className="h-100"
                    xxl={2}
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                >
                    <Row className="h-100" type="flex" align="middle">
                        <Col
                            xxl={16}
                            xl={16}
                            lg={24}
                            md={24}
                            sm={24}
                            xs={24}
                            className="ta-center"
                        >
                            <div
                                className="ta-center center-flex-all"
                                style={iconLgStyle}
                            >
                                {generateItemIcon()}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xxl={22} xl={21} lg={22} md={22} sm={22} xs={22}>
                    <Row className="first-row-container" type="flex">
                        <Col span={24}>
                            <div>
                                <Row gutter={20}>
                                    <Col
                                        xxl={14}
                                        xl={13}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Row type="flex" gutter={60}>
                                            <Col
                                                className={`${
                                                    isLg ? 'flx-5' : 'flx-2'
                                                } mxw-100`}
                                            >
                                                <b className="ws-nw va-top" style={{ whiteSpace: 'pre' }}>
                                                #{get(item, 'ticketNumber')}
                                                </b>
                                            </Col>
                                            
                                            <Col
                                                className={`${
                                                    isLg ? 'flx-5' : 'flx-2'
                                                } mxw-100`}
                                            >
                                                <b className="ws-nw va-top">
                                                    {get(item, 'subject')}
                                                </b>
                                            </Col>
                                            <Col span={24}>
                                                {createdDatesComponent}
                                            </Col>
                                            <Col span={24}>
                                                {getTranslatedText("Priority")}{' '}
                                                {getTranslatedText(get(item, 'priority'))}
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                    <Col
                                        xxl={5}
                                        xl={11}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Row type="flex">
                                            <Col className="ta-right totals-container fx-1">
                                                <Row>
                                                    <Col>
                                                        <Tag color={itemColor}>{getTranslatedText(get(item, 'status'))}</Tag>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col
                                        xxl={5}
                                        xl={13}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Row type="flex" gutter={60}>
                                            <Col className="ta-right totals-container fx-1">
                                                <AvatarNameInitialsComponent
                                                    fullName={get(item.contact, 'firstName') + " " +get(item.contact, 'lastName')}
                                                    size={50}
                                                    isDefault={false}
                                                    showTooltip
                                                    fontSize={22}
                                                />
                                                <br />
                                                    <div className="action-display-container mt-8">
                                                        <div className="ta-right totals-container fx-1">
                                                            <b> {getTranslatedText("Raised by")}:</b>
                                                        </div>
                                                        <div className="actioned-by-display">
                                                            {get(item.contact, 'firstName') + " " +get(item.contact, 'lastName')}
                                                        </div>
                                                    </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    /**
     * Function to populate `Created on` components.
     * Defined to easily be used in different screen size layouts.
     */
    const createdDatesComponent = (
        <Row type="flex" gutter={60}>
            <Col>{getTranslatedText("Created on")} {formatDateLocal(createdTime)}</Col>
        </Row>
    );


    return (
        <Row
            className={`list-item-clickable ${
                selectedId === id ? 'list-item-clicked' : ''
            }`}
            key={get(item, 'key')}
            style={style}
            onClick={handleRowClick}
        >
            <Col
                span={24}
                style={{
                    height: '100%',
                    paddingTop: 10,
                    paddingBottom: 10,
                }}
            >  
                {renderSupportTicketItem()}
            </Col>
        </Row>
        
    );
};

export default memo(
    withDateFormatHandler(withNumberFormatHandler(SupportTicketItemComponent))
);
