/**
 * File responsible for all the UI and actions for Settings>Payment plan Configuration page - update payment plan configuration tab item - `/app/settings/payment-plan-config`.
 */

import { Col, Modal, Row, Select, Tabs, Empty } from 'antd';
import { History as IHistory } from 'history';
import { get, isEqual, map, isObject, isEmpty, unset } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, { forwardRef, Ref, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouteLeavingGuard from '../../../../components/common/RouteLeavingGuard';
import { ApplicationState } from '../../../../store';
import { getPaymentPlanConfigRequestAction, createPaymentPlanConfigRequestAction } from '../../../../store/paymentPlans/actions';
import { Company } from '../../../../store/companies/types';
import { PaymentPlansState, PaymentPlanConfig } from '../../../../store/paymentPlans/types';
import {
    DynamicObject,
    ResponseModalObject
} from '../../../../utils/commonInterfaces';
import {
    FORM_FIELDS,
    FORM_DISPLAY,
    TAB_NAME,
    INSTALMENT_TYPE
} from '../../../../constants/paymentPlanConfigs';
import moment from 'moment-timezone';
import PaymentPlanOptionDetailsTabContent from '../../../../components/common/PaymentPlanOptionDetailsTabContent';
import PaymentPlanCustomFieldTabContent from '../../../../components/settings/PaymentPlanCustomFieldTabContent';
import PaymentPlanDynamicCustomFieldTabContent from '../../../../components/settings/PaymentPlanDynamicCustomFieldTabContent';
import PaymentPlanOptionDetailsDynamicTabContent from '../../../../components/common/PaymentPlanOptionDetailsDynamicTabContent';
import PaymentPlanScheduleDateTabContent from '../../../../components/settings/PaymentPlanScheduleDateTabContent';
import { getLoadingSkeletons, generateGUID, getTranslatedText } from '../../../../utils/commonFunctions';
import { dateFormatDDMMYYYYSlash } from '../../../../constants/dateFormats';

const { Option } = Select;
const { TabPane } = Tabs;

interface IProps {
    history: typeof IHistory;
    selectedUserCompany: Company;
    setFormHasChanges: () => void;
    form?: any;
    isTemplate: boolean;
    formHasChanges: boolean;
    handleModalSaveResponse: (response: ResponseModalObject) => void;
}
const UpdatePaymentPlanConfigManagementPage: React.FC<IProps> = forwardRef(
    (
        {
            selectedUserCompany,
            history,
            setFormHasChanges,
            form,
            isTemplate,
            formHasChanges,
            handleModalSaveResponse
        }: IProps,
        ref: Ref<any>
    ) => {
        const dispatch = useDispatch();
        const drawerRef = useRef(null);
        const paymentPlansState: PaymentPlansState = useSelector(
            (state: ApplicationState) => state.paymentPlans
        );
        const { validateFields, resetFields, setFieldsValue } = form;
        const paymentPlanConfigs: PaymentPlanConfig[] | undefined = paymentPlansState.paymentPlanConfigs;
        const [paymentPlanOptionKeys, setPaymentPlanOptionKeys] = useState<number[]>([]);
        const [paymentPlanOptionTabId, setPaymentPlanOptionTabId] = useState(1);
        const [paymentPlanOptions, setPaymentPlanOptions] = useState<any[]>([]);
        const [dynamicPaymentPlanOptions, setDynamicPaymentPlanOptions] = useState<any[]>([]);
        const [paymentPlanScheduleDateKeys, setPaymentPlanScheduleDateKeys] = useState<{ [key: number]: string[] }>({});
        const [paymentPlanScheduleDates, setPaymentPlanScheduleDates] = useState<{ [key: number]: string[] }>({});
        const [paymentPlanScheduleDateIds, setPaymentPlanScheduleDateIds] = useState<{ [key: number]: number }>({});
        const [paymentPlanCustomFields, setPaymentPlanCustomFields] = useState<{ [key: number]: { name: string; value: string }[] }>({});
        const [paymentPlanCustomFieldTabs, setPaymentPlanCustomFieldTabs] = useState<{ [key: number]: any[] }>({});
        const [paymentPlanDynamicCustomFieldTabs, setPaymentPlanDynamicCustomFieldTabs] = useState<{ [key: number]: any[] }>({});
        const [paymentPlanCustomFieldIds, setPaymentPlanCustomFieldIds] = useState<{ [key: number]: number }>({});
        const { loading } = paymentPlansState;
        const [selectedPaymentPlanOptionTab, setSelectedPaymentPlanOptionTab] = useState<string>("payment-plan-0");
        const [selectedCustomFieldTab, setSelectedCustomFieldTab] = useState<string>('payment-plan-0-custom-field-0');
        const [paymentPlanConfigFormState, setPaymentPlanConfigFormState] = useState<PaymentPlanConfig[]>([]);
        const [newPaymentPlanConfigFormState, setNewPaymentPlanConfigFormState] = useState<{}>({});
        const [addPaymentPercents, setAddPaymentPercents] = useState<{ [key: number]: boolean }>({});
        const [validateTab, setValidateTab] = useState<boolean>(false);

        /**
         * This is used for the Parent component wrapping this one be able to call the functions
         * inside this (save, cancel, checkForChanges).
         */
        React.useImperativeHandle(ref, () => ({
            save: () => {
                handleSave();
            },
            checkForChanges: () => {
                return checkForChanges();
            },
        }));

        /**
         * Check if there are any changes in the form (unsaved).
         */
        const checkForChanges = () => {
            const scheduleDatesDynamic = paymentPlanScheduleDates;

            //remove ScheduleDatesDisplay,PayPercentScheduleDatesDisplay,PayPercentHidePreview, PayPercentTotal, PayPercentWarning 
            //since we only need it for display and not for data comparison.
            const paymentPlanConfigFormStateCopy = paymentPlanConfigFormState.map(
                ({
                    ScheduleDatesDisplay,
                    PayPercentScheduleDatesDisplay,
                    PayPercentHidePreview,
                    PayPercentTotal,
                    PayPercentWarning,
                    paymentPlanKeyIndex,
                    InstalmentType,
                    InstalmentMin,
                    InstalmentMax,
                    ...rest
                }) => rest)

            const paymentPlanConfigCopy = paymentPlanConfigs.filter(paymentPlan => paymentPlan.IsTemplate == isTemplate && (!paymentPlan.IsDefault
                || paymentPlan.IsDefault == undefined))

            let stateNotEqual = !isEqual(
                paymentPlanConfigCopy.map(
                    ({
                        InstalmentType,
                        ...rest
                    }) => rest),
                paymentPlanConfigFormStateCopy
            );

            if (!isEmpty(scheduleDatesDynamic) && !isArrayWithEmptyValues(scheduleDatesDynamic)) {
                return true;
            }
            if (!isEmpty(paymentPlanCustomFields)) {
                return true;
            }
            if (!isEmpty(dynamicPaymentPlanOptions)) {
                return true;
            }

            return stateNotEqual ? true : false;
        };

        const isArrayWithEmptyValues = (obj: any): boolean => {
            const keys = Object.keys(obj);

            if (keys.length == 1) {
                return keys.every((key) => {
                    const value = obj[key];
                    return value.length === 0;
                });
            } else {
                return true
            }

        };

        /**
        * Function that resets all the changes and set the data
        * equal to the one saved in redux.
        */
        const syncStateFromRedux = () => {
            if (paymentPlanConfigs) {
                const updatedConfigs = paymentPlanConfigs
                    .filter(paymentPlan =>
                        paymentPlan.IsTemplate === isTemplate &&
                        (!paymentPlan.IsDefault || paymentPlan.IsDefault === undefined)
                    )
                    .map((paymentPlan, index) => ({
                        ...paymentPlan, // Keep existing fields
                        paymentPlanKeyIndex: index,         // Add index field
                    }));

                setSelectedCustomFieldTab('payment-plan-0-custom-field-0');
                setPaymentPlanConfigFormState(updatedConfigs);
                updatePaymentPlanOptionPanes();
                updateInitialPaymentPlanCustomFields();
                updateInitialPaymentPlanScheduleDates();
                setFormHasChanges();
            }
        };

        const updatePaymentPlanOptionPanes = () => {
            let tabPane: any[] = [];
            let customFieldTabPane: { [key: number]: any[] } = {};

            if (paymentPlanConfigFormState) {
                paymentPlanConfigFormState.map((paymentPlan, paymentPlanOptionIndex) => {
                    const tabOption = {
                        key: `payment-plan-${paymentPlanOptionIndex}`,
                        title: paymentPlan.Name ? paymentPlan.Name : getTranslatedText("Payment Plan Option"),
                        component: PaymentPlanOptionDetailsTabContent,
                        haveErrors: false
                    }

                    tabPane.push(tabOption);

                    if (!isEmpty(paymentPlan.CustomFields) && paymentPlan.CustomFields != null) {
                        if (!customFieldTabPane[paymentPlanOptionIndex]) {
                            customFieldTabPane[paymentPlanOptionIndex] = [];
                        }

                        paymentPlan.CustomFields.map((customField, customFieldIndex) => {
                            const customFieldTabOption = {
                                key: `payment-plan-${paymentPlanOptionIndex}-custom-field-${customFieldIndex}`,
                                title: getTranslatedText("Custom field [index]").replace("[index]", (customFieldIndex + 1).toString()),
                                customField: customField,
                                component: PaymentPlanCustomFieldTabContent
                            }

                            customFieldTabPane[paymentPlanOptionIndex].push(customFieldTabOption)
                        });
                    }
                })
            }

            if (isEmpty(paymentPlanCustomFieldTabs)) {
                setPaymentPlanCustomFieldTabs(customFieldTabPane);
            }

            setPaymentPlanOptions(tabPane);
        }

        const updateInitialPaymentPlanScheduleDates = () => {
            const idsInitialMapping: { [key: number]: number } = {};
            const paymentPlanScheduleDatesInitialMapping: { [key: number]: [] } = {};
            for (let i = 0; i <= paymentPlanConfigFormState.length; i++) {
                idsInitialMapping[i] = 1;
                paymentPlanScheduleDatesInitialMapping[i] = [];
            }

            setPaymentPlanScheduleDateIds(idsInitialMapping);
        }

        const updateInitialPaymentPlanCustomFields = () => {
            const idsInitialMapping: { [key: number]: number } = {};
            const customFieldInitialMapping: { [key: number]: [] } = {};
            for (let i = 0; i <= paymentPlanConfigFormState.length; i++) {
                idsInitialMapping[i] = 1;
                customFieldInitialMapping[i] = [];
            }

            setPaymentPlanCustomFieldIds(idsInitialMapping);
        }

        const fetchInitialData = () => {
            dispatch(getPaymentPlanConfigRequestAction())
        }

        useEffect(syncStateFromRedux, [paymentPlanConfigs, selectedUserCompany]);
        useEffect(fetchInitialData, [selectedUserCompany]);

        /**
        * Common function for updating the form state object
        * created to minimize repitition when updating form state.
        * @param formStateObject
        */
        const updateFormStateObject = (formStateObject: PaymentPlanConfig[]) => {
            setPaymentPlanConfigFormState((prevState) => {
                const newState = [...prevState];
                formStateObject.forEach((item, index) => {
                    newState[index] = {
                        ...newState[index],
                        ...item,
                    };
                });
                return newState;
            });
            setFormHasChanges();
        };

        /**
        * Common function for updating the form state object
        * created to minimize repitition when updating form state.
        * @param formStateObject
        */
        const updatePaymentPlanScheduleDates = (name: string, correctValue: any, paymentPlanOptionIndex: number, scheduleDateIndex: number, paymentPlanOptionKeyIndex: number | undefined) => {
            correctValue = correctValue ? correctValue.format(dateFormatDDMMYYYYSlash) : '';

            if (name.includes("Dynamic") && (paymentPlanOptionKeyIndex !== null && paymentPlanOptionKeyIndex !== undefined)) {
                setPaymentPlanScheduleDates(prevState => {
                    const newState = { ...prevState };

                    if (newState[paymentPlanOptionKeyIndex]) {
                        const updatedArray = [...newState[paymentPlanOptionKeyIndex]];

                        updatedArray[scheduleDateIndex] = correctValue;
                        newState[paymentPlanOptionKeyIndex] = updatedArray;

                        updatePayPercentageFields(paymentPlanOptionIndex, newState[paymentPlanOptionKeyIndex], scheduleDateIndex)
                        return newState;
                    }

                    return prevState;
                });
            } else {
                if (Array.isArray(paymentPlanConfigFormState[paymentPlanOptionIndex].ScheduleDates)) {
                    const scheduleDatesCopy = [
                        ...(paymentPlanConfigFormState[paymentPlanOptionIndex].ScheduleDates || []),
                    ];
                    scheduleDatesCopy[scheduleDateIndex] = correctValue;
                    setPaymentPlanConfigFormState((prevState) => {
                        const newState = [...prevState];
                        const updatedOption = {
                            ...newState[paymentPlanOptionIndex],
                            ScheduleDates: scheduleDatesCopy,
                        };
                        newState[paymentPlanOptionIndex] = updatedOption;
                        return newState;
                    });

                    updatePayPercentageFields(paymentPlanOptionIndex, [], scheduleDateIndex);
                }

            }
        };

        /**
        * Common function for updating the form state object
        * created to minimize repitition when updating form state.
        * @param formStateObject
        */
        const updatePaymentPlanCustomFields = (name: string, correctValue: any, paymentPlanOptionIndex: number, customFieldIndex: number, paymentPlanOptionKeyIndex: number | undefined) => {
            if (name.includes("Dynamic")) {
                setPaymentPlanCustomFields(prevState => {
                    const newState = { ...prevState };

                    if (newState[paymentPlanOptionIndex]) {
                        const updatedArray = [...newState[paymentPlanOptionIndex]];

                        if (name.includes("Name")) {
                            updatedArray[customFieldIndex].name = correctValue;
                        } else {
                            updatedArray[customFieldIndex].value = correctValue;
                        }

                        newState[paymentPlanOptionIndex] = updatedArray;

                        return newState;
                    }

                    return prevState;
                });
            } else {
                setPaymentPlanConfigFormState((prevState) => {
                    const newState = [...prevState];
                    const paymentPlanConfigStateCopy = {
                        ...newState[paymentPlanOptionIndex],
                    };

                    if (paymentPlanConfigStateCopy.CustomFields) {
                        const customFieldsCopy = paymentPlanConfigStateCopy.CustomFields.map(field => ({
                            ...field, // Create a new object for each field
                        }));

                        if (name.includes("Name")) {
                            customFieldsCopy[customFieldIndex].Name = correctValue;
                        } else {
                            customFieldsCopy[customFieldIndex].Value = correctValue;
                        }

                        paymentPlanConfigStateCopy.CustomFields = customFieldsCopy;
                        newState[paymentPlanOptionIndex] = paymentPlanConfigStateCopy;
                    }
                    return newState;
                });
            }
            setFormHasChanges();
        };

        /**
        * Function that updates the form state and which form field will be updated.
        * @param name - field name
        * @param valueIdentifier - string, either value or checked, or others, based on e.target.value
        */
        const updateFormState: any = (
            name: string,
            paymentPlanOptionIndex: number,
            scheduleDateIndex?: number,
            customFieldIndex?: number,
            paymentPlanOptionKeyIndex?: number,
        ) => {
            return (
                e: any
            ) => {
                let correctValue = e && e.target !== undefined ? e.target.value : e;

                const paymentPlanConfigNewList = [...paymentPlanConfigFormState];

                name = name.replace(/[0-9]/g, '');

                if (scheduleDateIndex != null) {
                    updatePaymentPlanScheduleDates(name, correctValue, paymentPlanOptionIndex, scheduleDateIndex, paymentPlanOptionKeyIndex)
                } else if (customFieldIndex !== null && customFieldIndex !== undefined) {
                    updatePaymentPlanCustomFields(name, correctValue, paymentPlanOptionIndex, customFieldIndex, paymentPlanOptionKeyIndex)
                } else {
                    if (name == FORM_FIELDS.CUT_OFF_DATE) {
                        correctValue = correctValue ? correctValue.format(dateFormatDDMMYYYYSlash) : '';
                    }
                    paymentPlanConfigNewList[paymentPlanOptionIndex] = {
                        ...paymentPlanConfigNewList[paymentPlanOptionIndex],
                        [name]: correctValue
                    };

                    updateFormStateObject(paymentPlanConfigNewList);
                }
            };
        };

        const filterFields = (obj: any): Partial<PaymentPlanConfig> => {
            return Object.fromEntries(
                Object.entries(obj).filter(
                    ([_, value]) =>
                        value !== null &&
                        value !== undefined &&
                        value !== '' &&
                        !(Array.isArray(value) && value.length === 0)
                )
            );
        };

        const isEmptyNumber = (value: number | undefined) => {
            if ((typeof value === 'number' && value === 0) || value === null || value === undefined) {
                return true;
            }
            return false;
        };

        /**
         * Function called when the save button is called.
         * Specific to this form only and does not affect the other child tab items
         * that are side by side with this form.
         */
        const handleSave = () => {
            validateFields((err: DynamicObject) => {
                let paymentPlanConfigOptions: PaymentPlanConfig[] = [];
                let paymentPlanValidations: { PaymentPlanOption: string; Validation: string[] | null }[] = [];
                let filteredPaymentPlanConfigs = paymentPlanConfigs.filter(paymentPlan => paymentPlan.IsTemplate != isTemplate); //getting the other config type (template or not template)
                debugger;
                if (!err) {
                    paymentPlanConfigFormState.map((paymentPlanConfig, paymentPlanindex) => {
                        let customFields: { Name: string; Value: string }[] = [];
                        let scheduleDates: string[] = [];
                        let paymentPlanValidation: string[] = [];
                        let paymentPlanKeyIndex: number = paymentPlanConfig.paymentPlanKeyIndex ? paymentPlanConfig.paymentPlanKeyIndex : 0;
                        let sortedScheduleDates: string[] = [];
                        let sortedPaymentPercentage: number[] = [];

                        if ((!paymentPlanConfig.ScheduleDates || isEmpty(paymentPlanConfig.ScheduleDates)) && paymentPlanConfig.InstalmentType != INSTALMENT_TYPE.MONTHLY) {
                            // If ScheduleDates is undefined or empty, check the alternative condition
                            if (isEmpty(paymentPlanScheduleDates[paymentPlanKeyIndex])) paymentPlanValidation.push(getTranslatedText("Scheduled dates are required."));
                        } else if (Array.isArray(paymentPlanConfig.ScheduleDates)) {
                            // If ScheduleDates is defined and an array, iterate over it
                            paymentPlanConfig.ScheduleDates.forEach((scheduleDate, index) => {
                                if (isEmpty(scheduleDate)) {
                                    paymentPlanValidation.push(
                                        getTranslatedText("Schedule date [index] is empty. Scheduled dates are required.").replace("[index]", (index + 1).toString())
                                    );
                                } else {
                                    scheduleDates.push(scheduleDate);
                                }
                            });
                        }

                        if (paymentPlanScheduleDates[paymentPlanKeyIndex]) {
                            paymentPlanScheduleDates[paymentPlanKeyIndex].map((scheduleDate, index) => {
                                if (isEmpty(scheduleDate)) {
                                    paymentPlanValidation.push(getTranslatedText("New schedule date [index] is empty. Scheduled dates are required.").replace("[index]", (index + 1).toString()))
                                } else {
                                    scheduleDates.push(scheduleDate);
                                }
                            })
                        }

                        if (isEmpty(paymentPlanConfig.InstalmentType)) paymentPlanValidation.push(getTranslatedText("Instalment type is required."))
                        if (isEmpty(paymentPlanConfig.Name)) paymentPlanValidation.push(getTranslatedText("Name is required."))
                        if (isEmptyNumber(paymentPlanConfig.Instalments)) paymentPlanValidation.push(getTranslatedText("Number of instalments is required."))
                        if (paymentPlanConfig.InstalmentType == INSTALMENT_TYPE.MONTHLY) {
                            if (isEmpty(paymentPlanConfig.StartTiming)) paymentPlanValidation.push(getTranslatedText("Start timing is required."))
                            if (isEmptyNumber(paymentPlanConfig.FrequencyGap)) paymentPlanValidation.push(getTranslatedText("Frequency gap is required."))
                            if (isEmptyNumber(paymentPlanConfig.SpecificPaymentDate)) paymentPlanValidation.push(getTranslatedText("Month day is required."))
                            if (paymentPlanConfig.StartDelay == null) paymentPlanValidation.push(getTranslatedText("Start delay is required."))
                        }

                        if (paymentPlanConfig.InstalmentType.replace(/\s+/g, '').toLowerCase() == INSTALMENT_TYPE.DATE_RANGE.replace(/\s+/g, '').toLowerCase()) {
                            if (!isEmpty(paymentPlanConfig.SplitInstalmentDateRange) && paymentPlanConfig.SplitInstalmentDateRange) {
                                const numberOfDays = moment(paymentPlanConfig.SplitInstalmentDateRange[1]).diff(moment(paymentPlanConfig.SplitInstalmentDateRange[0]), 'days');
                                if (paymentPlanConfig.Instalments > numberOfDays)
                                    getTranslatedText("There are [noOfInstalments] instalments but we only have [noOfDays] days.")
                                        .replace("[noOfInstalments]", paymentPlanConfig.Instalments.toString())
                                        .replace("[noOfDays]", paymentPlanConfig.InstalmentMax ? paymentPlanConfig.InstalmentMax.toString() : '0')
                            } else {
                                paymentPlanValidation.push(getTranslatedText("Instalment periods are required."))
                            }
                        }

                        const hasDuplicates = new Set(scheduleDates).size !== scheduleDates.length;

                        if (hasDuplicates) paymentPlanValidation.push(getTranslatedText("Duplicate scheduled dates found. Duplicate dates are not allowed."))

                        let minimumInstalment: number = paymentPlanConfig.InstalmentType == INSTALMENT_TYPE.DATE_RANGE ? 2 : 1;

                        if (scheduleDates.length < minimumInstalment && paymentPlanConfig.InstalmentType != INSTALMENT_TYPE.MONTHLY) paymentPlanValidation.push(getTranslatedText("Schedule dates should be more than [min].").replace('[min]', minimumInstalment.toString()))

                        if (addPaymentPercents[paymentPlanKeyIndex] && paymentPlanConfig.PayPercent) {
                            const hasNullValues = paymentPlanConfig.PayPercent.some(value => value === null || value === 0);

                            if (hasNullValues || (paymentPlanConfig.PayPercent.length != paymentPlanConfig.Instalments))
                                paymentPlanValidation.push(getTranslatedText("All payment percentages are required if Allocate instalment percentage is enabled."));

                            const payPercentTotal = paymentPlanConfig.PayPercent.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                            if (payPercentTotal != 100) paymentPlanValidation.push(getTranslatedText("The required total payment percentage has not been met."));

                            if (paymentPlanConfig.InstalmentType == INSTALMENT_TYPE.CUSTOM) {
                                const combined = scheduleDates.map((date, index) => ({ date, number: paymentPlanConfig.PayPercent ? paymentPlanConfig.PayPercent[index] : 0 }));

                                // Sort the combined array by the date
                                combined.sort((a, b) => moment(a.date, 'DD/MM/YYYY').isBefore(moment(b.date, 'DD/MM/YYYY')) ? -1 : 1);

                                // Separate the sorted dates and numbers into separate arrays
                                sortedScheduleDates = combined.map(item => item.date);
                                sortedPaymentPercentage = combined.map(item => item.number);
                            }
                        } else {
                            sortedScheduleDates = scheduleDates.sort((a, b) => moment(a, 'DD/MM/YYYY').isBefore(moment(b, 'DD/MM/YYYY')) ? -1 : 1);
                        }

                        if (paymentPlanConfig.CustomFields) {
                            paymentPlanCustomFieldTabs[paymentPlanKeyIndex].map((customFieldTabs, index) => {
                                if (paymentPlanConfig.CustomFields) {
                                    let customField = paymentPlanConfig.CustomFields[index];
                                    let customFieldTabTitle = customFieldTabs['title'];

                                    if (isEmpty(customField)) {
                                        paymentPlanValidation.push(getTranslatedText("[customFieldTabTitle] is empty. Custom fields are required.").replace("[customFieldTabTitle]", customFieldTabTitle))
                                    } else {
                                        if (isEmpty(customField.Name)) {
                                            paymentPlanValidation.push(getTranslatedText("[customFieldTabTitle] name is required.").replace("[customFieldTabTitle]", customFieldTabTitle))
                                        }

                                        if (isEmpty(customField.Value)) {
                                            paymentPlanValidation.push(getTranslatedText("[customFieldTabTitle] value is required.").replace("[customFieldTabTitle]", customFieldTabTitle))
                                        }

                                        if (customField.Name && customField.Value) {
                                            customFields.push({
                                                Name: customField.Name,
                                                Value: customField.Value
                                            });
                                        }
                                    }
                                }
                            })
                        }

                        if (paymentPlanDynamicCustomFieldTabs[paymentPlanKeyIndex]) {
                            paymentPlanDynamicCustomFieldTabs[paymentPlanKeyIndex].map((paymentPlanDynamicCustomFieldTab, index) => {
                                let customField = paymentPlanCustomFields[paymentPlanKeyIndex][index];
                                let customFieldTabTitle = paymentPlanDynamicCustomFieldTab['title'];

                                if (isEmpty(customField)) {
                                    paymentPlanValidation.push(getTranslatedText("[customFieldTabTitle] is empty. Custom fields are required.").replace("[customFieldTabTitle]", customFieldTabTitle))
                                } else {
                                    if (isEmpty(customField.name)) paymentPlanValidation.push(getTranslatedText("[customFieldTabTitle] name is required.").replace("[customFieldTabTitle]", customFieldTabTitle))


                                    if (isEmpty(customField.value)) paymentPlanValidation.push(getTranslatedText("[customFieldTabTitle] value is required.").replace("[customFieldTabTitle]", customFieldTabTitle))

                                    if (customField.name && customField.value) {
                                        customFields.push({
                                            Name: customField.name,
                                            Value: customField.value
                                        });
                                    }
                                }
                            })
                        }

                        if (customFields) {
                            const uniqueCustomeFields = new Set(customFields.map(obj => JSON.stringify(obj)));
                            if (uniqueCustomeFields.size !== customFields.length) paymentPlanValidation.push(getTranslatedText("Duplicate custom fields found. Duplicate custom fields are not allowed."));
                        }

                        if (!isEmpty(paymentPlanValidation)) {
                            paymentPlanValidations.push({
                                PaymentPlanOption: paymentPlanOptions[paymentPlanindex]['title'],
                                Validation: paymentPlanValidation
                            });

                            paymentPlanOptions[paymentPlanindex].haveErrors = true;
                        } else
                            paymentPlanOptions[paymentPlanindex].haveErrors = false;


                        const payload = {
                            InstalmentType: paymentPlanConfig.InstalmentType,
                            ConfigId: paymentPlanConfig.ConfigId,
                            Name: paymentPlanConfig.Name,
                            Frequency: paymentPlanConfig.Frequency,
                            CutoffDate: paymentPlanConfig.CutoffDate,
                            Instalments: paymentPlanConfig.Instalments,
                            ...(paymentPlanConfig.InstalmentType != INSTALMENT_TYPE.MONTHLY ? { ScheduleDates: isEmpty(sortedScheduleDates) ? scheduleDates : sortedScheduleDates } : {}),
                            CustomFields: customFields,
                            SplitInstalmentDateRange: paymentPlanConfig.SplitInstalmentDateRange,
                            StartTiming: paymentPlanConfig.StartTiming,
                            StartDelay: paymentPlanConfig.StartDelay,
                            FrequencyGap: paymentPlanConfig.FrequencyGap,
                            SpecificPaymentDate: paymentPlanConfig.SpecificPaymentDate,
                            PayPercent: isEmpty(sortedPaymentPercentage) ? paymentPlanConfig.PayPercent : sortedPaymentPercentage,
                            NewVersion: true,
                            IsTemplate: isTemplate
                        }

                        const filteredPayload = filterFields(payload);

                        const paymentPlanConfigPayloadObject: PaymentPlanConfig = {
                            ConfigId: filteredPayload.ConfigId || generateGUID(),
                            Name: filteredPayload.Name || '',
                            InstalmentType: filteredPayload.InstalmentType || '',
                            Instalments: filteredPayload.Instalments || 0,
                            NewVersion: filteredPayload.NewVersion || true,
                            IsTemplate: filteredPayload.IsTemplate || false,
                            ...filteredPayload, // Add other optional fields
                        };

                        paymentPlanConfigOptions.push(paymentPlanConfigPayloadObject)
                    })

                    if (newPaymentPlanConfigFormState) {
                        paymentPlanOptionKeys.map((key, index) => {
                            let newPaymentPlanOptionKey = key - 1;
                            let paymentPlanValidation: string[] = [];
                            let instalmentType = get(newPaymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_TYPE + newPaymentPlanOptionKey);
                            let newCustomFieldKeys = get(newPaymentPlanConfigFormState, TAB_NAME.CUSTOM_FIELD_KEYS + newPaymentPlanOptionKey);
                            let newScheduleDates = get(newPaymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES + newPaymentPlanOptionKey);
                            let newScheduleDatesKeys = get(newPaymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATE_KEYS + newPaymentPlanOptionKey);
                            let name = get(newPaymentPlanConfigFormState, FORM_FIELDS.NAME + newPaymentPlanOptionKey);
                            let numberOfInstalments: number = get(newPaymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + newPaymentPlanOptionKey);
                            let startTiming = get(newPaymentPlanConfigFormState, FORM_FIELDS.START_TIMING + newPaymentPlanOptionKey);
                            let frequencyGap = get(newPaymentPlanConfigFormState, FORM_FIELDS.FREQUENCY_GAP + newPaymentPlanOptionKey)
                            let specificPaymentDay = get(newPaymentPlanConfigFormState, FORM_FIELDS.SPECIFIC_PAYMENT_DAY + newPaymentPlanOptionKey)
                            let startDelay = get(newPaymentPlanConfigFormState, FORM_FIELDS.START_DELAY + newPaymentPlanOptionKey)
                            let addPayPercent = get(newPaymentPlanConfigFormState, FORM_DISPLAY.ADD_PAY_PERCENT + newPaymentPlanOptionKey)
                            let splitInstalmentDateRange = get(newPaymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + newPaymentPlanOptionKey)
                            let cutOffDate = get(newPaymentPlanConfigFormState, FORM_FIELDS.CUT_OFF_DATE + newPaymentPlanOptionKey)
                            let paymentPlanPercentages: number[] = [];
                            let newPaymentPlanConfigCustomFields: { Name: string; Value: string }[] = [];
                            let scheduleDates: string[] = [];
                            let sortedScheduleDates: string[] = [];
                            let sortedPaymentPercentage: number[] = [];

                            if (isEmpty(name)) paymentPlanValidation.push(getTranslatedText("Name is required."))
                            if (isEmpty(instalmentType)) {
                                paymentPlanValidation.push(getTranslatedText("Instalment type is required."))
                            } else {
                                if (isEmptyNumber(numberOfInstalments)) paymentPlanValidation.push(getTranslatedText("Number of instalments is required."))

                                //maximum number of instalment in daterange
                                let instalmentMax = get(newPaymentPlanConfigFormState, FORM_DISPLAY.INSTALMENT_MAX + newPaymentPlanOptionKey);
                                if (instalmentType == INSTALMENT_TYPE.DATE_RANGE && numberOfInstalments > (instalmentMax ? instalmentMax : 0))
                                    getTranslatedText("There are [noOfInstalments] instalments but we only have [noOfDays] days.")
                                        .replace("[noOfInstalments]", numberOfInstalments.toString())
                                        .replace("[noOfDays]", instalmentMax)

                                if (instalmentType == INSTALMENT_TYPE.MONTHLY) {
                                    if (isEmpty(startTiming)) paymentPlanValidation.push(getTranslatedText("Start timing is required."))
                                    if (isEmptyNumber(frequencyGap)) paymentPlanValidation.push(getTranslatedText("Frequency gap is required."))
                                    if (isEmptyNumber(specificPaymentDay)) paymentPlanValidation.push(getTranslatedText("Month day is required."))
                                    if (startDelay == null) paymentPlanValidation.push(getTranslatedText("Start delay is required."))
                                } else if (instalmentType.replace(/\s+/g, '').toLowerCase() == INSTALMENT_TYPE.DATE_RANGE.replace(/\s+/g, '').toLowerCase()) {
                                    if (isEmpty(splitInstalmentDateRange)) paymentPlanValidation.push(getTranslatedText("Instalment periods are required."))
                                }

                                if (newScheduleDates) {
                                    newScheduleDates.map((newScheduleDate: any, scheduleDateIndex: number) => {
                                        if (isEmpty(newScheduleDate)) {
                                            paymentPlanValidation.push(getTranslatedText("Schedule date [index] is empty. Scheduled dates are required.").replace("[index]", (scheduleDateIndex + 1).toString()))
                                        } else {
                                            scheduleDates.push(newScheduleDate);
                                        }
                                    })
                                }

                                if (newScheduleDatesKeys) {
                                    newScheduleDatesKeys.map((value: any) => {
                                        let scheduleDate = get(newPaymentPlanConfigFormState, FORM_FIELDS.SCHEDULED_DATE + newPaymentPlanOptionKey + (value));

                                        if (scheduleDate === undefined || scheduleDate == null) {
                                            paymentPlanValidation.push(getTranslatedText("Schedule date [index] is empty. Scheduled dates are required.").replace("[index]", (value).toString()))
                                        } else {
                                            scheduleDates.push(scheduleDate.format(dateFormatDDMMYYYYSlash));
                                        }
                                    })
                                }

                                const hasDuplicates = new Set(scheduleDates).size !== scheduleDates.length;
                                if (hasDuplicates) paymentPlanValidation.push(getTranslatedText("Duplicate scheduled dates found. Duplicate dates are not allowed."))

                                if (addPayPercent) {
                                    let paymentPlanPercentageEmpty: number[] = [];
                                    for (var i = 0; i < numberOfInstalments; i++) {
                                        let payPercent: number = get(newPaymentPlanConfigFormState, FORM_FIELDS.PAY_PERCENT + newPaymentPlanOptionKey + (i))

                                        if (payPercent === undefined) {
                                            paymentPlanPercentageEmpty.push(i);
                                        } else {
                                            paymentPlanPercentages.push(payPercent)
                                        }
                                    }

                                    if (paymentPlanPercentageEmpty.length > 0) {
                                        paymentPlanValidation.push(getTranslatedText("All payment percentages are required if Allocate instalment percentage is enabled."));
                                    }

                                    const payPercentTotal = paymentPlanPercentages.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                    if (payPercentTotal != 100) {
                                        paymentPlanValidation.push(getTranslatedText("The required total payment percentage has not been met."));
                                    }

                                    if (paymentPlanPercentages && instalmentType == INSTALMENT_TYPE.CUSTOM) {
                                        const combined = scheduleDates.map((date, index) => ({ date, number: paymentPlanPercentages[index] }));

                                        // Sort the combined array by the date
                                        combined.sort((a, b) => moment(a.date, 'DD/MM/YYYY').isBefore(moment(b.date, 'DD/MM/YYYY')) ? -1 : 1);

                                        // Separate the sorted dates and numbers into separate arrays
                                        sortedScheduleDates = combined.map(item => item.date);
                                        sortedPaymentPercentage = combined.map(item => item.number);
                                    }
                                } else {
                                    sortedScheduleDates = scheduleDates.sort((a, b) => moment(a, 'DD/MM/YYYY').isBefore(moment(b, 'DD/MM/YYYY')) ? -1 : 1);
                                }

                                if (newCustomFieldKeys) {
                                    newCustomFieldKeys.map((newCustomFieldKey: any) => {
                                        let customFieldName = get(newPaymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + newPaymentPlanOptionKey + (newCustomFieldKey));
                                        let customFieldValue = get(newPaymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + newPaymentPlanOptionKey + (newCustomFieldKey));

                                        if (customFieldName === undefined || isEmpty(customFieldName)) {
                                            paymentPlanValidation.push(getTranslatedText("Custom field [index] name is required.").replace("[index]", (newCustomFieldKey + 1)))
                                        }

                                        if (customFieldValue === undefined || isEmpty(customFieldValue)) {
                                            paymentPlanValidation.push(getTranslatedText("Custom field [index] value is required.").replace("[index]", (newCustomFieldKey + 1)))
                                        }

                                        if (customFieldName && customFieldValue) {
                                            newPaymentPlanConfigCustomFields.push({
                                                Name: get(newPaymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + newPaymentPlanOptionKey + newCustomFieldKey),
                                                Value: get(newPaymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + newPaymentPlanOptionKey + newCustomFieldKey)
                                            });
                                        }
                                    })
                                }

                                if (newPaymentPlanConfigCustomFields) {
                                    const uniqueCustomFields = new Set(newPaymentPlanConfigCustomFields.map(obj => JSON.stringify(obj)));

                                    if (uniqueCustomFields.size !== newPaymentPlanConfigCustomFields.length) paymentPlanValidation.push(getTranslatedText("Duplicate custom fields found. Duplicate custom fields are not allowed."));
                                }

                                const newPaymentPlanConfig = {
                                    ConfigId: generateGUID(),
                                    Name: name,
                                    InstalmentType: instalmentType,
                                    Frequency: get(newPaymentPlanConfigFormState, FORM_FIELDS.FREQUENCY + index),
                                    FrequencyGap: frequencyGap,
                                    Instalments: numberOfInstalments,
                                    CustomFields: newPaymentPlanConfigCustomFields,
                                    ...(instalmentType != INSTALMENT_TYPE.MONTHLY ? { ScheduleDates: isEmpty(sortedScheduleDates) ? scheduleDates : sortedScheduleDates } : {}),
                                    SplitInstalmentDateRange: splitInstalmentDateRange,
                                    CutoffDate: cutOffDate ? cutOffDate.format(dateFormatDDMMYYYYSlash) : null,
                                    StartTiming: startTiming,
                                    StartDelay: startDelay,
                                    SpecificPaymentDate: specificPaymentDay,
                                    NewVersion: true,
                                    IsTemplate: isTemplate,
                                    PayPercent: isEmpty(sortedPaymentPercentage) ? paymentPlanPercentages : sortedPaymentPercentage
                                }

                                const filteredPayload = filterFields(newPaymentPlanConfig);

                                const paymentPlanConfigPayloadObject: PaymentPlanConfig = {
                                    ConfigId: filteredPayload.ConfigId || generateGUID(),
                                    Name: filteredPayload.Name || '',
                                    InstalmentType: filteredPayload.InstalmentType || '',
                                    Instalments: filteredPayload.Instalments || 0,
                                    NewVersion: filteredPayload.NewVersion || true,
                                    IsTemplate: filteredPayload.IsTemplate || false,
                                    ...filteredPayload, // Add other optional fields
                                };

                                paymentPlanConfigOptions.push(paymentPlanConfigPayloadObject)
                            }

                            if (dynamicPaymentPlanOptions[index]) {
                                if (!isEmpty(paymentPlanValidation)) {
                                    paymentPlanValidations.push({
                                        PaymentPlanOption: dynamicPaymentPlanOptions[index]['title'],
                                        Validation: paymentPlanValidation
                                    });
                                    dynamicPaymentPlanOptions[index].haveErrors = true;
                                } else
                                    dynamicPaymentPlanOptions[index].haveErrors = false;
                            }
                        });
                    }

                    if (filteredPaymentPlanConfigs) {
                        filteredPaymentPlanConfigs.map((filteredPaymentPlanConfig, index) => {
                            paymentPlanConfigOptions.push(filteredPaymentPlanConfig)
                        });
                    }

                    if (isEmpty(paymentPlanValidations)) {
                        const paymentPlanConfigPayload: {
                            PaymentPlanConfigurations: {},
                        } = {
                            PaymentPlanConfigurations: paymentPlanConfigOptions,
                        }

                        submitPaymentPlanConfig(paymentPlanConfigPayload);
                    } else {
                        showValidationModalError(paymentPlanValidations, paymentPlanOptions, dynamicPaymentPlanOptions);
                    }
                }
            })
        };

        const submitPaymentPlanConfig = (paymentPlanConfigPayload: any) => {
            dispatch(
                createPaymentPlanConfigRequestAction(
                    paymentPlanConfigPayload,
                    handleModalSaveResponse
                )
            );
        }

        /**
         * Common function for showing modal error.
         * @param modalObject
         */
        const showValidationModalError = (paymentPlanValidations: { PaymentPlanOption: string; Validation: string[] | null }[], tabOptions: any[], dynamicTabOptions: any[]) => {
            let errorMessageContent:
                | string
                | JSX.Element[] = getTranslatedText("Validation Error") + "!";
            if (!isEmpty(paymentPlanValidations)) {
                errorMessageContent = map(paymentPlanValidations, (validation: any, index: number) => (
                    <div key={index}>
                        <span>{validation['PaymentPlanOption']}</span>
                        <ul key={index}>
                            {validation['Validation'].map((validationMessage: string, msgIndex: number) => (
                                <li key={msgIndex}>
                                    <div>{validationMessage}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))
            }

            Modal.error({
                title: getTranslatedText("Validation Error") + "!",
                content: errorMessageContent,
                okText: getTranslatedText("OK"),
                onOk: () => {
                    let firstTab = tabOptions.filter((pane) => pane.haveErrors === true)[0];
                    if (firstTab) {
                        setPaymentPlanOptions(tabOptions);
                        setSelectedPaymentPlanOptionTab(firstTab.key);
                    }

                    let dynamicTab = dynamicTabOptions.filter((pane) => pane.haveErrors === true)[0];
                    if (dynamicTab) {
                        setDynamicPaymentPlanOptions(dynamicTabOptions);
                        setSelectedPaymentPlanOptionTab(dynamicTab.key);
                    }

                    if (firstTab || dynamicTab)
                        setValidateTab(!validateTab);
                }
            });
        };

        const addPaymentPlanOption = () => {
            const tabOption = {
                key: `payment-plan-dynamic-${paymentPlanOptionTabId}`,
                title: getTranslatedText("New Payment Plan [index]").replace("[index]", paymentPlanOptionTabId.toString()),
                component: PaymentPlanOptionDetailsDynamicTabContent,
                haveErrors: false
            }

            setDynamicPaymentPlanOptions([...dynamicPaymentPlanOptions, tabOption]);

            setPaymentPlanOptionKeys([...paymentPlanOptionKeys, paymentPlanOptionTabId]);
            setPaymentPlanOptionTabId(paymentPlanOptionTabId + 1);
            setSelectedPaymentPlanOptionTab("payment-plan-dynamic-" + paymentPlanOptionTabId);
            setFormHasChanges();
        };

        const addPaymentPlanScheduleDate = (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number) => {
            resetFields();
            const newScheduleDates = [...(paymentPlanScheduleDates[paymentPlanOptionKeyIndex] || []), ""];

            setPaymentPlanScheduleDates(prevScheduleDate => ({
                ...prevScheduleDate,
                [paymentPlanOptionKeyIndex]: [...(prevScheduleDate[paymentPlanOptionKeyIndex] || []), ""] // Update the value for the specific schedule date
            }))

            setPaymentPlanScheduleDateKeys(prevScheduleDates => ({
                ...prevScheduleDates,
                [paymentPlanOptionKeyIndex]: [...(prevScheduleDates[paymentPlanOptionKeyIndex] || []), paymentPlanOptionKeyIndex + FORM_FIELDS.SCHEDULED_DATE + "dynamic" + paymentPlanScheduleDateIds[paymentPlanOptionKeyIndex]], // Push new number to the array or create a new array
            }));

            setPaymentPlanScheduleDateIds(prevScheduleDateId => ({
                ...prevScheduleDateId,
                [paymentPlanOptionKeyIndex]: prevScheduleDateId[paymentPlanOptionKeyIndex] + 1 // Update the value for the specific key
            }))

            updateNumberOfInstalment(paymentPlanOptionIndex, newScheduleDates.length);
            updateAddPayPercentageFields(paymentPlanOptionIndex, newScheduleDates);

            setFormHasChanges();
        };

        const addPaymentPlanCustomField = (paymentPlanOptionKeyIndex: number) => {
            const customFieldTabOption = {
                key: "payment-plan-" + paymentPlanOptionKeyIndex + "-dynamic-custom-field-" + paymentPlanCustomFieldIds[paymentPlanOptionKeyIndex],
                title: getTranslatedText("New custom field [index]").replace("[index]", paymentPlanCustomFieldIds[paymentPlanOptionKeyIndex].toString()),
                customField: { name: '', value: '' },
                component: PaymentPlanDynamicCustomFieldTabContent
            }

            setPaymentPlanDynamicCustomFieldTabs(prevCustomFields => ({
                ...prevCustomFields,
                [paymentPlanOptionKeyIndex]: [...(prevCustomFields[paymentPlanOptionKeyIndex] || []), customFieldTabOption] // Update the value for the specific schedule date
            }))

            setPaymentPlanCustomFields(prevCustomFields => ({
                ...prevCustomFields,
                [paymentPlanOptionKeyIndex]: [...(prevCustomFields[paymentPlanOptionKeyIndex] || []), { name: '', value: '' }] // Update the value for the specific schedule date
            }))


            setSelectedCustomFieldTab("payment-plan-" + paymentPlanOptionKeyIndex + "-dynamic-custom-field-" + paymentPlanCustomFieldIds[paymentPlanOptionKeyIndex]);

            setPaymentPlanCustomFieldIds(prevCustomFieldId => ({
                ...prevCustomFieldId, // Keep existing key-value pairs
                [paymentPlanOptionKeyIndex]: prevCustomFieldId[paymentPlanOptionKeyIndex] + 1 // Update the value for the specific key
            }))

            setFormHasChanges();
        };

        /**
        * Common function for updating the form state object
        * created to minimize repitition when updating form state.
        * @param formStateObject
        */
        const updateNumberOfInstalment = (paymentPlanOptionIndex: number, numberOfDynamicScheduleDates: number) => {
            const paymentPlanConfigNewList = [...paymentPlanConfigFormState];
            const scheduleDates = paymentPlanConfigFormState[paymentPlanOptionIndex].ScheduleDates;

            paymentPlanConfigNewList[paymentPlanOptionIndex] = {
                ...paymentPlanConfigNewList[paymentPlanOptionIndex],
                [FORM_FIELDS.INSTALMENTS]: numberOfDynamicScheduleDates + (scheduleDates ? scheduleDates.length : 0)
            };

            if (form.getFieldError(FORM_FIELDS.INSTALMENTS + paymentPlanOptionIndex)) {
                form.setFields({
                    [FORM_FIELDS.INSTALMENTS + paymentPlanOptionIndex]: {
                        errors: [], // Clear the validation errors for this field
                    },
                });
            }

            setPaymentPlanConfigFormState(paymentPlanConfigNewList)
        };

        const updatePayPercentageFields = (paymentPlanOptionIndex: number, scheduleDates: string[], scheduleDateIndex: number) => {
            setPaymentPlanConfigFormState((prevState) => {
                const newState = [...prevState];
                const paymentPlanOption = newState[paymentPlanOptionIndex];
                let scheduleDatesDisplay: string[] = [];

                const updateFields: Record<string, any> = {};


                if (paymentPlanOption.ScheduleDates) {
                    paymentPlanOption.ScheduleDates.map((scheduleDate) => {
                        scheduleDatesDisplay.push(scheduleDate);
                    })
                }

                if (!isEmpty(scheduleDates)) {
                    scheduleDateIndex = (scheduleDateIndex + 1) + (scheduleDatesDisplay.length - 1);
                    setFieldsValue({
                        [paymentPlanOptionIndex + FORM_FIELDS.PAY_PERCENT + scheduleDateIndex]: null,
                    });
                    scheduleDates.map((scheduleDate) => {
                        scheduleDatesDisplay.push(scheduleDate);
                    })
                } else {
                    if (paymentPlanScheduleDates[paymentPlanOptionIndex]) {
                        paymentPlanScheduleDates[paymentPlanOptionIndex].map((scheduleDate) => {
                            scheduleDatesDisplay.push(scheduleDate);
                        })
                    }

                    setFieldsValue({
                        [paymentPlanOptionIndex + FORM_FIELDS.PAY_PERCENT + scheduleDateIndex]: null,
                    });

                }

                if (paymentPlanOption.PayPercent) {
                    paymentPlanOption.PayPercent[scheduleDateIndex] = 0;
                }


                updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = scheduleDatesDisplay;


                if (addPaymentPercents[paymentPlanOptionIndex]) {

                    updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = true;
                    updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
                    updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
                }

                newState[paymentPlanOptionIndex] = {
                    ...paymentPlanOption,
                    ...updateFields,
                };

                return newState;
            });
            setFormHasChanges();
        }

        const updateAddPayPercentageFields = (paymentPlanOptionIndex: number, scheduleDates: string[]) => {
            setPaymentPlanConfigFormState((prevState) => {
                const newState = [...prevState];
                const paymentPlanOption = newState[paymentPlanOptionIndex];
                let scheduleDatesDisplay: string[] = [];

                const updateFields: Record<string, any> = {};

                if (paymentPlanOption.ScheduleDates) {
                    paymentPlanOption.ScheduleDates.map((scheduleDate) => {
                        scheduleDatesDisplay.push(scheduleDate);
                    })
                }

                if (!isEmpty(scheduleDates)) {
                    scheduleDates.map((scheduleDate) => {
                        scheduleDatesDisplay.push(scheduleDate);
                    })
                }

                updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = scheduleDatesDisplay;

                if (addPaymentPercents[paymentPlanOptionIndex]) {
                    updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = true;
                    updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
                    updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
                }

                newState[paymentPlanOptionIndex] = {
                    ...paymentPlanOption,
                    ...updateFields,
                };

                return newState;
            });
            setFormHasChanges();
        }

        const updateRemovePayPercentageFields = (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number, scheduleDates: string[], scheduleDateIndex: number) => {
            setPaymentPlanConfigFormState((prevState) => {
                const newState = [...prevState];
                const paymentPlanOption = newState[paymentPlanOptionIndex];
                const updateFields: Record<string, any> = {};

                let scheduleDatesDisplay: string[] = [];
                let payPercentageScheduleDatesDisplay: string[] = [];
                let instalments = 0;
                let payPercentages: (number)[] = [];
                let payPercentTotal: number | null = 0;

                if (paymentPlanOption.ScheduleDates) {
                    paymentPlanOption.ScheduleDates.map((scheduleDate) => {
                        scheduleDatesDisplay.push(scheduleDate);
                    })
                }

                if (!isEmpty(scheduleDates)) {
                    scheduleDateIndex = (scheduleDateIndex + 1) + (scheduleDatesDisplay.length - 1);

                    scheduleDates.map((scheduleDate) => {

                        scheduleDatesDisplay.push(scheduleDate);
                    })
                }

                instalments = scheduleDatesDisplay.length;

                if (paymentPlanOption.PayPercent) {
                    paymentPlanOption.PayPercent.map((payPercent, index) => {
                        if (index != scheduleDateIndex) {
                            payPercentages.push(payPercent);
                        }
                    })
                    scheduleDatesDisplay.map((scheduleDate, index) => {
                        if (index != scheduleDateIndex) {
                            scheduleDatesDisplay.push(scheduleDate);
                        }
                    })

                    if (!isEmpty(payPercentages)) {
                        payPercentTotal = payPercentages.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = getTranslatedText('Total is ') + payPercentTotal + '%';
                    }

                    updateFields[FORM_FIELDS.PAY_PERCENT] = payPercentages;
                }

                if (addPaymentPercents[paymentPlanOptionKeyIndex]) {
                    updateFields[FORM_FIELDS.PAY_PERCENT] = Array.from({ length: instalments }, () => null);
                }

                updateFields[FORM_FIELDS.INSTALMENTS] = instalments;
                updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = scheduleDatesDisplay;

                updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = true;
                updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
                updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
                payPercentages.map((percent, index) => {
                    payPercentageScheduleDatesDisplay.push(`${scheduleDatesDisplay[index]} - ${percent}%`);
                    setFieldsValue({
                        [paymentPlanOptionIndex + FORM_FIELDS.PAY_PERCENT + index]: null,
                    });
                })

                if (scheduleDatesDisplay && payPercentageScheduleDatesDisplay.length > 0)
                    updateFields[FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY] = payPercentageScheduleDatesDisplay;

                newState[paymentPlanOptionIndex] = {
                    ...paymentPlanOption,
                    ...updateFields,
                };

                return newState;
            });
            setFormHasChanges();
        }

        const removePaymentPlanOptions = (targetKey: any, isDynamic: boolean) => {
            const paymentPlanOptionIndex = parseInt(targetKey.toString().split("-").pop() || "", 10) - 1;
            let lastIndex = 0;
            let newActiveKey = selectedPaymentPlanOptionTab;

            if (isDynamic) {
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_TYPE + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_DISPLAY.SCHEDULE_DATES_DISPLAY + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.SCHEDULE_DATES + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.NUMBER_OF_INSTALMENT + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_NAME + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.CUSTOM_FIELD_VALUE + paymentPlanOptionIndex);

                unset(newPaymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_GAP + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_PERCENTAGE + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.DEPOSIT_AMOUNT + paymentPlanOptionIndex);

                unset(newPaymentPlanConfigFormState, FORM_FIELDS.NAME + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.START_DELAY + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.START_TIMING + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.FREQUENCY_GAP + paymentPlanOptionIndex);
                unset(newPaymentPlanConfigFormState, FORM_FIELDS.CUT_OFF_DATE + paymentPlanOptionIndex);

                unset(newPaymentPlanConfigFormState, FORM_FIELDS.INSTALMENT_AMOUNT + paymentPlanOptionIndex);

                const filteredPanes = dynamicPaymentPlanOptions.filter((pane, i) => {
                    if (pane.key === targetKey) {
                        lastIndex = i - 1;
                    }
                    return pane.key !== targetKey;
                });

                if (filteredPanes.length) {
                    if (lastIndex >= 0) {
                        newActiveKey = filteredPanes[lastIndex].key;
                    } else {
                        newActiveKey = filteredPanes[0].key;
                    }
                } else {
                    let lastIndexOfInitialPaymentPlanOption = paymentPlanOptions.length - 1;
                    if (paymentPlanOptions[lastIndexOfInitialPaymentPlanOption]) {
                        newActiveKey = paymentPlanOptions[lastIndexOfInitialPaymentPlanOption].key;
                    }
                }

                setPaymentPlanOptionKeys(prevState => {
                    // Use filter directly on the array
                    const newUpdatedArray = prevState.filter((value, index) => index !== paymentPlanOptionIndex);

                    return newUpdatedArray;
                });
                setDynamicPaymentPlanOptions(filteredPanes);
                setSelectedPaymentPlanOptionTab(newActiveKey);
            } else {
                const tabIndex = paymentPlanOptions.findIndex((pane) => pane.key === targetKey);

                const filteredPanes = paymentPlanOptions.filter((pane, i) => {
                    if (pane.key === targetKey) {
                        lastIndex = i - 1;
                    }
                    return pane.key !== targetKey;
                });
                const newPaymentPlanConfigState = paymentPlanConfigFormState.filter((_, index) => index !== tabIndex);

                if (selectedPaymentPlanOptionTab === targetKey) {
                    if (lastIndex >= 0) {
                        newActiveKey = filteredPanes[lastIndex].key;
                    } else {
                        if (!isEmpty(filteredPanes)) {
                            newActiveKey = filteredPanes[0].key;
                        }
                    }
                }

                let newActiveKeyIndex = parseInt(newActiveKey.toString().split("-").pop() || "", 10);
                if (!isEmpty(paymentPlanCustomFieldTabs[newActiveKeyIndex])) {
                    setSelectedCustomFieldTab(paymentPlanCustomFieldTabs[newActiveKeyIndex][0].key);
                } else if (!isEmpty(paymentPlanDynamicCustomFieldTabs[newActiveKeyIndex])) {
                    setSelectedCustomFieldTab(paymentPlanDynamicCustomFieldTabs[newActiveKeyIndex][0].key);
                }

                setPaymentPlanScheduleDates((prevState) => {
                    const { [paymentPlanOptionIndex]: _, ...remainingState } = prevState; // Use destructuring to remove the key

                    return remainingState;
                });

                setPaymentPlanConfigFormState(newPaymentPlanConfigState);
                setPaymentPlanOptions(filteredPanes);
                setSelectedPaymentPlanOptionTab(newActiveKey);
                setFormHasChanges();
            }
        };

        const removeCustomFieldTab = (targetKey: any, paymentPlanOptionIndex: number, isDynamic: boolean, paymentPlanOptionKeyIndex: number) => {
            let newActiveKey = selectedCustomFieldTab;
            let lastIndex = 0;

            if (isDynamic) {
                const tabIndex = paymentPlanDynamicCustomFieldTabs[paymentPlanOptionKeyIndex].findIndex((pane) => pane.key === targetKey);

                setPaymentPlanCustomFields(prevState => {
                    const newState = { ...prevState };
                    if (newState[paymentPlanOptionKeyIndex]) {
                        const updatedArray = [...newState[paymentPlanOptionKeyIndex]];

                        const newUpdatedArray = updatedArray.filter((_, index) => index !== tabIndex);

                        newState[paymentPlanOptionKeyIndex] = newUpdatedArray;

                        return { ...newState };
                    }

                    return prevState;
                });

                const filteredPanes = paymentPlanDynamicCustomFieldTabs[paymentPlanOptionKeyIndex].filter((pane, i) => {
                    if (pane.key === targetKey) {
                        lastIndex = i - 1;
                    }
                    return pane.key !== targetKey;
                });

                if (filteredPanes.length && selectedCustomFieldTab === targetKey) {
                    if (lastIndex >= 0) {
                        newActiveKey = filteredPanes[lastIndex].key;
                    } else {
                        newActiveKey = filteredPanes[0].key;
                    }
                } else {
                    if (!isEmpty(paymentPlanCustomFieldTabs[paymentPlanOptionKeyIndex])) {
                        let lastIndexOfInitialCustomField = paymentPlanCustomFieldTabs[paymentPlanOptionKeyIndex].length - 1;
                        newActiveKey = paymentPlanCustomFieldTabs[paymentPlanOptionKeyIndex][lastIndexOfInitialCustomField].key;
                    }
                }

                setPaymentPlanDynamicCustomFieldTabs(prevState => {
                    const newState = { ...prevState };

                    // Update the newState with the cloned object
                    newState[paymentPlanOptionKeyIndex] = filteredPanes;

                    return newState;
                });
            } else {
                const tabIndex = paymentPlanCustomFieldTabs[paymentPlanOptionKeyIndex].findIndex((pane) => pane.key === targetKey);

                setPaymentPlanConfigFormState(prevState => {
                    const newState = [...prevState];

                    const paymentPlanConfigClone = {
                        ...newState[paymentPlanOptionIndex],
                    };

                    if (!isEmpty(paymentPlanConfigClone.CustomFields) && paymentPlanConfigClone.CustomFields != null) {
                        const updatedCustomFields = paymentPlanConfigClone.CustomFields.filter((_, index) => index !== tabIndex);
                        paymentPlanConfigClone.CustomFields = updatedCustomFields;
                    }

                    // Update the newState with the cloned object
                    newState[paymentPlanOptionIndex] = paymentPlanConfigClone;

                    return newState;
                });

                const filteredPanes = paymentPlanCustomFieldTabs[paymentPlanOptionKeyIndex].filter((pane, i) => {
                    if (pane.key === targetKey) {
                        lastIndex = i - 1;
                    }
                    return pane.key !== targetKey;
                });

                if (filteredPanes.length && selectedCustomFieldTab === targetKey) {
                    if (lastIndex >= 0) {
                        newActiveKey = filteredPanes[lastIndex].key;
                    } else {
                        newActiveKey = filteredPanes[0].key;
                    }
                } else {
                    if (!isEmpty(paymentPlanDynamicCustomFieldTabs[paymentPlanOptionKeyIndex])) {
                        newActiveKey = paymentPlanDynamicCustomFieldTabs[paymentPlanOptionKeyIndex][0].key;
                    }
                }

                setPaymentPlanCustomFieldTabs(prevState => {
                    const newState = { ...prevState };

                    // Update the newState with the cloned object
                    newState[paymentPlanOptionKeyIndex] = filteredPanes;

                    return newState;
                });
            }

            setSelectedCustomFieldTab(newActiveKey);
            setFormHasChanges()
        };

        const removeInitialScheduleDate = (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number, scheduleDateIndex: number) => {
            setPaymentPlanConfigFormState(prevState => {
                const newState = [...prevState];

                const paymentPlanConfigClone = {
                    ...newState[paymentPlanOptionIndex],
                };

                const scheduleDates = [...(paymentPlanConfigClone.ScheduleDates || [])];
                const updatedScheduleDates = scheduleDates.filter((_, index) => index !== scheduleDateIndex);

                paymentPlanConfigClone.ScheduleDates = updatedScheduleDates;
                paymentPlanConfigClone.Instalments = updatedScheduleDates.length + (!isEmpty(paymentPlanScheduleDates) ? paymentPlanScheduleDates[paymentPlanOptionKeyIndex].length : 0);

                // Update the newState with the cloned object
                newState[paymentPlanOptionIndex] = paymentPlanConfigClone;

                return newState;
            });

            updateRemovePayPercentageFields(paymentPlanOptionIndex, paymentPlanOptionKeyIndex, [], scheduleDateIndex);

            setFormHasChanges();
        };

        const removeDynamicScheduleDate = (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number, scheduleDateKey: string, scheduleDateIndex: number) => {
            setPaymentPlanScheduleDateKeys(prevKeys => {
                const newKeys = { ...prevKeys };
                newKeys[paymentPlanOptionKeyIndex] = newKeys[paymentPlanOptionKeyIndex].filter((key, index) => key !== scheduleDateKey);
                return newKeys;
            });

            setPaymentPlanScheduleDates(prevState => {
                const newState = { ...prevState };
                if (newState[paymentPlanOptionKeyIndex]) {
                    const updatedArray = [...newState[paymentPlanOptionKeyIndex]];

                    const newUpdatedArray = updatedArray.filter((_, index) => index !== scheduleDateIndex);

                    newState[paymentPlanOptionKeyIndex] = newUpdatedArray;

                    updateNumberOfInstalment(paymentPlanOptionIndex, newUpdatedArray.length)
                    updateRemovePayPercentageFields(paymentPlanOptionIndex, paymentPlanOptionKeyIndex, newUpdatedArray, scheduleDateIndex)

                    return { ...newState };
                }
                return prevState;
            });

            resetPaymentPlanScheduleDatesField(paymentPlanOptionKeyIndex, scheduleDateIndex)
            setFormHasChanges();
        };

        const resetPaymentPlanScheduleDatesField = (paymentPlanOptionIndex: number, scheduleDateIndex: any) => {
            resetFields([FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + "Dynamic" + FORM_FIELDS.SCHEDULED_DATE + scheduleDateIndex, FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + "Dynamic" + FORM_FIELDS.SCHEDULED_DATE + (scheduleDateIndex + 1)]);
        }

        const handlePaymentPlanOptionEditTab = (targetKey: string | React.MouseEvent<Element, MouseEvent>, action: "add" | "remove") => {
            if (action === 'add') {
                addPaymentPlanOption();  // Call the add function when action is 'add'
            } else if (action === 'remove') {
                removePaymentPlanOptions(targetKey, targetKey.toString().includes("dynamic"))
            }
        };

        const handleCustomFieldEditTab = (targetKey: string | React.MouseEvent<Element, MouseEvent>, action: "add" | "remove", paymentPlanOptionindex: number, paymentPlanOptionKeyIndex: number) => {
            if (action === 'add') {
                addPaymentPlanCustomField(paymentPlanOptionKeyIndex);  // Call the add function when action is 'add'
            } else if (action === 'remove') {
                removeCustomFieldTab(targetKey, paymentPlanOptionindex, targetKey.toString().includes("dynamic"), paymentPlanOptionKeyIndex)
            }
        };

        // Function to format a single date string
        const formatDateString = (dateString: string, year: number = new Date().getFullYear()): moment.Moment => {
            const [day, month] = dateString.split('/');

            return moment(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`);
        };

        /**
         * Function that populates dropdown section.
         * @param options - list of options
         * @param hasDelete - boolean indicator if the dropdown option has delete functionality
         */
        const populateSelectOptions = () => {
            const optionComponents = map(selectedUserCompany.CustomFieldsConfigure, (option: any) => {
                let label, value;
                if (isObject(option)) {
                    label = get(option, 'FieldName');
                    value = get(option, 'FieldName');
                } else {
                    label = option;
                    value = option;
                }

                return (
                    <Option key={value} value={value} label={label}>
                        {label}
                    </Option>
                );
            });

            return optionComponents;
        };

        const populateAddedScheduleDates = (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number, scheduleDatesWarningDynamic: { ScheduleIndex: number, warning: string }[]) => {

            const formItems =
                <>
                    <PaymentPlanScheduleDateTabContent
                        form={form}
                        updateFormState={updateFormState}
                        paymentPlanOptionIndex={paymentPlanOptionIndex}
                        paymentPlanOptionKeyIndex={paymentPlanOptionKeyIndex}
                        paymentPlanScheduleDate={paymentPlanScheduleDates[paymentPlanOptionKeyIndex]}
                        paymentPlanScheduleDateKeys={paymentPlanScheduleDateKeys}
                        removeDynamicScheduleDate={removeDynamicScheduleDate}
                        formatDateString={formatDateString}
                        scheduleDatesWarningDynamic={scheduleDatesWarningDynamic}
                    />
                </>

            return formItems;
        };

        /**
        * Function called when the active payment plan option tab (clicking on tab item - title)
        * @param value
        */
        const handlePaymentPlanOptionTabChange = (value: string) => {
            setSelectedPaymentPlanOptionTab(value);
            const parts = value.toString().split('-');
            const keyIndex = parseInt(parts[parts.length - 1], 10);

            if (!isEmpty(paymentPlanCustomFieldTabs[keyIndex])) {
                setSelectedCustomFieldTab(paymentPlanCustomFieldTabs[keyIndex][0].key);
            } else if (!isEmpty(paymentPlanDynamicCustomFieldTabs[keyIndex])) {
                setSelectedCustomFieldTab(paymentPlanDynamicCustomFieldTabs[keyIndex][0].key);
            }

            setValidateTab(!validateTab);
        };

        /**
        * Function called when the active tab (clicking on tab item - title)
        * @param value
        */
        const handleCustomFieldTabChange = (value: string) => {
            setSelectedCustomFieldTab(value);
        };

        useEffect(() => {
            validateFields();
        }, [validateTab]);

        return (
            <>
                <RouteLeavingGuard
                    when={checkForChanges()}
                    navigate={(path: string) => history.push(path)}
                    shouldBlockNavigation={() => {
                        if (checkForChanges()) {
                            return true;
                        }
                        return false;
                    }}
                />
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <h4 className="mb-9">{getTranslatedText("Update Payment Plan Configuration")}</h4>
                    {
                        !isEmpty(paymentPlanConfigs) ?
                            <Row className="mt-9">
                                <Col
                                    xxl={24}
                                    xl={24}
                                    lg={24}
                                    md={24}
                                    sm={18}
                                    xs={18}
                                >
                                    <Tabs
                                        onChange={(targetKey) => handlePaymentPlanOptionTabChange(targetKey)}
                                        activeKey={selectedPaymentPlanOptionTab}
                                        type="editable-card"
                                        onEdit={(targetKey, action) => handlePaymentPlanOptionEditTab(targetKey, action)}
                                    >
                                        {map(
                                            paymentPlanOptions,
                                            ({
                                                component: TabItemComponent,
                                                ...option
                                            }: typeof paymentPlanOptions[number], index: number) => {
                                                const parts = option.key.toString().split('-');
                                                const keyIndex = parseInt(parts[parts.length - 1], 10);

                                                return (
                                                    <TabPane
                                                        tab={
                                                            <span style={option.haveErrors ? { color: 'red' } : {}}>
                                                                {option.title}
                                                            </span>
                                                        }
                                                        key={option.key}
                                                    >
                                                        {selectedPaymentPlanOptionTab === option.key &&
                                                            (loading ? (
                                                                getLoadingSkeletons(
                                                                    3,
                                                                    loading
                                                                )
                                                            ) : (
                                                                <div
                                                                    className="custom-tab-container"
                                                                    style={{
                                                                        height:
                                                                            window.innerHeight -
                                                                            230,
                                                                    }}
                                                                >
                                                                    <TabItemComponent
                                                                        form={form}
                                                                        containerRef={drawerRef}
                                                                        updateFormState={updateFormState}
                                                                        setPaymentPlanConfigFormState={setPaymentPlanConfigFormState}
                                                                        paymentPlanConfigFormState={paymentPlanConfigFormState}
                                                                        paymentPlanOption={paymentPlanConfigFormState[index]}
                                                                        paymentPlanOptionIndex={index}
                                                                        paymentPlanOptionKeyIndex={keyIndex}
                                                                        selectedUserCompany={selectedUserCompany}
                                                                        setNewPaymentPlanConfigFormState={setNewPaymentPlanConfigFormState}
                                                                        newPaymentPlanConfigFormState={newPaymentPlanConfigFormState}
                                                                        setFormHasChanges={setFormHasChanges}
                                                                        formHasChanges={formHasChanges}
                                                                        formatDateString={formatDateString}
                                                                        paymentPlanScheduleDates={paymentPlanScheduleDates[keyIndex]}
                                                                        populateAddedScheduleDates={populateAddedScheduleDates}
                                                                        addPaymentPlanScheduleDate={addPaymentPlanScheduleDate}
                                                                        removeInitialScheduleDate={removeInitialScheduleDate}
                                                                        addPaymentPlanCustomField={addPaymentPlanCustomField}
                                                                        handleCustomFieldTabChange={handleCustomFieldTabChange}
                                                                        selectedCustomFieldTab={selectedCustomFieldTab}
                                                                        handleCustomFieldEditTab={handleCustomFieldEditTab}
                                                                        populateSelectOptions={populateSelectOptions}
                                                                        paymentPlanCustomFields={paymentPlanCustomFields[keyIndex]}
                                                                        paymentPlanCustomFieldTabs={paymentPlanCustomFieldTabs[keyIndex]}
                                                                        paymentPlanDynamicCustomFieldTabs={paymentPlanDynamicCustomFieldTabs[keyIndex]}
                                                                        setAddPaymentPercents={setAddPaymentPercents}
                                                                        addPaymentPercents={addPaymentPercents}
                                                                    />
                                                                </div>

                                                            ))}
                                                    </TabPane>
                                                )
                                            }
                                        )}

                                        {map(
                                            dynamicPaymentPlanOptions,
                                            ({
                                                component: TabItemComponent,
                                                ...option
                                            }: typeof dynamicPaymentPlanOptions[number], index: number) => {
                                                const parts = option.key.toString().split('-');
                                                const keyIndex = parseInt(parts[parts.length - 1], 10) - 1;
                                                return (
                                                    <TabPane
                                                        tab={
                                                            <span style={option.haveErrors ? { color: 'red' } : {}}>
                                                                {option.title}
                                                            </span>
                                                        }
                                                        key={option.key}
                                                    >
                                                        {selectedPaymentPlanOptionTab === option.key &&
                                                            (loading ? (
                                                                getLoadingSkeletons(
                                                                    3,
                                                                    loading
                                                                )
                                                            ) : (
                                                                <div
                                                                    className="custom-tab-container"
                                                                    style={{
                                                                        height:
                                                                            window.innerHeight -
                                                                            230,
                                                                    }}
                                                                >
                                                                    <TabItemComponent
                                                                        form={form}
                                                                        paymentPlanOptionIndex={keyIndex}
                                                                        selectedUserCompany={selectedUserCompany}
                                                                        setNewPaymentPlanConfigFormState={setNewPaymentPlanConfigFormState}
                                                                        newPaymentPlanConfigFormState={newPaymentPlanConfigFormState}
                                                                        setFormHasChanges={setFormHasChanges}
                                                                        isTemplate={isTemplate}
                                                                    />
                                                                </div>

                                                            ))}
                                                    </TabPane>
                                                )
                                            }
                                        )}
                                    </Tabs>
                                </Col>
                            </Row>
                            :
                            <Empty description={getTranslatedText("No configuration found.")} />
                    }
                </QueueAnim>
            </>

        );
    }
);

export default UpdatePaymentPlanConfigManagementPage;