/**
 * File responsible for all the UI and actions for Settings>Payment Plan Configuration page container - `/app/settings/paymentPlan`.
 */

import { Button, Col, Modal, Row, Tabs, Typography, Form, Spin, Empty } from 'antd';
import { History as IHistory } from 'history';
import { debounce, get, includes, isEmpty, map } from 'lodash';
import QueueAnim from 'rc-queue-anim';
import React, {
    lazy,
    RefObject,
    Suspense,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    confirmModalCancelText,
    confirmModalOkText,
} from '../../../config/config';
import { ApplicationState } from '../../../store';
import { getPaymentPlanConfigRequestAction } from '../../../store/paymentPlans/actions';
import {
    CompaniesState,
    CompanyUserRole,
} from '../../../store/companies/types';
import {
    PaymentPlansState,
    PaymentPlanConfig
} from '../../../store/paymentPlans/types';
import { getRolePermissions } from '../../../store/roles/sagas';
import { updateLessVariables, getTranslatedText } from '../../../utils/commonFunctions';
import { DynamicObject } from '../../../utils/commonInterfaces';
import '../settings.less';
import UpdatePaymentPlanConfigManagementPage from './update/UpdatePaymentPlanConfigManagementPage';
import CreatePaymentPlanConfigPage from './create/CreatePaymentPlanConfigPage';
import { getCustomerUILabel } from '../../../store/customers/sagas';
import { capitalize } from 'lodash';

const ModalWithSpinner = lazy(
    () => import('../../../components/common/ModalWithSpinner')
);

const { TabPane } = Tabs;
const { Title } = Typography;
const { confirm } = Modal;
let fromBranding: boolean = false;
interface IProps {
    location: Location;
    history: typeof IHistory;
    match: {
        path: string;
    };
    form?: any;
}

const paymentPlanConfigRoute = '/app/settings/payment-plan-config';
const PaymentPlanConfigurationPageContainer: React.FC<IProps> = (props: IProps) => {
    const dispatch = useDispatch();
    const customerLabel = useSelector(getCustomerUILabel);
    const rolePermissions = useSelector(getRolePermissions);
    const userRole = useSelector((state: ApplicationState) =>
        get(state.companies.selectedUserCompany, 'Role.Name')
    );
    const [formHasChanges, setFormHasChanges] = useState<boolean>(false);
    const companiesState: CompaniesState = useSelector(
        (state: ApplicationState) => state.companies
    );
    const paymentPlansState: PaymentPlansState = useSelector(
        (state: ApplicationState) => state.paymentPlans
    );
    

    const paymentPlanConfigs: PaymentPlanConfig[] | undefined =
        paymentPlansState.paymentPlanConfigs;

    const selectedUserCompany: CompanyUserRole | undefined =
        companiesState.selectedUserCompany;

    const childRef: RefObject<DynamicObject | null | undefined> = useRef();
    const [currentTab, setCurrentTab] = useState<string>('');
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [routeTabs, setRouteTabs] = useState<any[]>([]);

    const fetchInitialData = () => {
        setRouteTabs([]);
        dispatch(getPaymentPlanConfigRequestAction())
    }

    /**
     * Responsible for setting the selected tab on page load. Based on the route path.
     */
    useEffect(() => {        
        const currentTabPath = props.location.pathname
            .replace(paymentPlanConfigRoute, '')
            .replace('/', '');
        let customerDescription = capitalize(customerLabel) + ' Facing';

        const customerFacingComponent = !isEmpty(paymentPlanConfigs) ? (paymentPlanConfigs[0].IsDefault != true && paymentPlanConfigs.filter(paymentPlan => paymentPlan.IsTemplate == false).length > 0 ? UpdatePaymentPlanConfigManagementPage : CreatePaymentPlanConfigPage) : CreatePaymentPlanConfigPage;
        const internalFacingComponent = !isEmpty(paymentPlanConfigs) ? (paymentPlanConfigs[0].IsDefault != true && paymentPlanConfigs.filter(paymentPlan => paymentPlan.IsTemplate == true).length > 0 ? UpdatePaymentPlanConfigManagementPage : CreatePaymentPlanConfigPage) : CreatePaymentPlanConfigPage;

        setRouteTabs([
            { name: customerDescription, component: customerFacingComponent, isTemplate: false },
            { name: 'Internal Facing', component: internalFacingComponent, isTemplate: true }
        ])

        const selectedTab = currentTabPath !== '' ? currentTabPath : routeTabs.length > 0 ? routeTabs[0].name : customerDescription.toLowerCase();
        
        setCurrentTab(selectedTab.toLowerCase());
    }, [props.location.pathname, paymentPlanConfigs]);

    useEffect(fetchInitialData, [selectedUserCompany]);

    /**
     * Function that is executed when a tab is selected (change - different from the previously selected tab).
     * @param tabKey - key for the tab (required to set the tab identity)
     */
    const onTabChange = (tabKey: string) => {
        const tabPath = tabKey === '' ? routeTabs[0].name : `/${tabKey}`;
        if (tabKey === 'branding') {
            fromBranding = true;
        } else {
            fromBranding = false;
        }

        props.history.push(`${paymentPlanConfigRoute}${tabPath.toLowerCase() }`);
    };

    /**
     * Function called when common `Save` button is clicked
     * calling the save function for the child (General, Contact, Branding).
     */
    const onSaveButtonClick = () => {
        if (childRef.current) childRef.current.save();
    };

    /**
     * Function called when common `Cancel` button is clicked
     * calling the cancel function for the child tab items.
     */
    const onCancelButtonClick = () => {
        confirm({
            className: 'modal-swapped-buttons',
            title: getTranslatedText('Continue?'),
            content: (
                <div>
                    {getTranslatedText("When you click the")} <b>{getTranslatedText(confirmModalOkText)}</b> 
                    {getTranslatedText("button, all the data will be reverted to the last saved values.")}
                </div>
            ),
            onOk() {
                if (childRef.current){
                    if(typeof childRef.current.cancel === 'function'){
                        childRef.current.cancel();
                    } else {
                        fetchInitialData();
                    }
                } 
            },
            onCancel() { },
            okText: getTranslatedText(confirmModalOkText),
            cancelText: getTranslatedText(confirmModalCancelText),
        });
    };

    /**
     * Function responsible for showing the success/error modal after saving payment plan config
     * data in either of the 3 child tab items.
     * @param param0 - includes a boolean and may have an error message response from API
     */
    const handleModalSaveResponse = ({ IsSuccess }: { IsSuccess: boolean }) => {
        if (IsSuccess) {
            Modal.success({
                title: getTranslatedText('Success'),
                content: getTranslatedText(`Payment plan config data saved successfully!`),
                onOk: () => {
                    fetchInitialData();
                    setSaveLoading(false);
                },
                okText: getTranslatedText(confirmModalOkText),
            });
        } else {
            Modal.error({
                title: getTranslatedText('Error'),
                content: getTranslatedText(`Failed to save payment plan config data`),
            });

            setSaveLoading(false);
        }
    };

    /**
     * A wrapper function (using debounce) for setting if form has changes.
     * Wrapped in debounce to prevent being called a couple of times in a short span of time.
     */
    const setHasChangesDebounced = debounce(() => {
        if (childRef.current) {
            const hasChanges = childRef.current.checkForChanges();

            setFormHasChanges(hasChanges);
        }
    }, 500);

    /**
     * Function called on mount and unmount.
     * This one specifically handles the unmounting to listen for changes
     * and reset colors if needed.
     */
    const mountUnmountFunction = () => {
        return () => {
            if (childRef.current) {
                const hasChanges = childRef.current.checkForChanges();

                if (hasChanges === true && fromBranding) {
                    if (selectedUserCompany) {
                        const { PrimaryColorTheme, SecondaryColorTheme } =
                            selectedUserCompany.Company;

                        if (PrimaryColorTheme && SecondaryColorTheme) {
                            updateLessVariables({
                                '@custom-primary-color': PrimaryColorTheme,
                                '@custom-secondary-color': SecondaryColorTheme,
                            });
                        }
                    }
                }
            }
        };
    };

    useEffect(mountUnmountFunction, []);

    let formsDisabled = true;
    const allowedRoles = rolePermissions.COMPANY_UPDATE_INFORMATION;
    if (isEmpty(allowedRoles) || includes(allowedRoles, userRole)) {
        formsDisabled = false;
    }

    const { savePaymentPlanDataLoading, loading } = paymentPlansState;
    return (
        <div className="h-100">
            <Col span={24}>
                <QueueAnim type={['right', 'left']} leaveReverse>
                    <Row key="title-container" type="flex" align="middle">
                        <Col span={12}>
                            <Title level={3}>{getTranslatedText("Payment Plan Customization")}</Title>
                        </Col>
                        <Col span={12} className="ta-right">
                            <Button
                                className="mr-10 w-100px"
                                type="primary"
                                onClick={onSaveButtonClick}
                                disabled={formsDisabled || !formHasChanges}
                                loading={saveLoading}
                            >
                                {getTranslatedText("Save")}
                            </Button>
                            <Button
                                className="buttonGrey w-100px"
                                onClick={onCancelButtonClick}
                                disabled={formsDisabled || !formHasChanges}
                            >
                                 {getTranslatedText("Cancel")}
                            </Button>
                        </Col>
                    </Row>
                    <br />
                    <Spin
                        spinning={loading}
                        tip={getTranslatedText("Loading payment plan configuration details")}
                        style={{
                            height: '80%',
                        }}
                    >
                        <Row key="tabs-container">
                            <Col span={24}>
                                <Tabs
                                    className="left"
                                    tabPosition="left"
                                    activeKey={currentTab}
                                    onChange={onTabChange}
                                >
                                    {map(
                                        routeTabs,
                                        ({
                                            name: tabName,
                                            component: TabComponent,
                                            isTemplate: isTemplate
                                        }: {
                                            name: string;
                                            component: any;
                                            isTemplate: boolean;
                                        }) => {
                                            const lowerCaseTabName =
                                                tabName.toLowerCase();

                                            return (
                                                <TabPane
                                                    tab={getTranslatedText(tabName)}
                                                    key={lowerCaseTabName}
                                                >
                                                    {lowerCaseTabName ===
                                                        currentTab ? (
                                                        <div

                                                        >
                                                            <TabComponent
                                                                ref={childRef}
                                                                history={
                                                                    props.history
                                                                }
                                                                disabled={
                                                                    formsDisabled
                                                                }
                                                                selectedUserCompany={get(
                                                                    selectedUserCompany,
                                                                    'Company',
                                                                    {}
                                                                )}
                                                                handleModalSaveResponse={
                                                                    handleModalSaveResponse
                                                                }
                                                                setFormHasChanges={
                                                                    setHasChangesDebounced
                                                                }
                                                                form={props.form}
                                                                setSaveLoading={setSaveLoading}
                                                                isTemplate={isTemplate}
                                                                formHasChanges={formHasChanges}
                                                                
                                                            />
                                                        </div>
                                                    ) : null}
                                                </TabPane>
                                            );
                                        }
                                    )}
                                </Tabs>
                            </Col>
                        </Row> 
                    </Spin>


                </QueueAnim>
                {savePaymentPlanDataLoading && (
                    <Suspense fallback={null}>
                        <ModalWithSpinner
                            modalTitle={getTranslatedText("Saving payment plan config data")}
                            modalVisible={savePaymentPlanDataLoading}
                            displayMessage={getTranslatedText("Please wait while saving payment plan config data. . .")}
                        />
                    </Suspense>
                )}
            </Col>
        </div>
    );
};

const PaymentPlanConfigurationPageContainerForm = Form.create({
    name: 'ra-customization-form',
})(PaymentPlanConfigurationPageContainer);

export default withRouter(PaymentPlanConfigurationPageContainerForm);
