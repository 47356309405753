import { action } from 'typesafe-actions';
import { PageData,SupportTicketActionTypes } from './types';
import { ResponseModalObject,DynamicObject } from '../../utils/commonInterfaces';

/**
 * Function for submitting support ticket.
 */
export const submitSupportTicketRequestAction = (
    payload: DynamicObject,    
    callback?: (response: DynamicObject) => void

) => action(SupportTicketActionTypes.SUBMIT_SUPPORT_TICKET_REQUEST, {
        payload,
        callback
    });
export const submitSupportTicketResponseAction = () =>
    action(SupportTicketActionTypes.SUBMIT_SUPPORT_TICKET_RESPONSE);

/**
 * Function for attaching file to a support ticket.
 */
export const attachSupportTicketRequestAction = (
    payload: DynamicObject,    
    callback?: (response: DynamicObject) => void

) => action(SupportTicketActionTypes.ATTACH_SUPPORT_TICKET_REQUEST, {
        payload,
        callback
    });

    /**
 * Function for fetching support ticket lists.
 */
export const getSupportTicketListsRequestAction = (   
    payload: DynamicObject,    
    callback?: (response: DynamicObject) => void,
    refetchCount: number = 1
) => action(SupportTicketActionTypes.GET_SUPPORT_TICKET_LISTS_REQUEST, {
        payload,
        callback,
        refetchCount
    });

export const getSupportTicketListsSuccessAction = (payload: {
    data: DynamicObject[];
    pageData: PageData;
    mergeData?: boolean;
}) => action(SupportTicketActionTypes.GET_SUPPORT_TICKET_LISTS_SUCCESS, payload);


