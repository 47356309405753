import { getTranslatedText } from './commonFunctions';

export const getStartTimingNote = (value: string | undefined) => {
    if (value == undefined) return '';

    let note = getTranslatedText("Note: Schedule date may vary based on the actual ");
    switch (value) {
        case "RequestDate":
            note += getTranslatedText("request date of the payment plan.");
            break;
        case "CreateDate":
            note += getTranslatedText("created date of the invoices.");
            break;
        case "OverdueDate":
            note += getTranslatedText("overdue date of the invoices.");
            break;
    }

    return note;
};