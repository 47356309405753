/**
 * File for the `Customer Insights History` widget.
 */

import { Table } from 'antd';
import {
    clone,
    forEach,
    get,
    includes,
    isUndefined,
    isEmpty,
    filter,
    find
} from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { populatePayloadForOrganisationRegionalWidgets, widgetDateRangeValues } from '../../constants/dashboards';
import { ApplicationState } from '../../store';
import { getDashboardCustomerInsightsHistoryRequestAction } from '../../store/dashboards/actions';
import {
    dashboardBypassAPIFetch,
    replaceInstancesOfCustomerString,
    getDateFilterValues,
    getTranslatedText
} from '../../utils/commonFunctions';
import { DynamicObject } from '../../utils/commonInterfaces';
import { withAccountingSystemHandler } from '../common/AccountingSystemHandler';
import { withDateFormatHandler } from '../common/DateFormatHandler';
import { withNumberFormatHandler } from '../common/NumberFormatHandler';
import {
    displayColumnIndicator,
    displayColumnOptions,
    displayRowCountOptions,
    defaultEndDate,
    defaultStartDate
} from './CustomersInsightsHistoryWidgetFields';
import moment from 'moment-timezone';
import { dateFormatYYYYMMDDDash } from '../../constants/dateFormats';
import { customerFieldIndicator } from '../common/FilterBar';
import { InsightCustomerHistoryReport } from '../../store/dashboards/types';
import { getCustomerUILabel } from '../../store/customers/sagas';
import {
    dateSelectOptions
} from '../../constants/invoicesSortAndFilters';
import { commonOrgFormFields } from './organisation/OrganisationWidgetCommonFilters';

interface IProps {
    widgetDetails: DynamicObject;
    readonly preview?: boolean;
    readonly formatNumber: (
        value: number,
        decimalScale?: number,
        cusLocale?: string
    ) => JSX.Element;
    readonly isUsingCloudImportType: boolean;
    readonly isOrgView?: boolean;
    readonly organisationCurrenciesAll?: DynamicObject[];
    readonly functionRefObj?: any;
}

const CustomersInsightsHistoryWidget: React.FC<IProps> = ({
    widgetDetails,
    isOrgView,
    functionRefObj,
    organisationCurrenciesAll
}: IProps) => {
    const dispatch = useDispatch();
    const unmountedRef = useRef<any>(null);
    const customerLabel = useSelector(getCustomerUILabel);

    const [filteredColumns, setFilteredColumns] = useState<Column[]>(Object.values(displayColumnOptions));
    // function to get month name
    const getMonthName = (monthIndex: number) => {
        const months = [
            "January", "February", "March", "April", "May", "June", 
            "July", "August", "September", "October", "November", "December"
        ];
        return months[monthIndex];
    }

    const getMonthsInRange = (startDate: string, endDate: string): string[] => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const monthsInRange : string[] = ["Customer code", "Customer name"];

        let currentMonth = start.getMonth();
        let currentYear = start.getFullYear();
        const endMonth = end.getMonth();
        const endYear = end.getFullYear();

        while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
            if (currentMonth > 11) {
                currentMonth = 0;
                currentYear++;
            }
            monthsInRange.push(getMonthName(currentMonth));

            currentMonth++;
        }
        return monthsInRange;
      
    };

    const usedDisplayColumns: any = isOrgView
        ? displayColumnOptions
        : filter(displayColumnOptions, ['OrgViewOnly', false]);

    const initialTableColumns = filter(usedDisplayColumns, [
        'defaultChecked',
        true,
    ]).map(({ label, value, orderNumber }: DynamicObject) => ({
        title: replaceInstancesOfCustomerString(
            label,
            customerLabel,
            isOrgView
        ),
        dataIndex: value,
        orderNumber: orderNumber
    }));

    const [tableState, setTableState] = useState<{
        lastWidgetDetails: DynamicObject;
        loading: boolean;
        columns: DynamicObject[];
        dataSource: DynamicObject[];
    }>({
        lastWidgetDetails: {},
        loading: false,
        columns: initialTableColumns,
        dataSource: [],
    });

    const currencySelected = isOrgView
        ? get(widgetDetails, commonOrgFormFields.CURRENCY)
        : undefined;
    const currencySelectedParsed = currencySelected
        ? find(organisationCurrenciesAll, ['Value', currencySelected])
        : undefined;

    let currencyValueUsed: any = undefined;
    let currencyCodeUsed: any = undefined;
    let localeUsed: any = undefined;
    if (isOrgView) {
        currencyValueUsed =
            currencySelectedParsed || get(organisationCurrenciesAll, 0);
            currencyCodeUsed = get(currencyValueUsed, 'Value');
            localeUsed = get(currencyValueUsed, 'Locale');
}

    /**
     * Common function for updating the `tableState` state.
     * @param tableStateObject
     */
    const updateTableStateObject = (tableStateObject: {}) => {
        if (unmountedRef.current) return;
        setTableState({
            ...tableState,
            ...tableStateObject,
        });
    };

    /**
     * Common function for organisation companies
     */
    const organisationCompanies = useSelector(
        (app: ApplicationState) => app.organisations.companies.data
    );
    type Column = {
        label: string,
        value: string,
        defaultChecked: boolean,
        OrgViewOnly: boolean,
        orderNumber: number
    }
    const customerColumns: Column[] = Object.values(displayColumnOptions);

    const dispatchAction = (payloadCallback?: (payload: any) => void) => {
        const customerInsightsColumns: DynamicObject[] = [];
        
        let hasDisplayValue = false;
        let startDate: any = clone(defaultStartDate);
        let endDate: any = clone(defaultEndDate);
        let CustomerName: string = '';
        let CustomerCode: string = '';
        let Customer: string = '';
        let CustomerIds: string[] = [];
        const dateRange = get(widgetDetails, 'dateRangeSelected');
        let dateFilters: any = {
            value: undefined,
            From: {},
            To: {},
            Last: undefined,
            Next: undefined
        }
        let DateMin: any = undefined;
        let DateMax: any = undefined;
        dateFilters.value = get(widgetDetails, "CustomerField---EvaluatedDate--DateType");

        if (dateRange === widgetDateRangeValues.CUSTOM_DATE_RANGE) {
            const customStartDate =
                get(widgetDetails, 'customDateRangeStart') || defaultStartDate;
            const customEndDate =
                get(widgetDetails, 'customDateRangeEnd') || defaultEndDate;
            startDate =
                moment(customStartDate).format(dateFormatYYYYMMDDDash) +
                'T00:00:00';
            endDate =
                moment(customEndDate).format(dateFormatYYYYMMDDDash) +
                'T23:59:59';
        } else if (dateRange === widgetDateRangeValues.THIS_CALENDAR_YEAR) {
            startDate = moment().format('YYYY-01-01T00:00:00');
            endDate = moment().format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_CALENDAR_YEAR) {
            startDate = moment()
                .subtract(1, 'year')
                .format('YYYY-01-01T00:00:00');
            endDate = moment()
                .subtract(1, 'year')
                .format('YYYY-12-31T23:59:59');
        } else if (dateRange === widgetDateRangeValues.LAST_SIX_MONTHS) {
            startDate = moment()
                .subtract(5, 'months')
                .format('YYYY-MM-DDT00:00:00');
            endDate = moment().format('YYYY-MM-DDT23:59:59');

        } else if (
            dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU ||
            dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
        ) {
            const thisYearFinancialYearStart = moment().format(
                'YYYY-07-01T00:00:00'
            );
            const thisYearFinancialYearStartEnd = moment()
                .add(1, 'year')
                .format('YYYY-06-30T23:59:59');
            if (dateRange === widgetDateRangeValues.THIS_FINANCIAL_YEAR_AU) {
                startDate = thisYearFinancialYearStart;
                endDate = thisYearFinancialYearStartEnd;
            } else if (
                dateRange === widgetDateRangeValues.LAST_FINANCIAL_YEAR_AU
            ) {
                startDate = moment(thisYearFinancialYearStart)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T00:00:00`);
                endDate = moment(thisYearFinancialYearStartEnd)
                    .subtract(1, 'year')
                    .format(`${dateFormatYYYYMMDDDash}T23:59:59`);
            }
        }

        forEach(widgetDetails, (wdValue: any, wdKey: string) => {
            const fieldName = wdKey.replace(displayColumnIndicator, '');

            if (
                includes(wdKey, displayColumnIndicator) &&
                !isUndefined(wdValue)
            ) {
                hasDisplayValue = true;
                if (wdValue) {
                    const columnTitle: string = get(
                        displayColumnOptions,
                        `${fieldName}.label`,
                        ''
                    ) as string;
                    customerInsightsColumns.push({
                        title: getTranslatedText(replaceInstancesOfCustomerString(
                            columnTitle,
                            customerLabel,
                            isOrgView
                        )),
                        dataIndex: fieldName,
                    });
                }
            }

            if (includes(wdKey, customerFieldIndicator) && !isUndefined(wdValue)) {
                if (includes(wdKey, "CustomerCode")) {
                    CustomerCode = wdValue;
                }
                else if (includes(wdKey, 'CustomerName')) {
                    CustomerName = wdValue;
                }
                else if (includes(wdKey, 'Customer')) {
                    Customer = wdValue;
                }
                else if (includes(wdKey, "EvaluatedDate")) {
                    if (dateFilters.value === dateSelectOptions.CUSTOM_DATE_RANGE) {
                        if (includes(wdKey, "From")) {
                            dateFilters.From = moment(wdValue);
                        }
                        else if (includes(wdKey, "To")) {
                            dateFilters.To = moment(wdValue);
                        }
                    }
                    else if (dateFilters.value === dateSelectOptions.CUSTOM_DAYS_RANGE) {
                        if (includes(wdKey, "Last--From")) {
                            dateFilters.From.Last = wdValue;
                        }
                        else if (includes(wdKey, "Last--To")) {
                            dateFilters.To.Last = wdValue;
                        }
                    }

                    const { minDate, maxDate } = getDateFilterValues(dateFilters);
                    DateMin = minDate;
                    DateMax = maxDate;  
                }
            }
        });

        const monthsInRange = getMonthsInRange(startDate, endDate);
        
        const filtered = customerColumns.filter((column) =>
        monthsInRange.includes(column.label)
        );
        
        const sortedFilteredColumns = filtered.sort((a, b) => {
            const indexA = monthsInRange.indexOf(a.label);
            const indexB = monthsInRange.indexOf(b.label);
            return indexA - indexB; // Sorting by the position in monthsInRange
            });
          
          // Now set the sorted filtered columns
         setFilteredColumns(sortedFilteredColumns);

        let payload: DynamicObject = {
            filters: {
                CustomerIds,
                Customer,
                DateMin: !isEmpty(DateMin) ? DateMin : startDate,
                DateMax: !isEmpty(DateMax) ? DateMax : endDate,
            },
            SortField: "Customer Code",
            Ascending: false,
            Skip: 0,
            PageSize:
                get(widgetDetails, 'rowCount') ||
                get(displayRowCountOptions, 0),
            currentPage: 0,
        };

        if (isOrgView) {
            payload = populatePayloadForOrganisationRegionalWidgets({
                payload,
                widgetDetails,
                organisationCompanies,
                organisationCurrenciesAll,
                currencyCodeUsed,
            });
        }
        dispatch(
            getDashboardCustomerInsightsHistoryRequestAction(
                payload,
                isOrgView,
                (insightCustomerHistoryReports: InsightCustomerHistoryReport[]) => {

                    if (unmountedRef.current) return;

                    let usedColumns: any = [];
                    if (hasDisplayValue) {
                        usedColumns = customerInsightsColumns;
                    } else {
                        usedColumns = tableState.columns;
                    }

                    updateTableStateObject({
                        columns: usedColumns,
                        dataSource: insightCustomerHistoryReports,
                        loading: false,
                        lastWidgetDetails: clone(widgetDetails),
                    });
                },
                payloadCallback
            )
        );
    }

    /**
     * Function called for initializing widget data based on widgetDetails prop received.
     */
    const initializeWidgetData = () => {
        const bypassAPIFetching = dashboardBypassAPIFetch(
            tableState.lastWidgetDetails,
            widgetDetails
        );
        if (bypassAPIFetching) return;

        updateTableStateObject({
            loading: true,
        });

        dispatchAction(undefined);
    };

    useEffect(initializeWidgetData, [widgetDetails]);

    if (functionRefObj) {
        functionRefObj.getPayload = (callback: (payload: any) => void) => {
            dispatchAction(callback);
        };
    }
    
    /**
     * Function responsible for setting the `unmounted` variable indicator for when this component unmounts.
     */
    const setInitialLoad = () => {
        unmountedRef.current = false;

        //will unmount
        return () => {
            unmountedRef.current = true;
        };
    };

    useEffect(setInitialLoad, []);

    const {
        columns: stateColumns,
        loading: stateLoading,
        dataSource: stateDataSource,
    } = tableState;

    const filteredStateColumns = stateColumns.filter((stateColumn) => 
                                filteredColumns.some((filteredColumn) => filteredColumn.label === stateColumn.title));

    // sorting filtered stateColumns to make sure that order of the months follows order in monthsInRange 
    const sortedFilteredColumns = filteredStateColumns.sort((a, b) => {
        // Find the index of the label in filteredColumns
        const indexA = filteredColumns.findIndex((filteredColumn) => filteredColumn.label === a.title);
        const indexB = filteredColumns.findIndex((filteredColumn) => filteredColumn.label === b.title);
        
        return indexA - indexB; // Sort by the position of the labels in filteredColumns
        });
 
    const customerRelatedColumns = ['CustomerCode', 'CustomerName'];
    const finalColumns = [
        ...stateColumns.filter((column) => customerRelatedColumns.includes(column.dataIndex)),
        ...sortedFilteredColumns

    ];

    if (isOrgView)
    {
        return (
            <div>
                <Table
                    className="table-striped-rows table-ws-nw"    
                    columns={sortedFilteredColumns.map(column => ({ 
                        ...column,
                        title: getTranslatedText(column.title)
                    }))}
                    dataSource={stateDataSource}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    locale={{
                        emptyText: getTranslatedText('No Data'),
                    }}
                    
                />
            </div>
        )
    }
    else
    {
        return (
            <div>
                <Table
                    className="table-striped-rows table-ws-nw"    
                    columns={finalColumns.map(column => ({ 
                        ...column,
                        title: getTranslatedText(column.title)
                    }))}
                    dataSource={stateDataSource}
                    loading={stateLoading}
                    pagination={false}
                    size="middle"
                    locale={{
                        emptyText: getTranslatedText('No Data'),
                    }}
                    
                />
            </div>
        )
    }   
};

export default withAccountingSystemHandler(
    withDateFormatHandler(withNumberFormatHandler(CustomersInsightsHistoryWidget))
);
