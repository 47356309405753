import { UploadFile } from 'antd/lib/upload/interface';

export interface PageData {
    pageSize: number;
    currentPage: number;
    hasNextPage: boolean;
}

export enum SupportTicketActionTypes {
    SUBMIT_SUPPORT_TICKET_REQUEST = '@@ supporttickets/SUBMIT_SUPPORT_TICKET_REQUEST',
    SUBMIT_SUPPORT_TICKET_RESPONSE = '@@supporttickets/SUBMIT_SUPPORT_TICKET_RESPONSE',
    ATTACH_SUPPORT_TICKET_REQUEST = '@@supporttickets/ATTACH_SUPPORT_TICKET_REQUEST',
    GET_SUPPORT_TICKET_LISTS_REQUEST = '@@supporttickets/GET_SUPPORT_TICKET_LISTS_REQUEST',
    GET_SUPPORT_TICKET_LISTS_SUCCESS = '@@supporttickets/GET_SUPPORT_TICKET_LISTS_SUCCESS',
    SET_SUPPORT_TICKET_SELECTED_ID_REQUEST = '@@supporttickets/SET_SUPPORT_TICKET_SELECTED_ID_REQUEST',
    SET_SUPPORT_TICKET_SELECTED_ID_SUCCESS = '@@supporttickets/SET_SUPPORT_TICKET_SELECTED_ID_SUCCESS',
    GET_SUPPORT_TICKET_DATA_REQUEST = '@@supporttickets/GET_SUPPORT_TICKET_DATA_REQUEST',
    GET_SUPPORT_TICKET_DATA_SUCCESS = '@@supporttickets/GET_SUPPORT_TICKET_DATA_SUCCESS',
    GET_SUPPORT_TICKET_DATA_ERROR = '@@supporttickets/GET_SUPPORT_TICKET_DATA_ERROR',
    CLEAR_SUPPORT_TICKET_DETAILS_DATA_SUCCESS = '@@supporttickets/CLEAR_SUPPORT_TICKET_DETAILS_DATA_SUCCESS',
}

export interface SupportTicketsState {
    readonly loading: boolean;
    readonly errorMessages: string[];
    readonly activeData: {
        loading: boolean;
        record: {};
        selectedId: string | null;
        errorMessages: string[];
    };
}

export interface GetSupportTicketRequestPayload {
    pageSize: number;
    currentPage: number;
}

export interface SubmitSupportTicketRequestPayload {
    readonly Subject: string;
    readonly Description: string;
    readonly Priority: string;
    readonly FileLists: UploadFile[];
}

export interface File {
    readonly UId: string;
    readonly Name: string;
    readonly Size: number;
    readonly Type: string;
    readonly webkitRelativePath: string;
    readonly lastModified: number;
    readonly lastModifiedDate: string;
}

export interface SupportTicket {
    readonly CreatedTime: string;
    readonly Id: string;
    readonly Priority: string;
    readonly Status: string;
    readonly Subject: string;
    readonly TicketNumber: string;
}

export interface SupportTicketDetails {
    readonly ticketNumber: string,
    readonly subject: string,
    readonly description: string,
    readonly statusType: string,
    readonly createdTime: string,
    readonly closedTime: string,
    readonly priority: string,
    readonly dueDate: string,   
    readonly assignee: Assignee,
    readonly contact: Contact
}

export interface Assignee {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
}

export interface Contact {
    readonly firstName: string;
    readonly lastName: string;
    readonly email: string;
}

