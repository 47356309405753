/**
 * Reusable component for populating the payment plan option tab section.
 */

import { Card, Col, Row, Select, Typography, Tabs, Form, Input, InputNumber, DatePicker, Button, Tooltip, Icon, Checkbox } from 'antd';
import { get, map, isObject, isEmpty, filter } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { PaymentPlanConfig, } from '../../store/paymentPlans/types';

import {
    FORM_FIELDS,
    paymentPlanConfigStartTimingOptions,
    INSTALMENT_TYPE,
    FORM_DISPLAY
} from '../../constants/paymentPlanConfigs';
import { dateFormatDDMMMYYYYSpace, dateFormatDDMMYYYYSlash } from '../../constants/dateFormats';
import moment, { Moment } from 'moment-timezone';
import { getLoadingSkeletons, getTranslatedText, limitOnlyNumber } from '../../utils/commonFunctions';
import { Company } from '../../store/companies/types';
import { RangePickerValue } from 'antd/lib/date-picker/interface';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FontAwesome from '../common/FontAwesome';
import { getStartTimingNote } from '../../utils/paymentPlanConfigFunctions';

const { TabPane } = Tabs;
const { Option } = Select;
const { Item: FormItem } = Form;

interface IProps {
    form?: any;
    updateFormState: any;
    setPaymentPlanConfigFormState: any;
    paymentPlanConfigFormState: any;
    paymentPlanOption: PaymentPlanConfig;
    paymentPlanOptionIndex: number;
    paymentPlanOptionKeyIndex: number;
    formatDateString: any;
    addPaymentPlanScheduleDate: (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number) => void;
    paymentPlanScheduleDates: string[];
    populateAddedScheduleDates: any;
    removeInitialScheduleDate: (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number, scheduleDateIndex: number) => void;
    populateCustomfield: any;
    handleCustomFieldTabChange: (value: string) => void;
    selectedCustomFieldTab: string
    handleCustomFieldEditTab: (targetKey: string | React.MouseEvent<Element, MouseEvent>, action: "add" | "remove", paymentPlanOptionindex: number, paymentPlanOptionKeyIndex: number) => void;
    paymentPlanCustomFieldKeys: { [key: number]: number[] }
    selectedUserCompany: Company;
    addPaymentPlanCustomField: (paymentPlanOptionIndex: number, paymentPlanOptionKeyIndex: number) => void;
    paymentPlanCustomFieldTabs: any;
    paymentPlanDynamicCustomFieldTabs: any;
    paymentPlanCustomFields: any;
    setFormHasChanges: () => void;
    setAddPaymentPercents: any;
    addPaymentPercents: boolean[];
}

const PaymentPlanOptionDetailsTabContent: React.FC<IProps> = ({
    form,
    updateFormState,
    setPaymentPlanConfigFormState,
    paymentPlanConfigFormState,
    paymentPlanOption,
    paymentPlanOptionIndex,
    paymentPlanOptionKeyIndex,
    formatDateString,
    addPaymentPlanScheduleDate,
    paymentPlanScheduleDates,
    populateAddedScheduleDates,
    removeInitialScheduleDate,
    handleCustomFieldTabChange,
    selectedCustomFieldTab,
    handleCustomFieldEditTab,
    selectedUserCompany,
    addPaymentPlanCustomField,
    paymentPlanCustomFieldTabs,
    paymentPlanDynamicCustomFieldTabs,
    paymentPlanCustomFields,
    setFormHasChanges,
    setAddPaymentPercents,
    addPaymentPercents
}: IProps) => {
    const { getFieldDecorator, setFieldsValue } = form;
    const { RangePicker } = DatePicker;
    const addPaymentPercent: boolean = addPaymentPercents[paymentPlanOptionKeyIndex];
    const [instalmentTypeDescription, setInstalmentTypeDescription] = useState<string>();

    /**
    * Function that concatanate field names.
    */
    const generateFields = (fieldName: string, fieldIndex?: number | null) => {
        let field = `${fieldName}${paymentPlanOptionIndex}`;
        if (fieldIndex != null) {
            field = `${field}${fieldIndex}`;
        }

        return field;
    };

    const firstDateOfCurrentMonth = () => {
        const current = moment(); // Get the current date
        current.date(1); // Set the date to the first of the month
        return current.format(dateFormatDDMMMYYYYSpace); // This will return a Date object representing the first day of the current month
    };

    const updateMultipleFieldsFormState = (fields: Record<string, any>) => {
        setPaymentPlanConfigFormState((prevState: any) => {
            const newState = [...prevState];
            newState[paymentPlanOptionIndex] = {
                ...newState[paymentPlanOptionIndex],
                ...fields,
            };

            return newState
        });

        setFormHasChanges();
    };

    const updatePayPercent = () => {
        const paymentPlanConfigNewList = [...paymentPlanConfigFormState];
        const paymentPlanConfigStateCopy = {
            ...paymentPlanConfigNewList[paymentPlanOptionIndex],
        };

        // Initialize PayPercent with 'null' for each instalment
        paymentPlanConfigStateCopy.PayPercent = Array.from({ length: paymentPlanOption.Instalments }, () => null);

        // Update the state with the modified object
        paymentPlanConfigNewList[paymentPlanOptionIndex] = paymentPlanConfigStateCopy;

        setPaymentPlanConfigFormState(paymentPlanConfigNewList);
        setFormHasChanges();
    };

    const updatePayPercentFormState = (payPercentIndex: number, val: any) => {
        setPaymentPlanConfigFormState((prevState: any) => {
            const newState = [...prevState];
            const paymentPlanOption = newState[paymentPlanOptionIndex];

            const paymentPlanConfigStateCopy = {
                ...paymentPlanOption,
            };

            paymentPlanConfigStateCopy.PayPercent[payPercentIndex] = val;
            newState[paymentPlanOptionIndex] = {
                ...paymentPlanOption,
                ...paymentPlanConfigStateCopy,
            };

            return newState;
        });
    };

    /**
    * Function that populates dropdown section.
    * @param options - list of options
    * @param hasDelete - boolean indicator if the dropdown option has delete functionality
    */
    const populateSelectOptions = () => {
        const optionComponents = map(selectedUserCompany.CustomFieldsConfigure, (option: any) => {
            let label, value;
            if (isObject(option)) {
                label = get(option, 'FieldName');
                value = get(option, 'FieldName');
            } else {
                label = option;
                value = option;
            }

            return (
                <Option key={value} value={value} label={label}>
                    {label}
                </Option>
            );
        });

        return optionComponents;
    };

    function handleChangeInstalmentPeriod(dates: RangePickerValue) {
        const updateFields: Record<string, any> = {};
        const instalments = paymentPlanOption.Instalments;
        let payPercentages: (number | null)[] = [];
        let displayDates: any[] = [];//ui display
        let formDates: any[] = [];//save format

        let splitInstalmentPeriod: Moment[] | undefined = paymentPlanOption.SplitInstalmentDateRange ?
            paymentPlanOption.SplitInstalmentDateRange.map(date => moment(date)) : undefined;

        if (instalments && splitInstalmentPeriod && splitInstalmentPeriod[0] && splitInstalmentPeriod[1]) {
            const startDate = splitInstalmentPeriod[0].toDate();
            const endDate = splitInstalmentPeriod[1].toDate();
            const dateDiff = endDate.getTime() - startDate.getTime();
            const interval = dateDiff / instalments;

            // Arrays to store the unique dates
            const displayDatesSet = new Set();
            const formDatesSet = new Set();

            for (let i = 1; i <= instalments; i++) {
                // Calculate the date
                const newDate = new Date(startDate.getTime() + i * interval);

                // Format the dates in the desired formats
                const displayDate = moment(newDate).format(dateFormatDDMMMYYYYSpace);
                const formDate = moment(newDate).format(dateFormatDDMMYYYYSlash);

                // Add to the set to ensure uniqueness
                displayDatesSet.add(displayDate);
                formDatesSet.add(formDate);

                setFieldsValue({
                    [paymentPlanOptionIndex + FORM_FIELDS.PAY_PERCENT + (i - 1)]: null,
                });
                payPercentages.push(null);
            }

            // Convert sets to arrays
            const numberOfDays = moment(splitInstalmentPeriod[1]).diff(moment(splitInstalmentPeriod[0]), 'days');
            if (instalments <= numberOfDays) {
                displayDates = Array.from(displayDatesSet);
                formDates = Array.from(formDatesSet);
            }

            updateFields[FORM_DISPLAY.INSTALMENT_MAX] = numberOfDays;
        }
        updateFields[FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE] = dates;

        if (addPaymentPercents[paymentPlanOptionIndex]) {
            updateFields[FORM_FIELDS.PAY_PERCENT] = payPercentages;
            updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = true;
            updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
            updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
        }

        if (displayDates.length > 0 && formDates.length > 0) {
            updateFields[FORM_FIELDS.SCHEDULE_DATES] = formDates
            updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = displayDates
        }

        updateMultipleFieldsFormState(updateFields);
    }

    const handlePayPercentCheckboxClick = (
        e: CheckboxChangeEvent
    ) => {
        const addPaymentPercentsArray = { ...addPaymentPercents };
        addPaymentPercentsArray[paymentPlanOptionKeyIndex] = e.target.checked

        setAddPaymentPercents(addPaymentPercentsArray);
        if (e.target.checked) {
            updatePayPercent()
        }
        setFormHasChanges();

    };

    function handlePayPercent(percent: any, index: number) {
        let numberOfInstalment: number = paymentPlanOption.Instalments;
        let scheduleDatesDisplay: string[] = paymentPlanOption.ScheduleDatesDisplay ? paymentPlanOption.ScheduleDatesDisplay : [];
        let newScheduleDatesDisplay: string[] = [];
        let total: number = 0;

        for (var i = 0; i < numberOfInstalment; i++) {
            //get latest value
            if (i == index) {
                total = total + percent;
                if (scheduleDatesDisplay) newScheduleDatesDisplay.push(`${scheduleDatesDisplay[index]} - ${percent}%`);
            }
            else {
                let value: number = paymentPlanOption.PayPercent ? paymentPlanOption.PayPercent[i] : 0;
                if (value) {
                    total = total + value;
                    if (scheduleDatesDisplay) newScheduleDatesDisplay.push(`${scheduleDatesDisplay[i]} - ${value}%`);
                }
            }
        }

        let updateFields: any = {};

        //update display schedule dates
        if (scheduleDatesDisplay && newScheduleDatesDisplay.length > 0)
            updateFields[FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY] = newScheduleDatesDisplay;

        updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = getTranslatedText("Total is [percentage]%").replace("[percentage]", total.toString());
        if (total != 100) {
            updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = getTranslatedText("The required total payment percentage has not been met.");
        } else {
            updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
        }
        updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = false;
        updateMultipleFieldsFormState(updateFields);

        updatePayPercentFormState(index, percent);
    }

    function handleChangeInstalments(instalment: number | undefined) {
        const updateFields: Record<string, any> = {};
        const instalments = instalment;
        let payPercentages: (number | null)[] = [];
        let displayDates: any[] = [];//ui display
        let formDates: any[] = [];//save format

        let splitInstalmentPeriod: Moment[] | undefined = paymentPlanOption.SplitInstalmentDateRange ?
            paymentPlanOption.SplitInstalmentDateRange.map(date => moment(date)) : undefined;

        if (instalments && splitInstalmentPeriod && splitInstalmentPeriod[0] && splitInstalmentPeriod[1]) {
            const startDate = splitInstalmentPeriod[0].toDate();
            const endDate = splitInstalmentPeriod[1].toDate();
            const dateDiff = endDate.getTime() - startDate.getTime();
            const interval = dateDiff / instalments;

            // Arrays to store the unique dates
            const displayDatesSet = new Set();
            const formDatesSet = new Set();

            for (let i = 1; i <= instalments; i++) {
                // Calculate the date
                const newDate = new Date(startDate.getTime() + i * interval);

                // Format the dates in the desired formats
                const displayDate = moment(newDate).format(dateFormatDDMMMYYYYSpace);
                const formDate = moment(newDate).format(dateFormatDDMMYYYYSlash);

                displayDatesSet.add(displayDate);
                formDatesSet.add(formDate);

                setFieldsValue({
                    [paymentPlanOptionIndex + FORM_FIELDS.PAY_PERCENT + (i - 1)]: null,
                });
                payPercentages.push(null);
            }

            // Convert sets to arrays
            const numberOfDays = moment(splitInstalmentPeriod[1]).diff(moment(splitInstalmentPeriod[0]), 'days');
            if (instalments <= numberOfDays) {
                displayDates = Array.from(displayDatesSet);
                formDates = Array.from(formDatesSet);
            }

            updateFields[FORM_DISPLAY.INSTALMENT_MAX] = numberOfDays;
        }
        updateFields[FORM_FIELDS.INSTALMENTS] = instalment

        if (addPaymentPercents[paymentPlanOptionIndex]) {
            updateFields[FORM_FIELDS.PAY_PERCENT] = payPercentages;
            updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = true;
            updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
            updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
        }

        if (displayDates.length > 0 && formDates.length > 0) {
            updateFields[FORM_FIELDS.SCHEDULE_DATES] = formDates
            updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = displayDates
        }

        updateMultipleFieldsFormState(updateFields);
    }

    function handleMonthlyFields(value: any, fieldName: string) {
        let displayDates: any[] = [];//ui display
        let formDates: any[] = [];//save format
        let monthDay: number | undefined = fieldName == FORM_FIELDS.SPECIFIC_PAYMENT_DAY ? value : paymentPlanOption.SpecificPaymentDate;
        let startDelay: number = fieldName == FORM_FIELDS.START_DELAY ? value : paymentPlanOption.StartDelay;
        let frequencyGap: number | undefined = fieldName == FORM_FIELDS.FREQUENCY_GAP ? value : paymentPlanOption.FrequencyGap;
        let instalments = paymentPlanOption.Instalments;
        let updateFields: any = {};

        if (monthDay && startDelay >= 0 && frequencyGap && instalments) {
            let currentDate = moment(firstDateOfCurrentMonth());
            let startMonth: number = startDelay;
            let saveDate: any;

            for (let i = 1; i <= instalments; i++) {
                if (!isEmpty(saveDate))
                    saveDate = saveDate.add(frequencyGap, 'months').date(monthDay);
                else
                    saveDate = currentDate.add(startMonth, 'months').date(monthDay);

                displayDates.push(saveDate.format(dateFormatDDMMMYYYYSpace));
                formDates.push(saveDate.format(dateFormatDDMMYYYYSlash));

                if (addPaymentPercent)
                    setFieldsValue({ [paymentPlanOptionIndex + FORM_FIELDS.PAY_PERCENT + (i - 1)]: null });
            }

            if (addPaymentPercent) {
                updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = true;
                updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
                updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
            }

            if (displayDates.length > 0 && formDates.length > 0) {
                updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = displayDates;
                updateFields[FORM_FIELDS.SCHEDULE_DATES] = formDates;
            }
        }

        updateFields[fieldName] = value;
        if (!isEmpty(updateFields)) {
            updateMultipleFieldsFormState(updateFields);
        }
    }

    const assignMonthDate = (date: any, day: number) => {
        const endOfMonth = moment(date).endOf('month').date();

        if (day > endOfMonth) return endOfMonth;

        return day;
    }

    const displayScheduleDates = () => {
        let displayDates: any[] = [];//ui display
        let formDates: any[] = [];//save format
        let updateFields: any = {};
        let instalments: number = paymentPlanOption.Instalments;
        let splitInstalmentPeriod: Moment[] | undefined = paymentPlanOption.SplitInstalmentDateRange ?
            paymentPlanOption.SplitInstalmentDateRange.map(date => moment(date)) : undefined;
        let payPercentages: (number | null)[] = [];
        let payPercentageScheduleDatesDisplay: string[] = [];

        if (instalments && splitInstalmentPeriod && splitInstalmentPeriod[0] && splitInstalmentPeriod[1]) {
            const startDate = splitInstalmentPeriod[0].toDate();
            const endDate = splitInstalmentPeriod[1].toDate();
            const dateDiff = endDate.getTime() - startDate.getTime();
            const interval = dateDiff / instalments;
            // Arrays to store the unique dates
            const displayDatesSet = new Set();
            const formDatesSet = new Set();

            for (let i = 1; i <= instalments; i++) {
                // Calculate the date
                const newDate = new Date(startDate.getTime() + i * interval);

                // Format the dates in the desired formats
                const displayDate = moment(newDate).format(dateFormatDDMMMYYYYSpace);
                const formDate = moment(newDate).format(dateFormatDDMMYYYYSlash);

                // Add to the set to ensure uniqueness
                displayDatesSet.add(displayDate);
                formDatesSet.add(formDate);
            }

            // Convert sets to arrays
            const numberOfDays = moment(splitInstalmentPeriod[1]).diff(moment(splitInstalmentPeriod[0]), 'days');
            if (instalments <= numberOfDays) {
                displayDates = Array.from(displayDatesSet);
                formDates = Array.from(formDatesSet);
            }

            updateFields[FORM_DISPLAY.INSTALMENT_MAX] = numberOfDays;
        }

        let monthDay: number | undefined = paymentPlanOption.SpecificPaymentDate;
        let startDelay = paymentPlanOption.StartDelay;
        let frequencyGap: number | undefined = paymentPlanOption.FrequencyGap;
        if (paymentPlanOption.InstalmentType == INSTALMENT_TYPE.MONTHLY && monthDay && startDelay != null && frequencyGap && instalments) {
            let currentDate = moment(firstDateOfCurrentMonth());
            let startMonth: number = startDelay;
            let saveDate: any;

            for (let i = 1; i <= instalments; i++) {

                if (!isEmpty(saveDate))
                    saveDate = saveDate.add(frequencyGap, 'months').date(assignMonthDate(saveDate, monthDay));
                else
                    saveDate = currentDate.add(startMonth, 'months').date(assignMonthDate(currentDate, monthDay));

                displayDates.push(saveDate.format(dateFormatDDMMMYYYYSpace));
                formDates.push(saveDate.format(dateFormatDDMMYYYYSlash));
            }
        }

        if (paymentPlanOption.InstalmentType == INSTALMENT_TYPE.CUSTOM && (!isEmpty(paymentPlanOption.ScheduleDates) || !isEmpty(paymentPlanScheduleDates)) && instalments) {
            if (paymentPlanOption.ScheduleDates) {
                paymentPlanOption.ScheduleDates.map((scheduleDate) => {
                    displayDates.push(scheduleDate);
                })
            }

            if (paymentPlanScheduleDates) {
                paymentPlanScheduleDates.map((scheduleDate) => {
                    displayDates.push(scheduleDate);
                });
            }

            if (isEmpty(paymentPlanOption.ScheduleDatesDisplay)) {
                updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = displayDates;
            }
        }

        if (paymentPlanOption.PayPercent) {
            const paymentPercentageTotal = paymentPlanOption.PayPercent.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            if (addPaymentPercent == undefined || addPaymentPercent == null) {
                setAddPaymentPercents((prevState: any) => {
                    const addPaymentPercentsArray = { ...prevState }
                    addPaymentPercentsArray[paymentPlanOptionIndex] = true
                    return addPaymentPercentsArray;
                })

                paymentPlanOption.PayPercent.map((payPercentage) => {
                    payPercentages.push(payPercentage);
                });

                updateFields[FORM_FIELDS.PAY_PERCENT] = payPercentages;
                updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = false;
                updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = getTranslatedText("Total is [percentage]%").replace("[percentage]", paymentPercentageTotal.toString());
                if (paymentPercentageTotal != 100) {
                    updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = getTranslatedText("The required total payment percentage has not been met.");
                } else {
                    updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
                }

                payPercentages.map((percent, index) => {
                    payPercentageScheduleDatesDisplay.push(`${displayDates[index]} - ${percent}%`);
                })

                if (displayDates && payPercentageScheduleDatesDisplay.length > 0)
                    updateFields[FORM_DISPLAY.PAY_PERCENT_SCHEDULE_DATES_DISPLAY] = payPercentageScheduleDatesDisplay;
            }
        }

        if (displayDates.length > 0 && formDates.length > 0) {
            updateFields[FORM_DISPLAY.SCHEDULE_DATES_DISPLAY] = displayDates;
            updateFields[FORM_FIELDS.SCHEDULE_DATES] = INSTALMENT_TYPE.MONTHLY != paymentPlanOption.InstalmentType ? formDates : null;
            updateFields[FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE] = paymentPlanOption.SplitInstalmentDateRange;
        }

        if (paymentPlanOption.Instalments == 1) {
            //     let updateFields: any = {};

            const addPaymentPercentsArray = { ...addPaymentPercents };
            addPaymentPercentsArray[paymentPlanOptionKeyIndex] = false;

            setAddPaymentPercents(addPaymentPercentsArray);

            updateFields[FORM_FIELDS.PAY_PERCENT] = null;
            updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = false;
            updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
            updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';
        }


        if (!isEmpty(updateFields)) {
            updateMultipleFieldsFormState(updateFields);
        }
    }

    useEffect(displayScheduleDates, [
        paymentPlanOption.Instalments,
        paymentPlanOption.SplitInstalmentDateRange,
        paymentPlanOption.SpecificPaymentDate,
        paymentPlanOption.StartDelay,
        paymentPlanOption.FrequencyGap,
        paymentPlanScheduleDates
    ]);

    useEffect(() => {
        if (addPaymentPercent == false) {
            let updateFields: any = {};

            updateFields[FORM_FIELDS.PAY_PERCENT] = null;
            updateFields[FORM_DISPLAY.PAY_PERCENT_HIDE_PREVIEW] = true;
            updateFields[FORM_DISPLAY.PAY_PERCENT_TOTAL] = '';
            updateFields[FORM_DISPLAY.PAY_PERCENT_WARNING] = '';

            if (!isEmpty(updateFields)) {
                updateMultipleFieldsFormState(updateFields);
            }
        }

    }, [addPaymentPercent]);

    useEffect(() => {
        if (paymentPlanOption.InstalmentType) {
            switch (paymentPlanOption.InstalmentType.replace(/\s+/g, '').toLowerCase()) {

                case INSTALMENT_TYPE.CUSTOM.replace(/\s+/g, '').toLowerCase():
                    setInstalmentTypeDescription(getTranslatedText("The custom type is for payment schedule dates that are specific."));
                    break;
                case INSTALMENT_TYPE.DATE_RANGE.replace(/\s+/g, '').toLowerCase():
                    setInstalmentTypeDescription(getTranslatedText("The date range type allows you to generate payment schedule dates based on the number of instalments."));
                    break;
                case INSTALMENT_TYPE.MONTHLY.replace(/\s+/g, '').toLowerCase():
                    setInstalmentTypeDescription(getTranslatedText("The monthly type is for creating payment schedule dates based on the invoice creation, overdue, or requested dates."));
                    break;
                case INSTALMENT_TYPE.PAYMENT_AMOUNT.replace(/\s+/g, '').toLowerCase():
                    setInstalmentTypeDescription(getTranslatedText("The payment amount type is for monthly payment schedule dates that will calculate the number of installments based on the invoice amount and the instalment amount the customer will pay."));
                    break;
            }

            //set minimum number of installment
            let minimumInstalment: number = paymentPlanOption.InstalmentType == INSTALMENT_TYPE.DATE_RANGE ? 2 : 1;
            let updateFields: any = {};

            updateFields[FORM_DISPLAY.INSTALMENT_MIN] = minimumInstalment;

            if (!isEmpty(updateFields)) {
                updateMultipleFieldsFormState(updateFields);
            }


        } else {
            setPaymentPlanConfigFormState((prevState: any) => {
                const newState = [...prevState];
                const paymentPlanOption = newState[paymentPlanOptionIndex];

                const paymentPlanConfigStateCopy = {
                    ...paymentPlanOption,
                };

                if (isEmpty(paymentPlanOption.Name) && isEmpty(paymentPlanOption.ScheduleDates)) {
                    paymentPlanConfigStateCopy.InstalmentType = INSTALMENT_TYPE.MONTHLY;
                } else if (isEmpty(paymentPlanOption.Name) && !isEmpty(paymentPlanOption.ScheduleDates)) {
                    paymentPlanConfigStateCopy.InstalmentType = INSTALMENT_TYPE.CUSTOM;
                }

                newState[paymentPlanOptionIndex] = {
                    ...paymentPlanOption,
                    ...paymentPlanConfigStateCopy,
                };

                return newState;
            });
        }
    }, [paymentPlanConfigFormState[generateFields(FORM_FIELDS.INSTALMENT_TYPE)]]);

    /**
    * Function that populates the accounting system dropdown options.
    */
    const populatePaymentPlanConfigStartTimingOptions = () => {
        return map(
            paymentPlanConfigStartTimingOptions,
            ({ label, value }: { label: string; value: string }) => (
                <Option key={value} value={value}>
                    {label}
                </Option>
            )
        );
    };

    /**
    * Function that populates the dropdown options for custom day option.
    */
    const populateCustomDayChildrenOptions = () =>
        map(
            Array.from({ length: 31 }, (_, i) => i + 1),
            i => (
                <Option key={i} value={i}>
                    {i}
                </Option>
            )
        );

    const populatePaymentPercent = () => {
        let listOfInstalment: number[] = [];
        let numberOfInstalment = paymentPlanOption.Instalments;
        for (var i = 0; i < numberOfInstalment; i++) {
            listOfInstalment.push(i);
        }
        let isDisabled = paymentPlanOption.InstalmentType.replace(/\s+/g, '').toLowerCase() == INSTALMENT_TYPE.DATE_RANGE.replace(/\s+/g, '').toLowerCase() ? (isEmpty(paymentPlanOption.SplitInstalmentDateRange) ? true : false) : false

        const formItems = listOfInstalment.map((k: any) => (
            <div key={k} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                {k + 1}.&nbsp;<FormItem key={k}>
                    {getFieldDecorator(paymentPlanOptionIndex + FORM_FIELDS.PAY_PERCENT + k, {
                        rules: [
                            {
                                required: true,
                                message: getTranslatedText("Payment percentage is required!"),
                            }
                        ],
                        initialValue: paymentPlanOption.PayPercent ? paymentPlanOption.PayPercent[k] : null
                    })(<InputNumber
                        min={1}
                        max={100}
                        type="number"
                        style={{ width: 100 }}
                        onChange={(val) => handlePayPercent(val, k)}
                        onKeyDown={limitOnlyNumber(false)}
                        disabled={isDisabled}
                    />

                    )}
                </FormItem>&nbsp;%
            </div>
        ));
        return formItems;
    };

    const validatePaymentPlanInstalment = useMemo(() => (scheduleDate: string) => {
        let warning = null;

        const isDuplicateInDynamicScheduleDates = filter(paymentPlanScheduleDates, paymentPlanScheduleDate => paymentPlanScheduleDate === scheduleDate).length > 1;

        const isDuplicateInInitialScheduleDates = filter(paymentPlanOption.ScheduleDates, paymentPlanScheduleDate => paymentPlanScheduleDate === scheduleDate).length > 1;

        const isInBothArrays = filter(paymentPlanScheduleDates, paymentPlanScheduleDate => paymentPlanScheduleDate === scheduleDate).length > 0 &&
            filter(paymentPlanOption.ScheduleDates, paymentPlanScheduleDate => paymentPlanScheduleDate === scheduleDate).length > 0;

        if (isDuplicateInDynamicScheduleDates || isDuplicateInInitialScheduleDates || isInBothArrays) {
            warning = getTranslatedText("This schedule date has been duplicated!");
        }

        return warning;
    }, [paymentPlanScheduleDates, paymentPlanOption.ScheduleDates]);

    const populateCustomForm = () => {
        let scheduleDatesWarning: { ScheduleIndex: number, warning: string }[] = [];
        let scheduleDatesWarningDynamic: { ScheduleIndex: number, warning: string }[] = [];

        if (paymentPlanOption.ScheduleDates) {
            paymentPlanOption.ScheduleDates.map((scheduleDate, scheduleDateIndex) => {
                if (scheduleDate) {
                    let warning = validatePaymentPlanInstalment(scheduleDate);
                    if (warning) {
                        scheduleDatesWarning[scheduleDateIndex] = { ScheduleIndex: scheduleDateIndex, warning: warning };
                    }
                }
            })
        }

        if (paymentPlanScheduleDates) {
            paymentPlanScheduleDates.map((scheduleDate, scheduleDateIndex) => {
                if (scheduleDate) {
                    let warning = validatePaymentPlanInstalment(scheduleDate);
                    if (warning) {
                        scheduleDatesWarningDynamic[scheduleDateIndex] = { ScheduleIndex: scheduleDateIndex, warning: warning };
                    }
                }

            })
        }

        return (
            <>
                <Row className="mt-9">
                    <Col span={24}>
                        <Card>
                            <Col span={8}>
                                {getTranslatedText("Schedule dates:")} <br />
                                <Button type="primary"
                                    onClick={() => addPaymentPlanScheduleDate(paymentPlanOptionIndex, paymentPlanOptionKeyIndex)}
                                    disabled={paymentPlanOption.Instalments >= 30}>
                                    {getTranslatedText("Add schedule dates")}
                                </Button>
                            </Col>
                            <Col span={10}>
                                {Array.isArray(paymentPlanOption.ScheduleDates) && !isEmpty(paymentPlanOption.ScheduleDates) ?
                                    (paymentPlanOption.ScheduleDates.map((scheduleDate: string, index: number) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                            <FormItem key={index}>
                                                {getFieldDecorator("Initial" + FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + FORM_FIELDS.SCHEDULED_DATE + index, {
                                                    initialValue: scheduleDate ? moment(scheduleDate, 'DD/MM/YYYY') : null,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: getTranslatedText("Scheduled dates required!"),
                                                        }
                                                    ]
                                                })(<DatePicker
                                                    format={dateFormatDDMMMYYYYSpace}
                                                    placeholder={getTranslatedText("Date")}
                                                    onChange={updateFormState(
                                                        FORM_FIELDS.PAYMENT_PLAN_OPTION + paymentPlanOptionIndex + FORM_FIELDS.SCHEDULED_DATE + index,
                                                        paymentPlanOptionIndex,
                                                        index
                                                    )}
                                                />

                                                )}
                                            </FormItem>

                                            <a
                                                style={{ marginLeft: '10px', cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                                                onClick={() => removeInitialScheduleDate(paymentPlanOptionIndex, paymentPlanOptionKeyIndex, index)}
                                            >
                                                <FontAwesome icon={['fas', 'trash']} style={{ color: 'gray' }} />
                                            </a>
                                            {(scheduleDatesWarning[index]) && (
                                                <Tooltip title={scheduleDatesWarning[index].warning}>
                                                    <FontAwesome
                                                        icon={['fas', 'info-circle']}
                                                        style={{ color: '#ff5d00', marginLeft: '5px' }}
                                                        className="fs-18"
                                                    />
                                                </Tooltip>)}
                                        </div>)
                                    )
                                    ) :
                                    ''
                                }
                                {
                                    paymentPlanScheduleDates ?
                                        populateAddedScheduleDates(paymentPlanOptionIndex, paymentPlanOptionKeyIndex, scheduleDatesWarningDynamic)
                                        : ''
                                }
                            </Col>
                        </Card>
                    </Col>
                </Row>

            </>
        )
    }

    const populateMonthlyForm = () => {
        return (
            <>
                <Row>
                    <Col span={8}>
                        <FormItem label={
                            <span>
                                {getTranslatedText("Start timing")}&nbsp;
                                <Tooltip title={getTranslatedText("It will be based on the invoice details, and for multiple invoices, it will be the earliest one.")}>
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        }
                            extra={paymentPlanOption.StartTiming ?
                                (<strong>{getTranslatedText("E.g. Earliest Invoice [startTiming]: [firstDateOfCurrentMonth]")
                                    .replace("[startTiming]", getTranslatedText(paymentPlanOption.StartTiming))
                                    .replace("[firstDateOfCurrentMonth]", firstDateOfCurrentMonth())}</strong>) : ''
                            }
                        >
                            {getFieldDecorator("Initial" + FORM_FIELDS.START_TIMING + paymentPlanOptionIndex, {
                                initialValue: paymentPlanOption.StartTiming,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText("Start timing is required!"),
                                    }
                                ]
                            })(
                                <Select
                                    onChange={updateFormState(
                                        FORM_FIELDS.START_TIMING + paymentPlanOptionIndex,
                                        paymentPlanOptionIndex
                                    )}
                                    style={{ width: 200 }}
                                >
                                    {populatePaymentPlanConfigStartTimingOptions()}
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormItem label={
                            <span>
                                {getTranslatedText("Frequecy gap")}&nbsp;
                                <Tooltip title={getTranslatedText("Monthly gap between each payment date.")}>
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        }>
                            {getFieldDecorator("Initial" + FORM_FIELDS.FREQUENCY_GAP + paymentPlanOptionIndex, {
                                initialValue: paymentPlanOption.FrequencyGap,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText("Frequency gap is required!"),
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={1}
                                    max={12}
                                    style={{ width: 200 }}
                                    onChange={(value) => handleMonthlyFields(value, FORM_FIELDS.FREQUENCY_GAP)}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormItem label={
                            <span>
                                {getTranslatedText("Month day")}&nbsp;
                                <Tooltip title={getTranslatedText("The current day of the month")}>
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        }>
                            {getFieldDecorator("Initial" + FORM_FIELDS.SPECIFIC_PAYMENT_DAY + paymentPlanOptionIndex, {
                                initialValue: paymentPlanOption.SpecificPaymentDate,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText("Month Day required!"),
                                    }
                                ]
                            })(
                                <Select
                                    showSearch
                                    onChange={(value) => handleMonthlyFields(value, FORM_FIELDS.SPECIFIC_PAYMENT_DAY)}
                                    style={{ width: 200 }}>
                                    {populateCustomDayChildrenOptions()}
                                </Select>)}
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <FormItem label={
                            <span>
                                {getTranslatedText("Start delay")}&nbsp;
                                <Tooltip title={getTranslatedText("Adding a delay to your start payment date")}>
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        }>
                            {getFieldDecorator("Initial" + FORM_FIELDS.START_DELAY + paymentPlanOptionIndex, {
                                initialValue: paymentPlanOption.StartDelay,
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText("Start delay is required!"),
                                    }
                                ]
                            })(
                                <InputNumber
                                    min={0}
                                    max={12}
                                    style={{ width: 200 }}
                                    onChange={(value) => handleMonthlyFields(value, FORM_FIELDS.START_DELAY)}
                                />
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </>
        );
    }

    const populateDateRangeForm = () => {
        return (
            <>
                <Row>
                    <Col span={8}>
                        <FormItem label={getTranslatedText("Instalment periods")}>
                            {getFieldDecorator("Initial" + FORM_FIELDS.SPLIT_INSTALMENT_DATE_RANGE + paymentPlanOptionIndex, {
                                initialValue: paymentPlanOption.SplitInstalmentDateRange ?
                                    paymentPlanOption.SplitInstalmentDateRange.map(date => moment(date)) : [],
                                rules: [
                                    {
                                        required: true,
                                        message: getTranslatedText("Instalment periods required!"),
                                    }
                                ]
                            })(
                                <RangePicker
                                    format={dateFormatDDMMMYYYYSpace}
                                    onChange={handleChangeInstalmentPeriod}
                                />
                            )}

                        </FormItem>
                        {paymentPlanOption.Instalments > (paymentPlanOption.InstalmentMax ? paymentPlanOption.InstalmentMax : 0) ?
                            (<span style={{ color: 'red' }}>{`${getTranslatedText("There are [noOfInstalments] instalments but we only have [noOfDays] days.")
                                .replace("[noOfInstalments]", paymentPlanOption.Instalments.toString())
                                .replace("[noOfDays]", paymentPlanOption.InstalmentMax ? paymentPlanOption.InstalmentMax.toString() : '0')}`}</span>) : ''
                        }
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            <Form className="form-inline-mb-0">
                <FormItem label={getTranslatedText("Name")}>
                    {getFieldDecorator("Initial" + FORM_FIELDS.NAME + paymentPlanOptionIndex, {
                        initialValue: paymentPlanOption.Name,
                        rules: [
                            {
                                required: true,
                                message: getTranslatedText("Name is required!"),
                            }
                        ]
                    })
                        (
                            <Input style={{ width: 400 }}
                                onChange={updateFormState(
                                    FORM_FIELDS.NAME,
                                    paymentPlanOptionIndex
                                )}
                            />
                        )}
                </FormItem>
                <FormItem label={getTranslatedText("Instalment Type")}
                    extra={paymentPlanOption.InstalmentType ?
                        (<i>{instalmentTypeDescription}</i>) : ''
                    }>
                    {getFieldDecorator("Initial" + FORM_FIELDS.INSTALMENT_TYPE + paymentPlanOptionIndex, {
                        initialValue: paymentPlanOption.InstalmentType ? getTranslatedText(paymentPlanOption.InstalmentType) : 'Default configuration',
                    })(
                        <Input
                            style={{ width: 200 }}
                            disabled
                        />
                    )}
                </FormItem>
                <Row>
                    <Col span={12}>
                        <Card style={{ marginTop: 15 }}>
                            {
                                paymentPlanOption.InstalmentType && (

                                    paymentPlanOption.InstalmentType.replace(/\s+/g, '').toLowerCase() === INSTALMENT_TYPE.CUSTOM.replace(/\s+/g, '').toLowerCase()
                                        ? populateCustomForm()
                                        : (paymentPlanOption.InstalmentType.replace(/\s+/g, '').toLowerCase() === INSTALMENT_TYPE.MONTHLY.replace(/\s+/g, '').toLowerCase())
                                            ? populateMonthlyForm()
                                            : paymentPlanOption.InstalmentType.replace(/\s+/g, '').toLowerCase() === INSTALMENT_TYPE.DATE_RANGE.replace(/\s+/g, '').toLowerCase()
                                                ? populateDateRangeForm()
                                                : null
                                )
                            }

                            <Row>
                                <Col span={8}>
                                    <FormItem label={getTranslatedText("Number of instalments")}>
                                        {getFieldDecorator("Initial" + FORM_FIELDS.INSTALMENTS + paymentPlanOptionIndex, {
                                            initialValue: paymentPlanOption.Instalments,
                                            rules: [
                                                {
                                                    validator: (rule: any, value: any, callback: any) => {
                                                        let minimumInstalment: number = paymentPlanOption.InstalmentMin ? paymentPlanOption.InstalmentMin : 0;
                                                        if (value && (value < minimumInstalment || value > 30)) {
                                                            callback(getTranslatedText("The number of instalments should be between [min] and 30.").replace("[min]", minimumInstalment.toString()));
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                },
                                                {
                                                    required: true,
                                                    message: getTranslatedText("Please enter the number of instalments"),
                                                },
                                            ]
                                        })(
                                            <InputNumber
                                                onChange={handleChangeInstalments}
                                                max={30}
                                                disabled={paymentPlanOption.InstalmentType == INSTALMENT_TYPE.CUSTOM}
                                                style={{ width: 200 }}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            {paymentPlanOption.InstalmentType && (
                                paymentPlanOption.InstalmentType.replace(/\s+/g, '').toLowerCase() === INSTALMENT_TYPE.CUSTOM.replace(/\s+/g, '').toLowerCase() ||
                                    paymentPlanOption.InstalmentType.replace(/\s+/g, '').toLowerCase() === INSTALMENT_TYPE.DATE_RANGE.replace(/\s+/g, '').toLowerCase()
                                    ?
                                    <FormItem label={
                                        <span>
                                            {getTranslatedText("Cutoff date")}&nbsp;
                                            <Tooltip title={getTranslatedText("Config that will not include if date have passed")}>
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                    }>
                                        {getFieldDecorator("Initial" + FORM_FIELDS.CUT_OFF_DATE + paymentPlanOptionIndex, {
                                            initialValue: paymentPlanOption.CutoffDate ? formatDateString(paymentPlanOption.CutoffDate) : null
                                        })(<DatePicker
                                            style={{ width: 200 }}
                                            format={dateFormatDDMMMYYYYSpace}
                                            placeholder={getTranslatedText("Date")}
                                            onChange={updateFormState(
                                                FORM_FIELDS.CUT_OFF_DATE,
                                                paymentPlanOptionIndex
                                            )}
                                        />

                                        )}
                                    </FormItem> : ''
                            )
                            }
                            <Row className="mt-9">
                                <Card>
                                    <Col span={24}>
                                        <Row>
                                            <Col span={10}>
                                                <h4>{getTranslatedText("Allocate Instalment Percentage:")}</h4>
                                                <FormItem>
                                                    <Checkbox
                                                        onChange={handlePayPercentCheckboxClick}
                                                        checked={addPaymentPercent}
                                                        disabled={paymentPlanOption.Instalments == 1 ? true : false}
                                                    />
                                                </FormItem>
                                            </Col>
                                            <Col span={8}>
                                                {
                                                    addPaymentPercent && paymentPlanOption.ScheduleDatesDisplay ?
                                                        populatePaymentPercent()
                                                        : ''
                                                }
                                            </Col>
                                            {addPaymentPercent && paymentPlanOption.ScheduleDatesDisplay && (!paymentPlanOption.PayPercentHidePreview && paymentPlanOption.PayPercentHidePreview != undefined) ?
                                                (
                                                    <Col span={6}>
                                                        <strong>{paymentPlanOption.PayPercentTotal}</strong>
                                                        <br />
                                                        <br />
                                                        <i style={{ color: 'red' }}>{paymentPlanOption.PayPercentWarning}</i>
                                                    </Col>
                                                )
                                                : ''}
                                        </Row>

                                    </Col>
                                    <Col span={24}>
                                        {addPaymentPercent ?
                                            (<i style={{ color: 'rgba(0, 0, 0, 0.45)' }}>{getTranslatedText("Note: Payment Percentage should have a total of 100%")}</i>) : ''
                                        }
                                    </Col>
                                </Card>
                            </Row>
                            {
                                !paymentPlanOption.IsTemplate && (
                                    <>
                                        <h4>{getTranslatedText("Custom Fields:")}</h4>
                                        {isEmpty(paymentPlanCustomFieldTabs) && isEmpty(paymentPlanDynamicCustomFieldTabs) ?
                                            <Button
                                                key={paymentPlanOptionKeyIndex}
                                                type="primary"
                                                style={{ marginBottom: 10 }}
                                                onClick={() => addPaymentPlanCustomField(paymentPlanOptionIndex, paymentPlanOptionKeyIndex)}
                                            >
                                                {getTranslatedText("Add Custom Field")}
                                            </Button> :
                                            <Tabs
                                                key={paymentPlanOptionIndex}
                                                onChange={handleCustomFieldTabChange}
                                                activeKey={selectedCustomFieldTab}
                                                type="editable-card"
                                                onEdit={(targetKey, action) => handleCustomFieldEditTab(targetKey, action, paymentPlanOptionIndex, paymentPlanOptionKeyIndex)}
                                            >
                                                {map(paymentPlanCustomFieldTabs,
                                                    ({
                                                        component: TabItemComponent,
                                                        customField,
                                                        ...option
                                                    }: typeof paymentPlanCustomFieldTabs[number], index: number) => {
                                                        return (
                                                            <TabPane
                                                                tab={
                                                                    option.title
                                                                }
                                                                key={option.key}
                                                            >
                                                                {selectedCustomFieldTab === option.key &&
                                                                    (!paymentPlanCustomFieldTabs ? (
                                                                        getLoadingSkeletons(
                                                                            3,
                                                                            paymentPlanCustomFieldTabs
                                                                        )
                                                                    ) : (
                                                                        <TabItemComponent
                                                                            form={form}
                                                                            updateFormState={updateFormState}
                                                                            paymentPlanOptionIndex={paymentPlanOptionIndex}
                                                                            paymentPlanOptionKeyIndex={paymentPlanOptionKeyIndex}
                                                                            populateSelectOptions={populateSelectOptions}
                                                                            customField={paymentPlanOption.CustomFields ? paymentPlanOption.CustomFields[index] : []}
                                                                            customFieldIndex={index}
                                                                        />
                                                                    ))}
                                                            </TabPane>
                                                        )
                                                    }
                                                )}
                                                {
                                                    map(paymentPlanDynamicCustomFieldTabs,
                                                        ({
                                                            component: TabItemComponent,
                                                            ...option
                                                        }: typeof paymentPlanDynamicCustomFieldTabs[number], index: number) => {
                                                            const parts = option.key.toString().split('-');
                                                            const keyIndex = parseInt(parts[parts.length - 1], 10);

                                                            return (
                                                                <TabPane
                                                                    tab={
                                                                        option.title
                                                                    }
                                                                    key={option.key}
                                                                >
                                                                    {selectedCustomFieldTab === option.key &&
                                                                        (!paymentPlanDynamicCustomFieldTabs ? (
                                                                            getLoadingSkeletons(
                                                                                3,
                                                                                paymentPlanDynamicCustomFieldTabs
                                                                            )
                                                                        ) : (
                                                                            <TabItemComponent
                                                                                form={form}
                                                                                updateFormState={updateFormState}
                                                                                paymentPlanOptionIndex={paymentPlanOptionKeyIndex}
                                                                                populateSelectOptions={populateSelectOptions}
                                                                                dynamicCustomField={paymentPlanCustomFields[index]}
                                                                                customFieldIndex={index}
                                                                            />
                                                                        ))}
                                                                </TabPane>
                                                            )
                                                        }
                                                    )
                                                }
                                            </Tabs>
                                        }
                                    </>
                                )
                            }
                        </Card>
                    </Col>
                    <Col span={12}>
                        {paymentPlanOption.InstalmentType && paymentPlanOption.ScheduleDatesDisplay && paymentPlanOption.InstalmentType != INSTALMENT_TYPE.CUSTOM && !addPaymentPercent ?
                            (
                                <Row>
                                    <Col>
                                        <Card style={{ marginTop: 15, marginLeft: 10 }} >
                                            <h4>{getTranslatedText("Payment Schedule Dates:")}</h4>
                                            <ul>
                                                {
                                                    map(
                                                        paymentPlanOption.ScheduleDatesDisplay,
                                                        (value) => (
                                                            <li key={value}>{value}</li>
                                                        )
                                                    )
                                                }
                                            </ul>

                                            {paymentPlanOption.InstalmentType == INSTALMENT_TYPE.MONTHLY ? (<i style={{ color: 'red' }}>{getStartTimingNote(paymentPlanOption.StartTiming)}</i>) : ''}
                                        </Card>
                                    </Col>
                                </Row>
                            ) : ''
                        }

                        {addPaymentPercent && paymentPlanOption.ScheduleDatesDisplay && (!paymentPlanOption.PayPercentHidePreview && paymentPlanOption.PayPercentHidePreview != undefined) ?
                            (
                                <Row>
                                    <Col>
                                        <Card style={{ marginTop: 15, marginLeft: 10 }} >
                                            <h4>{getTranslatedText("Payment Schedule dates with payment percentage:")}</h4>
                                            <ul>
                                                {
                                                    map(
                                                        paymentPlanOption.PayPercentScheduleDatesDisplay,
                                                        (value) => (
                                                            <li key={value}>{value}</li>
                                                        )
                                                    )
                                                }
                                            </ul>

                                            <i style={{ color: 'red' }}>{getTranslatedText("Note: Payment percentage will be applied to the total amount of the invoice.")}</i>
                                        </Card>
                                    </Col>
                                </Row>
                            ) : ''
                        }
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default PaymentPlanOptionDetailsTabContent;
