import { DynamicObject } from './../../utils/commonInterfaces';
import API, { graphqlOperation } from '@aws-amplify/api';
import { filter, get, isEmpty } from 'lodash';
import {
    all,
    call,
    fork,
    put,
    select,
    takeLatest,
    delay,
} from 'redux-saga/effects';
import { ApplicationState } from '..';
import {
    API_NAME,
    maxAPIRefetchCount,
    refetchAPIDelay,
} from '../../config/config';
import { IMPORT_HISTORY_PAGE } from '../../config/tableAndPageConstants';
import queries from '../../graphql/queries.graphql';
import { checkShouldRequestRefetch } from '../../utils/commonFunctions';
import {
    getImportHistoryDataErrorAction,
    getImportHistoryDataErrorsAndWarningsErrorAction,
    getImportHistoryDataErrorsAndWarningsSuccessAction,
    getImportHistoryDataSuccessAction,
    getImportHistoryErrorAction,
    getImportHistorySuccessAction,
    setImportHistorySelectedIdSuccessAction,
    getImportHistoryRequestAction,
    getXeroConfigurationRequestAction,
} from './actions';
import { EntityType, ImportActionTypes, ImportConfig, ImportConfigs } from './types';

/**
 * used in useSelector for getting the active data object inside import history.
 */
export const getImportHistoryData = (state: ApplicationState) =>
    state.import.history.activeData;

/**
 * used in useSelector for getting the import history data from redux state.
 */
export const getImportHistoryList = (state: ApplicationState) =>
    state.import.history.data;

/**
 * used in useSelector for getting the import history selected id when clicking a row.
 */
export const getSelectedImportHistoryId = (state: ApplicationState) =>
    state.import.history.activeData.selectedId;

export const getCurrentEntityType = (state: ApplicationState) =>
    state.import.history.entityType;

/**
 * Function that fetches the presigned url used for importing an excel file.
 * @param sagaPayload
 */
function* handleUploadTemplateFileRequest(sagaPayload: any) {
    const { payload } = sagaPayload;
    const type = get(payload, 'type');
    const callback = get(payload, 'callback');
    let url = '/import/upload/file/url';

    if (type && type === 'pp' || type === 'mc') {
        url = `${url}?type=${type}`
    }

    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'get'],
            API_NAME,
            url,
            {}
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that fetches the presigned url for downloading the template file.
 * @param sagaPayload
 */
function* handleDownloadTemplateFileRequest(sagaPayload: any) {
    const { payload } = sagaPayload;
    const fileName = get(payload, 'fileName');
    const callback = get(payload, 'callback');
    
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'get'],
            API_NAME,
            `/import/download/template/url?FileName=${fileName}`,
            {
            }
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

let refetchCount = 0;
/**
 * Function for getting the Import history data for company.
 */
function* handleGetImportHistoryRequest({ payload: sagaPayload }: any) {
    
    const { entityType } = sagaPayload;
    const importConfig: ImportConfig = ImportConfigs[entityType as EntityType];
    const errorMessage =
        'Error fetching import history list. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(importConfig.getImportQuery)
        );

        const { ImportJobStates } = get(
            res.data, importConfig.getImportQueryName
        );

        const responsePayload = {
            data: ImportJobStates,
        };

        refetchCount = 0;
        yield put(getImportHistorySuccessAction(responsePayload));
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getImportHistoryRequestAction(entityType));
        } else {
            yield put(getImportHistoryErrorAction([errorMessage]));
        }
    }
}

/**
 * Function for getting the Import History details data.
 */
function* handleGetImportHistoryDataRequest({ payload: importHistoryId }: any) {
    const errorMessage =
        'Error fetching import history details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const importHistoryDataList: DynamicObject[] = yield select(
            getImportHistoryList
        );
        const importHistoryData = get(
            filter(importHistoryDataList, ['Id', importHistoryId]),
            0
        );

        if (importHistoryData) {
            const responsePayload = {
                record: importHistoryData,
            };

            yield put(getImportHistoryDataSuccessAction(responsePayload));
        } else {
            yield put(getImportHistoryDataErrorAction([errorMessage]));
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(getImportHistoryDataErrorAction([errorMessage]));
    }
}

/**
 * Function that sets the import history selected Id. Also to trigger the callback and trigger the success action.
 */
function* handleSetImportHistorySelectedIdRequest({ payload }: any) {
    const { importHistoryId, callback } = payload;
    yield put(setImportHistorySelectedIdSuccessAction(importHistoryId));
    if (callback) callback();
}

/**
 * Function for getting the errors and warnings data inside import history details.
 */
function* handleGetImportHistoryDataErrorsAndWarningsRequest({
    payload: sagaPayload,
}: any) {
    const errorMessage =
        'Error fetching import history details. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const { importJobId, currentPage, pageSize, entityType } = sagaPayload;
        const importConfig: ImportConfig = ImportConfigs[entityType as EntityType];
        
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(
                importConfig.getMessageQuery, 
                {
                    ImportJobId: importJobId,
                    Skip:
                        currentPage *
                        IMPORT_HISTORY_PAGE.ERRORS_AND_WARNINGS.pageSize,
                    PageSize: pageSize,
                }
            )
        );

        const { ImportStagingMessages } = get(
            res.data,
            importConfig.getMessageQueryName
        );

        if (ImportStagingMessages) {
            const responsePayload = {
                data: ImportStagingMessages,
                pageData: {
                    pageSize: pageSize,
                    currentPage: currentPage,
                    hasNextPage:
                        !(ImportStagingMessages.length < pageSize) &&
                        !(
                            pageSize <
                            IMPORT_HISTORY_PAGE.ERRORS_AND_WARNINGS.pageSize
                        ),
                },
            };

            yield put(
                getImportHistoryDataErrorsAndWarningsSuccessAction(
                    responsePayload
                )
            );
        } else {
            yield put(
                getImportHistoryDataErrorsAndWarningsErrorAction([errorMessage])
            );
        }
    } catch (err) {
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.', err);
        }

        yield put(
            getImportHistoryDataErrorsAndWarningsErrorAction([errorMessage])
        );
    }
}

/**
 * Function for getting xero configuration.
 */
function* handleGetXeroConfigurationRequest({ payload: callback }: any) {
    const errorMessage =
        'Error fetching xero configuration. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.GET_XERO_CONFIGURATION_FOR_IMPORT)
        );

        const xeroConfigData = get(res.data, 'GetXeroConfigurationForImport');

        if (callback) {
            const returnData: DynamicObject = {
                xeroConfiguration: xeroConfigData,
                IsSuccess: true,
            };

            callback(returnData);
        }
    } catch (err) {
        if (
            refetchCount <= maxAPIRefetchCount &&
            checkShouldRequestRefetch(err)
        ) {
            refetchCount++;
            yield delay(refetchAPIDelay);
            yield put(getXeroConfigurationRequestAction(callback));
        } else {
            if (callback) {
                let returnData: any;

                if (err instanceof Error && 'response' in err && (err as any).response.data) {
                    returnData = (err as any).response.data;
                } else if (err instanceof Error) {
                    returnData = { Messages: [err.message] };
                } else {
                    returnData = { Messages: ['An unknown error occurred.'] };
                }

                returnData.IsSuccess = false;
                callback(returnData);
            }
        }
    }
}

/**
 * Function that initiates a connection to xero api.
 * @param sagaPayload
 */
function* handleXeroConnectRequest(sagaPayload: any) {
    const { payload: callback } = sagaPayload;
    const errorMessage =
        'Error encountered when connecting to Xero. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'get'],
            API_NAME,
            '/import/xero/connect',
            {}
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that disconnects the company from xero api.
 * @param sagaPayload
 */
function* handleXeroDisconnectRequest({ payload }: any) {
    const { connectionId, callback } = payload;
    const errorMessage =
        'Error encountered when disconnecting from Xero. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'del'],
            API_NAME,
            '/import/xero/connect',
            {
                body: {
                    ConnectionId: connectionId,
                },
            }
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that syncs the xero account.
 * @param sagaPayload
 */
function* handleXeroManualSyncRequest({ payload }: any) {
    const { callback } = payload;
    const errorMessage =
        'Error encountered when syncing Xero account. Please try again later.';
    try {
        // To call async functions, use redux-saga's `call()`.
        const res: DynamicObject = yield call(
            [API, 'post'],
            API_NAME,
            '/import/xero/manualsync',
            {}
        );

        res.IsSuccess = true;
        if (callback) callback(res);
    } catch (err) {
        if (callback) {
            let returnData: any;

            if (err instanceof Error && 'response' in err && (err as any).response.data) {
                returnData = (err as any).response.data;
            } else if (err instanceof Error) {
                returnData = { Messages: [err.message] };
            } else {
                returnData = { Messages: ['An unknown error occurred.'] };
            }

            returnData.IsSuccess = false;
            callback(returnData);
        }

        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occurred.');
        }
    }
}

/**
 * Function that check import job status.
 * @param sagaPayload
 */
function* handleCheckImportJobStatusRequest({ payload: sagaPayload }: any) {
    const { callback } = sagaPayload;;
    const errorMessage = 'Failed to check the import job state data!';
    try {
        const res: DynamicObject = yield call(
            [API, 'graphql'],
            graphqlOperation(queries.CHECK_IMPORT_JOB_STATUS_FOR_COMPANY, {})
        );

        if (callback) callback(get(res, 'data.CheckImportJobStatusForCompany'));
    } catch (err) {
        if (callback) {
            const returnData = !isEmpty(get(err.response, 'data.Messages'))
                ? err.response.data
                : { Messages: [errorMessage] };

            returnData.IsSuccess = false;
            callback(returnData);
        }
        if (err instanceof Error) {
            console.log('Error', err);
        } else {
            console.error('An unknown error occured.');
        }
    }
}

// This is our watcher function. We use `take*()` functions to watch Redux for a specific action
// type, and run our saga, for example the `handleFetch()` saga above.
function* watchUploadTemplateFileRequest() {
    yield takeLatest(
        ImportActionTypes.UPLOAD_TEMPLATE_FILE_REQUEST,
        handleUploadTemplateFileRequest
    );
}

function* watchDownloadTemplateFileRequest() {
    yield takeLatest(
        ImportActionTypes.DOWNLOAD_TEMPLATE_FILE_REQUEST,
        handleDownloadTemplateFileRequest
    );
}

function* watchGetImportHistoryRequest() {
    yield takeLatest(
        ImportActionTypes.GET_IMPORT_HISTORY_REQUEST,
        handleGetImportHistoryRequest
    );
}

function* watchGetImportHistoryDataRequest() {
    yield takeLatest(
        ImportActionTypes.GET_IMPORT_HISTORY_DATA_REQUEST,
        handleGetImportHistoryDataRequest
    );
}

function* watchSetImportHistorySelectedIdRequest() {
    yield takeLatest(
        ImportActionTypes.SET_IMPORT_HISTORY_SELECTED_ID_REQUEST,
        handleSetImportHistorySelectedIdRequest
    );
}

function* watchGetImportHistoryDataErrorsAndWarningsRequest() {
    yield takeLatest(
        ImportActionTypes.GET_IMPORT_HISTORY_DATA_ERRORS_AND_WARNINGS_REQUEST,
        handleGetImportHistoryDataErrorsAndWarningsRequest
    );
}

function* watchGetXeroConfigurationRequest() {
    yield takeLatest(
        ImportActionTypes.GET_XERO_CONFIGURATION_REQUEST,
        handleGetXeroConfigurationRequest
    );
}

function* watchXeroConnectRequest() {
    yield takeLatest(
        ImportActionTypes.XERO_CONNECT_REQUEST,
        handleXeroConnectRequest
    );
}

function* watchXeroDisconnectRequest() {
    yield takeLatest(
        ImportActionTypes.XERO_DISCONNECT_REQUEST,
        handleXeroDisconnectRequest
    );
}

function* watchXeroManualSyncRequest() {
    yield takeLatest(
        ImportActionTypes.XERO_MANUAL_SYNC_REQUEST,
        handleXeroManualSyncRequest
    );
}

function* watchCheckImportJobStatusRequest() {
    yield takeLatest(
        ImportActionTypes.CHECK_IMPORT_JOB_STATUS_REQUEST,
        handleCheckImportJobStatusRequest
    );
}
// We can also use `fork()` here to split our saga into multiple watchers.
function* importSaga() {
    yield all([
        fork(watchUploadTemplateFileRequest),
        fork(watchDownloadTemplateFileRequest),
        fork(watchGetImportHistoryRequest),
        fork(watchGetImportHistoryDataRequest),
        fork(watchSetImportHistorySelectedIdRequest),
        fork(watchGetImportHistoryDataErrorsAndWarningsRequest),
        fork(watchGetXeroConfigurationRequest),
        fork(watchXeroConnectRequest),
        fork(watchXeroDisconnectRequest),
        fork(watchXeroManualSyncRequest),
        fork(watchCheckImportJobStatusRequest),
    ]);
}

export default importSaga;
